<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar "
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="sticky-top bg-white">
                <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                    Kuesioner
                </div>
                <div class="row mt-2 mb-2 me-0  bg-white">
                    <!-- <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                            id="tahuna_ajaran" v-model="tahunAjaranFilter" @change="pageActive = 1; offset = 0; index();">
                            <option value="" selected disabled>- Pilih Tahun ajaran</option>
                            <option v-for=" list  in  tahunAjaranList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="pageActive = 1; offset = 0; index();" required>
                            <option value="" selected disabled>- Pilih prodi</option>
                            <option v-for=" list  in  prodiList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="pageActive = 1; offset = 0; index()" required
                            :disabled="prodiFilter == ''">
                            <option value="" selected>- Pilih Semester</option>
                            <option v-for=" list  in  semesterList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div> -->
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="ditujukanFilter" @change="pageActive = 1; offset = 0; index()" required>
                            <option value="" selected disabled>- Pilih Ditujukan</option>
                            <option value="mahasiswa">Mahasiswa</option>
                            <option value="dosen">dosen</option>
                            <!-- <option value="pegawai">pegawai</option> -->
                        </select>
                    </div>
                    <div class="col-4">
                        <input type="text" v-model="query" @change="pageActive = 1; offset = 0; index()"
                            class="form-control" placeholder="Cari nama">
                    </div>
                    <div class="col-6 text-end ">
                        <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                            style="border-radius: 5px;" @click="showModal2('modal_kuesioner_tambah')">
                            <img src="@/assets/icon/data_plus.png" alt="" width="15">
                            Tambah Kuesioner
                        </button>
                    </div>

                    <div class="d-flex align-items-start justify-content-start mt-2 gap-2"
                        v-if="paginglist.length != 0">
                        <div>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="limit" @change="pageActive = 1; offset = 0; index()" required>
                                <option v-for=" list in limitList " :key="list.value" :value="list.value"
                                    @change=index()>
                                    {{ list.value }}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center  align-items-center">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination" ref="pagi">
                                    <li class="page-item">
                                        <span class="page-link"
                                            @click="if (pageActive != 1) { pageActive--; offset = offset - limit; index('previous') };"
                                            aria-label="Previous" style="cursor:pointer;">
                                            <span aria-hidden="true">&laquo;</span>
                                        </span>
                                    </li>
                                    <li v-for=" list in paginglist " :key="list.nama" :value="list.value"
                                        class="page-item"
                                        :class="{ 'active': list.nama == pageActive, 'd-none': list.show == false }">
                                        <span class="page-link text-black "
                                            @click="if (list.disabled != true) pageActive = list.nama; offset = list.value; index()"
                                            style="cursor: pointer;">{{
                                                list.nama }}</span>
                                    </li>
                                    <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                                    <li class="page-item">
                                        <span class="page-link"
                                            @click="if (pageActive != totalPages) { pageActive++; offset = offset + limit; index('next') };"
                                            aria-label="Next" style="cursor:pointer;">
                                            <span aria-hidden="true">&raquo;</span>
                                        </span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>



                </div>
            </div>

            <div class="d-flex flex-column gap-2  ">
                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->
                <div class="border  rounded-3 me-2 font-custom-1 mb-5 "
                    style="overflow-x:scroll; z-index: 0;  overflow-y: scroll; max-height: 65vh!important; ">
                    <table class="table mb-0 bg-transparent table-borderless" style="">
                        <thead class="sticky-top " style="top: 0px;">
                            <tr>
                                <th scope="col" class="fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">Nama Kuesioner</th>
                                <th scope="col" class="font-custom-1 fs-7">Ditujukan</th>
                                <th scope="col" class="font-custom-1 fs-7">Terikat KRS</th>
                                <!-- <th scope="col" class="font-custom-1 fs-7">Keterangan</th> -->
                                <th scope="col" class="font-custom-1 fs-7">Status</th>
                                <th scope="col" class="font-custom-1 fs-7">Tanggal Mulai</th>
                                <th scope="col" class="font-custom-1 fs-7">Tanggal Selesai</th>
                                <th scope="col" class="font-custom-1 fs-7">Tanggal Akhir</th>
                                <th scope="col" class="font-custom-1 fs-7">Tahun Ajaran </th>
                                <th scope="col" class="font-custom-1 fs-7">Semester</th>
                                <th scope="col" class="font-custom-1 fs-7">Sudah isi</th>
                                <th scope="col" class="font-custom-1 fs-7">Belum isi</th>
                                <th scope="col" class=" fs-7">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="( record, index ) in records " :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.nama }}</td>
                                <td> <span
                                        v-if="record.terikat_krs == 'YA' && record.ditujukan == 'mahasiswa' && record.id_prodi != 0">{{
                                            record.ditujukan + ' (' + record.prodi + ') ' }}</span>
                                    <span v-else>{{ record.ditujukan }}</span>
                                </td>
                                <td>{{ record.terikat_krs }}</td>
                                <!-- <td>{{ record.keterangan }}</td> -->
                                <td>{{ record.status_kuisioner }}</td>
                                <td>{{ DateFormat(record.tanggal_mulai) }}</td>
                                <td>{{ DateFormat(record.tanggal_selesai) }}</td>
                                <td>{{ DateFormat(record.tanggal_akhir) }}</td>
                                <td>{{ record.tahun_ajaran }}</td>
                                <td>{{ record.semester }}</td>
                                <td>
                                    <span
                                        v-if="record.count_user == 0 && record.terikat_krs == 'YA' && record.ditujukan == 'dosen'">
                                        Observer belum ditambahkan
                                    </span>
                                    <span v-else>
                                        {{ record.count_user_sudah_isi }}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        v-if="record.count_user == 0 && record.terikat_krs == 'YA' && record.ditujukan == 'dosen'">
                                        Observer belum ditambahkan
                                    </span>
                                    <span v-else>
                                        {{ record.count_user_belum_isi }}
                                    </span>
                                </td>

                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <button class="btn btn-primary btn-sm fs-8"
                                            v-on:click="id_result_kuesioner = record.id; showModal2('modal_observer'); observer_index()"
                                            v-if="record.ditujukan == 'dosen' && record.terikat_krs == 'YA'"><i
                                                class='bx bxs-user-plus fs-8'></i></button>
                                        <button class="btn btn-success btn-sm fs-8"
                                            v-on:click="id_result_kuesioner = record.id; kuesioner_export_result(record.id);"><i
                                                class='bx bxs-file-export fs-8'></i></button>
                                        <button class="btn bg-blue-custom btn-sm fs-8 text-light"
                                            @click="editRecord(record); getSoalJawaban(record.id); showModal2('modal_list_soal')"
                                            v-if="update_akses == true"><i class='bx bx-list-ol fs-8'></i></button>
                                        <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                            @click="editRecord(record); showModal2('modal_kuesioner_update')"><i
                                                class='bx bxs-edit fs-8'></i></button>
                                        <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                            v-on:click="deleteRecord(record.id); showModal2('modal_kuisioner_delete')"><i
                                                class='bx bxs-trash-alt fs-8'></i></button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>


    <form @submit.prevent="insert">
        <div class="modal fade" id="modal_kuesioner_tambah" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_kuesioner_tambah">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah Kuesioner</h5>

                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_kuesioner_tambah')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3 ">
                            <label for="nama" class="form-label"> Nama</label>
                            <input type="text" class="form-control" required v-model="newRecord.nama" />
                        </div>
                        <div class="mb-3 ">
                            <label for="nama" class="form-label"> Keterangan</label>
                            <textarea class="form-control" v-model="newRecord.keterangan" required />
                        </div>
                        <div class="mb-3 ">
                            <label for="ditujukan" class="form-label"> Ditujukan</label>
                            <select class="form-select" aria-label="Default select example" name="ditujukan"
                                id="ditujukan" v-model="newRecord.ditujukan" required>
                                <option value="" selected disabled>- Pilih Ditujukan</option>
                                <option value="mahasiswa">Mahasiswa</option>
                                <option value="dosen">dosen</option>
                                <!-- <option value="pegawai">pegawai</option> -->
                            </select>
                        </div>

                        <div class="mb-3 ">
                            <label for="terikat_krs" class="form-label">Terikat dengan KRS</label>
                            <select class="form-select" aria-label="Default select example" name="terikat_krs"
                                id="terikat_krs" v-model="newRecord.terikat_krs" required>
                                <option value="TIDAK" selected>TIDAK</option>
                                <option value="YA">YA</option>
                            </select>
                        </div>
                        <div class="mb-3" v-if="newRecord.ditujukan == 'dosen' && newRecord.terikat_krs == 'TIDAK'">
                            <label for="pilih_user" class="form-label">Ditujukan Kepada <span
                                    style="font-size:8px">(kosongkan apabila ingin ditujukan kepada seluruh
                                    dosen)</span> </label>
                            <vue-select v-model="newRecord.pilih_user" :options="DosenListKuesioner" label="nama"
                                :multiple="true" :reduce="tag => tag.id" :searchable="true" :close-on-select="false"
                                :limit="100"></vue-select>
                        </div>
                        <div class="mb-3" v-if="newRecord.terikat_krs == 'YA'">
                            <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahun_ajaran" v-model="tahunAjaranFilter" @change="index()" required>
                                <option value="" selected disabled>- Pilih Tahun ajaran</option>
                                <option v-for="list in tahunAjaranListKuesioner" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3" v-if="newRecord.terikat_krs == 'YA'" :disabled="tahunAjaranFilter == ''">
                            <label for="semester" class="form-label">Semester</label>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="semesterFilter" @change="index()" required>
                                <option value="" selected>- Pilih Semester</option>
                                <option v-for="list in semesterListKuesioner" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3 " v-if="newRecord.terikat_krs == 'YA'">
                            <label for="prodi" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="prodiFilter" @change="index()" required>
                                <option value="" selected>- Pilih Prodi</option>
                                <option v-for="list in prodiListKuesioner" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3 ">
                            <label for="tanggal_mulai" class="form-label"> Tanggal Mulai</label>
                            <input type="date" class="form-control" required v-model="newRecord.tanggal_mulai" />
                        </div>
                        <div class="mb-3 ">
                            <label for="tanggal_selesai" class="form-label"> Tanggal Selesai</label>
                            <input type="date" class="form-control" required v-model="newRecord.tanggal_selesai" />
                        </div>
                        <div class="mb-3 ">
                            <label for="tanggal_selesai" class="form-label"> Tanggal Akhir</label>
                            <input type="date" class="form-control" required v-model="newRecord.tanggal_akhir" />
                        </div>


                        <!-- <div class="mb-3 ">
                                <label for="ditujukan" class="form-label"> Status Kuesioner</label>
                                <select class="form-select" aria-label="Default select example" name="ditujukan" id="ditujukan"
                                    v-model="newRecord.status_kuisioner" required>
                                    <option value="" selected disabled>- Pilih Ditujukan</option>
                                    <option value="pending">PENDING</option>
                                    <option value="verify">VERIFY</option>
                                </select>
                            </div> -->
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_kuesioner_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-primary text-light">
                            Simpan </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <form @submit.prevent="update">
        <div class="modal fade" id="modal_kuesioner_update" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_kuesioner_update">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Perbaharui Kuesioner</h5>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_kuesioner_update')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3 ">
                            <label for="nama" class="form-label"> Nama</label>
                            <input type="text" class="form-control" required v-model="currentRecord.nama" />
                        </div>
                        <div class="mb-3 ">
                            <label for="nama" class="form-label"> Keterangan</label>
                            <textarea class="form-control" v-model="currentRecord.keterangan" required />
                        </div>
                        <div class="mb-3 ">
                            <label for="ditujukan" class="form-label"> Ditujukan</label>
                            <select class="form-select" aria-label="Default select example" name="ditujukan"
                                id="ditujukan" v-model="currentRecord.ditujukan" required>
                                <option value="" selected disabled>- Pilih Ditujukan</option>
                                <option value="mahasiswa">Mahasiswa</option>
                                <option value="dosen">dosen</option>
                                <!-- <option value="pegawai">pegawai</option> -->
                            </select>
                        </div>




                        <div class="mb-3 ">
                            <label for="terikat_krs" class="form-label">Terikat dengan KRS</label>
                            <select class="form-select" aria-label="Default select example" name="terikat_krs"
                                id="terikat_krs" v-model="currentRecord.terikat_krs" required>
                                <option value="TIDAK" selected>TIDAK</option>
                                <option value="YA">YA</option>
                            </select>
                        </div>
                        <div class="mb-3"
                            v-if="currentRecord.ditujukan == 'dosen' && currentRecord.terikat_krs == 'TIDAK'">
                            <label for="pilih_user" class="form-label">Ditujukan Kepada <span
                                    style="font-size:10px">(kosongkan apabila ingin ditujukan kepada seluruh
                                    dosen)</span> </label>
                            <vue-select v-model="currentRecord.pilih_user" :options="DosenListKuesioner" label="nama"
                                :multiple="true" :reduce="tag => tag.id" :searchable="true" :close-on-select="false"
                                :limit="100"></vue-select>
                        </div>

                        <div class="mb-3" v-if="currentRecord.terikat_krs == 'YA'">
                            <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahun_ajaran" v-model="tahunAjaranFilter" @change="index()" required>
                                <option value="" selected disabled>- Pilih Tahun ajaran</option>
                                <option v-for="list in tahunAjaranListKuesioner" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3" v-if="currentRecord.terikat_krs == 'YA'" :disabled="tahunAjaranFilter == ''">
                            <label for="semester" class="form-label">Semester</label>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="semesterFilter" @change="index()" required>
                                <option value="" selected>- Pilih Semester</option>
                                <option v-for="list in semesterListKuesioner" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3 " v-if="currentRecord.terikat_krs == 'YA'">
                            <label for="prodi" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="prodiFilter" @change="index()" required>
                                <option value="" selected>- Pilih Prodi</option>
                                <option v-for="list in prodiListKuesioner" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3 ">
                            <label for="tanggal_mulai" class="form-label"> Tanggal Mulai</label>
                            <input type="date" class="form-control" required v-model="currentRecord.tanggal_mulai" />
                        </div>
                        <div class="mb-3 ">
                            <label for="tanggal_selesai" class="form-label"> Tanggal Selesai</label>
                            <input type="date" class="form-control" required v-model="currentRecord.tanggal_selesai" />
                        </div>
                        <div class="mb-3 ">
                            <label for="tanggal_akhir" class="form-label"> Tanggal Akhir</label>
                            <input type="date" class="form-control" required v-model="currentRecord.tanggal_akhir" />
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_kuesioner_update')">Tutup</button>
                        <button type="submit" @click="finalisasi = 'YA'" class="btn bg-primary text-light"
                            v-if="currentRecord.status_kuisioner == 'PENDING' && update_akses == true">
                            Finalisasi </button>
                        <button type="submit" @click="finalisasi = 'TIDAK'" class="btn bg-primary text-light"
                            v-if="currentRecord.status_kuisioner == 'VERIFY' && update_akses == true">
                            Batalkan Finalisasi </button>
                        <button type="submit" class="btn bg-green-custom text-light"
                            v-if="currentRecord.status_kuisioner == 'PENDING' && update_akses == true">
                            Simpan </button>

                    </div>

                </div>
            </div>
        </div>
    </form>
    <div class="modal fade" id="modal_kuisioner_delete" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_kuisioner_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Berita Acara</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_kuisioner_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_kuisioner_delete')">Tidak,
                        tetap simpan
                        disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>
    <form @submit.prevent="insertSoalJawaban">
        <div class="modal fade" id="modal_list_soal" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_list_soal">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">List Soal Kuesioner {{
                            currentRecord.nama
                        }}</h5>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_list_soal')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <!-- <button @click="tambahSoal" type="button" class="btn bg-green-custom text-light"
                            v-if="currentRecord.status_kuisioner == 'PENDING'">
                            <i class='bx bx-list-plus'></i> Tambah Soal
                        </button> -->
                        <div v-for="list in soalList " :key="list.id" class="mt-3 d-flex align-items-center gap-2 ">
                            <div class="w-100 border px-2 py-2 rounded" v-if="list.status == 'AKTIF'">
                                <div class="">
                                    <div class="mb-3">
                                        <select class="form-select" aria-label="Default select example" name="tipe"
                                            id="jenis_soal" v-model="list.tipe" @change="changeTipeSoal(list.id)"
                                            required :disabled="currentRecord.status_kuisioner == 'VERIFY'">
                                            <option value="" disabled>- Pilih Tipe</option>
                                            <option value="pg">Pilihan ganda</option>
                                            <option value="essay">Essay</option>
                                        </select>
                                    </div>
                                    <div class="w-100">
                                        <textarea class="form-control" style="overflow: hidden;"
                                            @input="adjustTextarea()" v-model="list.nama" :ref="list.id"
                                            :disabled="currentRecord.status_kuisioner == 'VERIFY'"></textarea>
                                    </div>

                                </div>

                                <div v-for="list2 in list.jawaban " :key="list2.id">
                                    <div class="d-flex align-items-center  gap-2 mt-1" v-if="list2.status == 'AKTIF'">
                                        <div class="d-flex align-items-center w-100">
                                            <input
                                                class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                                type="radio" disabled>
                                            <!-- <input type="text" class="form-control" @change="CharOnly" 
                                                v-model=""> -->
                                            <textarea class="form-control" style="overflow: hidden;"
                                                @input="adjustTextarea()" v-model="list2.nama" :key="list2.id"
                                                :disabled="currentRecord.status_kuisioner == 'VERIFY'"></textarea>
                                        </div>

                                        <div @click="hapusJawaban(list.id, list2.id)" style="cursor: pointer;"
                                            class="text-danger  " v-if="currentRecord.status_kuisioner == 'PENDING'">
                                            <i class='bx bx-x-circle fs-5'></i>
                                        </div>

                                    </div>

                                </div>
                                <div @click="tambahJawaban(list.id)" style="cursor: pointer;"
                                    class="font-green-custom ms-4 mt-2"
                                    v-if="list.tipe == 'pg' && currentRecord.status_kuisioner == 'PENDING'">
                                    <i class='bx bx-plus-circle fs-5'></i>
                                </div>
                            </div>
                            <div class="" v-if="list.status == 'AKTIF' && currentRecord.status_kuisioner == 'PENDING'">

                                <button @click="deleteSoal(list.id)" type="button" class="btn btn-danger">
                                    <i class='bx bxs-trash-alt'></i>
                                </button>
                            </div>
                        </div>
                        <div class="mt-3 d-flex align-items-center gap-2">
                            <button @click="tambahSoal" type="button" class="btn bg-green-custom text-light"
                                v-if="currentRecord.status_kuisioner == 'PENDING'">
                                <i class='bx bx-list-plus'></i> Tambah Soal
                            </button>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_list_soal')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light"
                            v-if="currentRecord.status_kuisioner == 'PENDING'">
                            Simpan </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="modal fade" id="modal_get_result_kuisioner" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_get_result_kuisioner">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hasil Kuesioner {{ currentRecord.nama }}
                    </h5>

                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_get_result_kuisioner')">
                    </button>

                </div>
                <div class="modal-body">
                    <div class="d-flex align-items-start justify-content-start mt-2 gap-2"
                        v-if="paginglistKuesioner.length != 0">
                        <div class="flex-fill d-flex justify-content-start gap-1">
                            <div>
                                <select class="form-select" aria-label="Default select example" name="semester"
                                    id="semester" v-model="limitKuesioner"
                                    @change="pageActiveKuesioner = 1; offsetKuesioner = 0; kuesioner_get_result()"
                                    required>
                                    <option v-for=" list in limitList " :key="list.value" :value="list.value"
                                        @change=kuesioner_get_result()>
                                        {{ list.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="d-flex justify-content-center  align-items-center">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination" ref="pagi">
                                        <li class="page-item">
                                            <span class="page-link"
                                                @click="if (pageActiveKuesioner != 1) { pageActiveKuesioner--; offsetKuesioner = offset - limitKuesioner; kuesioner_get_result('previous') };"
                                                aria-label="Previous" style="cursor:pointer;">
                                                <span aria-hidden="true">&laquo;</span>
                                            </span>
                                        </li>
                                        <li v-for=" list in paginglistKuesioner " :key="list.nama" :value="list.value"
                                            class="page-item"
                                            :class="{ 'active': list.nama == pageActiveKuesioner, 'd-none': list.show == false }">
                                            <span class="page-link text-black "
                                                @click="if (list.disabled != true) pageActiveKuesioner = list.nama; offsetKuesioner = list.value; kuesioner_get_result()"
                                                style="cursor: pointer;">{{
                                                    list.nama }}</span>
                                        </li>
                                        <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                                        <li class="page-item">
                                            <span class="page-link"
                                                @click="if (pageActiveKuesioner != totalPagesKuesioner) { pageActiveKuesioner++; offsetKuesioner = offsetKuesioner + limitKuesioner; kuesioner_get_result('next') };"
                                                aria-label="Next" style="cursor:pointer;">
                                                <span aria-hidden="true">&raquo;</span>
                                            </span>
                                        </li>
                                    </ul>
                                </nav>

                            </div>
                            <div class="">
                                <input type="text" v-model="queryKuesioner"
                                    @change="pageActiveKuesioner = 1; offsetKuesioner = 0; kuesioner_get_result()"
                                    class="form-control" placeholder="Cari nim, mahasiswa ">
                            </div>
                        </div>
                        <div class="flex-fill d-flex justify-content-end">
                            <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4"
                                v-on:click="kuesioner_export_result(id_result_kuesioner);"><i
                                    class='bx bx-export fs-8 me-2'></i>
                                Ekspor Hasil Kuesioner</button>
                        </div>
                    </div>
                    <div class="border  rounded-3 me-2 font-custom-1 mb-5 "
                        style="overflow-x:scroll; z-index: 0;  overflow-y: scroll; max-height: 60vh!important; ">

                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <thead class="sticky-top " style="top: 0px;">
                                <tr>
                                    <th scope="row" class="bg-white ">No</th>
                                    <th v-for="(record, index) in headerResultKuesioner" :key="index" :ref="index"
                                        scope="col" class="fs-7">
                                        {{ record }}
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( record, index ) in recordsKuesioner " :key="index" :ref="index"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td v-for="(atribut, index2) in headerResultKuesioner "
                                        :key="atribut + index + index2" :ref="atribut + index + index2">{{
                                            record[atribut] }}</td>
                                </tr>
                                <tr v-for="( record, index ) in recordsKuesioner " :key="index" :ref="index"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td v-for="(atribut, index2) in headerResultKuesioner "
                                        :key="atribut + index + index2" :ref="atribut + index + index2">{{
                                            record[atribut] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_get_result_kuisioner')">Tutup</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_observer" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_observer">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Atur Observer
                    </h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_list_berita_acara"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_observer')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-2 text-center">
                            <select class="form-select" aria-label="Default select example" name="dosen_observer"
                                id="dosen_observer" v-model="prodiFilterObserver"
                                @change="pageActive = 1; offset = 0; observer_index();">
                                <option value="" selected>- Pilih Prodi</option>
                                <option v-for=" list in prodiListObserver " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="col-2 text-center">
                            <select class="form-select" aria-label="Default select example" name="dosen_observer"
                                id="dosen_observer" v-model="dosenFilterObserver"
                                @change="pageActive = 1; offset = 0; observer_index();">
                                <option value="" selected>- Pilih Dosen</option>
                                <option v-for=" list in dosenListObserver " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="col-2 text-center">
                            <select class="form-select" aria-label="Default select example" name="dosen_target"
                                id="dosen_target" v-model="dosenTargetFilterObserver"
                                @change="pageActive = 1; offset = 0; observer_index();">
                                <option value="" selected>- Pilih Dosen</option>
                                <option v-for=" list in dosenListObserver" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 text-end ">
                            <button v-if="create_akses == true"
                                class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                                style="border-radius: 5px;" @click="showModal2('modal_observer_tambah');">
                                <img src="@/assets/icon/data_plus.png" alt="" width="15">
                                Tambah Observer
                            </button>
                        </div>
                    </div>
                    <div class="">
                        <div class="d-flex align-items-start justify-content-start mt-2 gap-2">
                            <div>
                                <select class="form-select" aria-label="Default select example" name="semester"
                                    id="semester" v-model="limitObserver"
                                    @change="pageActiveObserver = 1; offsetObserver = 0; index()" required>
                                    <option v-for=" list in limitList " :key="list.value" :value="list.value"
                                        @change=index()>
                                        {{ list.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="d-flex justify-content-center  align-items-center">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination" ref="pagi">
                                        <li class="page-item">
                                            <span class="page-link"
                                                @click="if (pageActiveObserver != 1) { pageActiveObserver--; offsetObserver = offsetObserver - limitObserver; observer_index('previous') };"
                                                aria-label="Previous" style="cursor:pointer;">
                                                <span aria-hidden="true">&laquo;</span>
                                            </span>
                                        </li>
                                        <li v-for=" list in paginglistObserver " :key="list.nama" :value="list.value"
                                            class="page-item"
                                            :class="{ 'active': list.nama == pageActiveObserver, 'd-none': list.show == false }">
                                            <span class="page-link text-black "
                                                @click="if (list.disabled != true) pageActiveObserver = list.nama; offsetObserver = list.value; observer_index()"
                                                style="cursor: pointer;">{{
                                                    list.nama }}</span>
                                        </li>
                                        <li class="page-item">
                                            <span class="page-link"
                                                @click="if (pageActiveObserver != totalPagesObserver) { pageActiveObserver++; offsetObserver = offsetObserver + limitObserver; observer_index('next') };"
                                                aria-label="Next" style="cursor:pointer;">
                                                <span aria-hidden="true">&raquo;</span>
                                            </span>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="w-25">
                                <input type="text" v-model="queryObserver"
                                    @change="pageActiveObserver = 1; offsetObserver = 0; observer_index()"
                                    class="form-control" placeholder="Cari nim, nama_lengkap, nama_kuliah">
                            </div>
                        </div>
                    </div>

                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>NIDN Observer</th>
                                    <th>Nama Dosen Observer</th>
                                    <th>NIDN Target </th>
                                    <th>Nama Dosen Target</th>
                                    <th>Prodi Target</th>
                                    <th>Mata Kuliah Target</th>
                                    <th>Kelas Target</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( record, index ) in recordsObserver " :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>

                                    <td>{{ record.nidn_observer }}</td>
                                    <td>{{ record.dosen_observer }}</td>
                                    <td>{{ record.nidn_target_dosen }}</td>
                                    <td>{{ record.dosen_target }}</td>
                                    <td>{{ record.prodi_target }}</td>
                                    <td>{{ record.mata_kuliah_target }}</td>
                                    <td>{{ record.kelas_target }}</td>


                                    <td class="bg-transparent">
                                        <div class="d-flex gap-2">
                                            <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                                @click="editRecordObserver(record); showModal2('modal_observer_update');"><i
                                                    class='bx bxs-message-square-edit fs-8'></i></button>
                                            <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                                v-on:click="deleteRecordObserver(record.id); showModal2('modal_observer_delete')"><i
                                                    class='bx bxs-trash-alt fs-8'></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_observer')">Tutup</button>
                </div>
            </div>

        </div>
    </div>
    <form @submit.prevent="insertObserver">
        <div class="modal fade" id="modal_observer_tambah" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_observer_tambah">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah Dosen Observer</h5>

                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_observer_tambah')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="dosenObserver" class="form-label">Dosen Observer</label>
                            <select class="form-select" aria-label="Default select example" name="dosenObserver"
                                id="dosenObserver" v-model="dosenFilterObserver" @change="index()" required>
                                <option value="" selected disabled>- Pilih Dosen</option>
                                <option v-for="list in dosenListObserver" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosentargetObserver" class="form-label">Dosen Target</label>
                            <select class="form-select" aria-label="Default select example" name="dosentargetObserver"
                                id="dosentargetObserver" v-model="dosenTargetFilterObserver" @change="observer_index()"
                                required>
                                <option value="" selected disabled>- Pilih Dosen</option>
                                <option v-for="list in dosenListObserver" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="prodiObserver" class="form-label">Prodi Target</label>
                            <select class="form-select" aria-label="Default select example" name="prodiObserver"
                                id="prodiObserver" v-model="prodiFilterObserver" @change="observer_index()" required>
                                <option value="" selected disabled>- Pilih Prodi</option>
                                <option v-for="list in prodiTargetListObserver" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="matakuliahObserver" class="form-label">Mata Kuliah Target</label>
                            <select class="form-select" aria-label="Default select example" name="matakuliahObserver"
                                id="matakuliahObserver" v-model="mataKuliahFilterObserver" @change="observer_index()"
                                required>
                                <option value="" selected disabled>- Pilih Mata Kuliah</option>
                                <option v-for="list in mataKuliahListObserver" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="kelasObserver" class="form-label">Kelas Target</label>
                            <select class="form-select" aria-label="Default select example" name="kelasObserver"
                                id="kelasObserver" v-model="kelasFilterObserver" @change="observer_index()" required>
                                <option value="" selected disabled>- Pilih Kelas</option>
                                <option v-for="list in kelasListObserver" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>



                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_observer_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-primary text-light">
                            Simpan </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <form @submit.prevent="updateObserver">
        <div class="modal fade" id="modal_observer_update" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_observer_update">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Update Dosen Observer</h5>

                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_observer_update')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="dosenObserver" class="form-label">Dosen Observer</label>
                            <select class="form-select" aria-label="Default select example" name="dosenObserver"
                                id="dosenObserver" v-model="dosenFilterObserver" @change="index()" required>
                                <option value="" selected disabled>- Pilih Dosen</option>
                                <option v-for="list in dosenListObserver" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosentargetObserver" class="form-label">Dosen Target</label>
                            <select class="form-select" aria-label="Default select example" name="dosentargetObserver"
                                id="dosentargetObserver" v-model="dosenTargetFilterObserver" @change="observer_index()"
                                required>
                                <option value="" selected disabled>- Pilih Dosen</option>
                                <option v-for="list in dosenListObserver" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="prodiObserver" class="form-label">Prodi Target</label>
                            <select class="form-select" aria-label="Default select example" name="prodiObserver"
                                id="prodiObserver" v-model="prodiFilterObserver" @change="observer_index()" required>
                                <option value="" selected disabled>- Pilih Prodi</option>
                                <option v-for="list in prodiTargetListObserver" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="matakuliahObserver" class="form-label">Mata Kuliah Target</label>
                            <select class="form-select" aria-label="Default select example" name="matakuliahObserver"
                                id="matakuliahObserver" v-model="mataKuliahFilterObserver" @change="observer_index()"
                                required>
                                <option value="" selected disabled>- Pilih Mata Kuliah</option>
                                <option v-for="list in mataKuliahListObserver" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="kelasObserver" class="form-label">Kelas Target</label>
                            <select class="form-select" aria-label="Default select example" name="kelasObserver"
                                id="kelasObserver" v-model="kelasFilterObserver" @change="observer_index()" required>
                                <option value="" selected disabled>- Pilih Kelas</option>
                                <option v-for="list in kelasListObserver" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>



                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_observer_update')">Tutup</button>
                        <button type="submit" class="btn bg-primary text-light">
                            Simpan </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="modal fade" id="modal_observer_delete" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_observer_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Berita Acara</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_observer_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_observer_delete')">Tidak,
                        tetap simpan
                        disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteDataObserver">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
.pagination {
    --bs-pagination-color: black !important;
}

.active>.page-link,
.page-link.active {
    background-color: #0D9044 !important;
    border-color: #0D9044 !important;
    color: white !important;
}

.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}

::-webkit-scrollbar {
    width: 0.6rem;
    border-radius: 0.5rem;
    background-color: rgba(63, 63, 63, 0.656);
    height: 0.8rem;

}

::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.473);
    border-radius: 0.5rem;
    width: 0.3rem;
}

::-webkit-scrollbar-thumb:hover {
    width: 0.3rem;
    background-color: rgba(255, 255, 255, 0.797);
}

.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, hariList, base_url, DateFormat, CloseLoading, ShowLoading, menu_akses_cek, ErrorConnectionTimeOut, AlertBottom, CharAndNumberOnly, DateNow, addNumberToObjects } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";
import XLSX from "xlsx/dist/xlsx.full.min.js";
import vueSelect from 'vue-select';

export default {
    data() {
        return {
            //data
            url: 'kuesioner',
            url2: 'berita_acara',
            api: "",

            //pages
            limit: 5,
            offset: 0,
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,
            currentPageActive: 1,
            pageActive: 1,


            limitList: [{
                value: 5
            }, {
                value: 10
            }, {
                value: 25
            }
            ],

            prodiFilter: "",
            // semesterFilter: "",
            // tahunAjaranFilter: "",
            // hariFilter: "",
            ditujukanFilter: "",
            semesterFilter: "",
            tahunAjaranFilter: "",
            query: "",

            records: [],
            newRecord: { nama: "", keterangan: "", tanggal_mulai: "", tanggal_selesai: "", tanggal_akhir: "", ditujukan: "", status_kuisioner: "", terikat_krs: "TIDAK" },
            currentRecord: { nama: "", keterangan: "", tanggal_mulai: "", tanggal_selesai: "", tanggal_akhir: "", ditujukan: "", status_kuisioner: "" },
            // beritaAcaraRecords: [],
            // mahasiswaRecords: [],
            // attendanceRecords: [],
            // penggantiList: [],
            paginglist: [],
            isLoad: 0,

            //modal berita acara
            batas_page_awal: 0,
            batas_page_akhir: 10,

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            temporaryStatusDosen: "",

            finalisasi: "",
            setujui: "",
            tipe: "",

            //SOAL KUESIONER
            soalList: [],
            jawabanList: [],

            // index result Kuesioner 
            numberPageKuesioner: 1,
            totalPagesKuesioner: 0,
            currentPageActiveKuesioner: 1,
            pageActiveKuesioner: 1,
            recordsKuesioner: [],
            currentRecordKuesioner: { nim_mahasiswa: "", nama_lengkap: "", },
            limitKuesioner: 5,
            offsetKuesioner: 0,
            batas_page_awalKuesioner: 0,
            batas_page_akhirKuesioner: 10,

            //
            prodiListKuesioner: [],
            waktuKuliahListKuesioner: [],
            dosenWaliListKuesioner: [],
            semesterListKuesioner: [],
            tahunAjaranListKuesioner: [],
            hariJadwalListKuesioner: [],
            mataKuliahListKuesioner: [],
            kurikulumListKuesioner: [],

            //
            prodiFilterKuesioner: "",
            semesterFilterKuesioner: "",
            tahunAjaranFilterKuesioner: "",
            kurikulumFilterKuesioner: "",
            tipeFilterKuesioner: "",
            MataKuliahFilterKuesioner: "",
            queryKuesioner: "",
            status_terima_Kuesioner: "",
            headerResultKuesioner: [],

            paginglistKuesioner: [],
            isLoadKuesioner: 0,

            //vue select 
            DosenListKuesioner: "",

            //id actie kuesioner untuk observer
            id_result_kuesioner: "",

            //List Observer
            numberPageObserver: 1,
            totalPagesObserver: 0,
            currentPageActiveObserver: 1,
            pageActiveObserver: 1,
            recordsObserver: [],
            currentRecordObserver: [],
            newRecordObserver: {},
            limitObserver: 5,
            offsetObserver: 0,
            batas_page_awalObserver: 0,
            batas_page_akhirObserver: 10,
            queryObserver: "",

            dosenListObserver: [],
            prodiListObserver: [],
            mataKuliahListObserver: [],
            kelasListObserver: [],
            prodiTargetListObserver: [],


            prodiFilterObserver: "",
            dosenTargetFilterObserver: "",
            dosenFilterObserver: "",
            kelasFilterObserver: "",
            mataKuliahFilterObserver: "",
            prodiTargetFilterObserver: "",

            paginglistObserver: [],
            isLoadObserver: 0,


        }
    },
    components: {
        SideNavbar,
        vueSelect
    },
    computed: {
        returnValueStatusDosen() {
            return this.currentRecordBeritaAcara.status_dosen ? 'TIDAK' : 'HADIR';
        }
    },

    methods: {
        addNumberToObjects,
        kuesioner_export_result(id) {
            ShowLoading();

            let api = "";
            let formData = new FormData();


            formData.append("id", id);

            if (process.env.NODE_ENV === "development") {
                api = base_url + 'kuesioner_export_result';
            } else {
                api = base_url + 'kuesioner_export_result';
            }

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            response.data.data[0] = this.addNumberToObjects(response.data.data[0]);
                            for (var i = 0; i < response.data.data[1].length; i++) {
                                delete response.data.data[1][i]["id"];
                                delete response.data.data[1][i]["created_at"];
                                delete response.data.data[1][i]["updated_at"];
                            }

                            // Create a new workbook
                            const wb = XLSX.utils.book_new();
                            // Create a new worksheet
                            const ws = XLSX.utils.json_to_sheet(response.data.data[1]);
                            // Add the worksheet to the workbook
                            XLSX.utils.book_append_sheet(wb, ws, 'Kuesioner');
                            // Convert the workbook to an XLSX file and download it
                            XLSX.writeFile(wb, 'Kuesioner' + ".xlsx");
                            CloseLoading();
                        } else {
                            AlertPopup("error", "", response.data.message, 1500, false);
                        }


                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        kuesioner_get_result(status) {

            this.paginglistKuesioner = [];
            ShowLoading();
            this.recordsKuesioner = [];

            let formData = new FormData();

            formData.append("limit", this.limitKuesioner);
            formData.append("offset", this.offsetKuesioner);
            formData.append("id", this.id_result_kuesioner);
            formData.append("query", this.queryKuesioner);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'kuesioner_get_result';
            } else {
                this.api = base_url + 'kuesioner_get_result';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            this.recordsKuesioner = response.data.data[1];
                            this.totalPagesKuesioner = response.data.data[0][0].number_page;
                            this.headerResultKuesioner = Object.keys(response.data.data[1][0]);

                            let limit = this.limitKuesioner;
                            this.pageActiveKuesioner;

                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limitKuesioner;
                                }

                                if (i <= this.batas_page_akhirKuesioner && i > this.batas_page_awalKuesioner) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }



                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhirKuesioner] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActiveKuesioner - 1].key) {

                                    page_list_array[this.batas_page_awalKuesioner].show = false;
                                    page_list_array[this.batas_page_akhirKuesioner].show = true;

                                    this.batas_page_awalKuesioner++;
                                    this.batas_page_akhirKuesioner++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActiveKuesioner - 1].key) {
                                        this.batas_page_awalKuesioner--;
                                        this.batas_page_akhirKuesioner--;

                                        page_list_array[this.batas_page_awalKuesioner].show = true;
                                        page_list_array[this.batas_page_akhirKuesioner].show = false;
                                    }
                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActiveKuesioner - 1].key) && page_list_array[this.batas_page_akhirKuesioner] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActiveKuesioner != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglistKuesioner.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPagesKuesioner,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }

                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglistKuesioner.push(pemisahPages);
                                    this.paginglistKuesioner.push(totalPages);
                                }

                            }


                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        // Soal 
        adjustTextarea() {
            const textarea = event.target;
            textarea.style.height = 'auto'; // Reset the height to auto to calculate the new height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set the new height based on scrollHeight


        },
        changeTipeSoal(id) {
            const cek_id = id + "";
            const index = this.soalList.findIndex((record) => record.id === id);

            if (cek_id.includes("soal_")) {
                if (this.soalList[index].jawaban.length > 0) {
                    this.soalList[index].jawaban = [{}];
                }
            } else {
                if (this.soalList[index].jawaban.length > 0) {
                    for (let i = 0; i < this.soalList[index].jawaban.length; i++) {
                        const cek_id_jawaban = this.soalList[index].jawaban[i].id + "";
                        if (cek_id_jawaban.includes("soal_")) {
                            this.soalList[index].jawaban.splice(i, 1);
                        } else {
                            this.soalList[index].jawaban[i].status = 'TIDAK';
                        }
                    }
                }

            }
        },
        tambahSoal() {
            const soal =
            {
                id: "soal_" + (this.soalList.length + 1),
                nama: "tambah soal",
                tipe: "pg",
                status: "AKTIF",
                jawaban: [
                    {
                        id: "soal_" + (this.soalList.length + 1) + "_jawaban_1",
                        nama: "1",
                        status: "AKTIF",
                    },
                ],
            }
            this.soalList.push(soal);
        },
        deleteSoal(id) {
            const cek_id = id + "";
            const index = this.soalList.findIndex((record) => record.id === id);
            if (cek_id.includes("soal_")) {
                this.soalList.splice(index, 1);
            } else {
                this.soalList[index].status = 'TIDAK';
                if (this.soalList[index].jawaban.length > 0) {
                    for (let i = 0; i < this.soalList[index].jawaban.length; i++) {
                        this.soalList[index].jawaban[i].status = 'TIDAK';
                    }
                }
            }

        },
        tambahJawaban(id) {
            const index = this.soalList.findIndex((record) => record.id === id);
            let jawaban = {};

            jawaban = {
                id: "soal_" + (this.soalList.length) + "_jawaban_" + (this.soalList[index].jawaban.length + 1),
                nama: "",
                status: 'AKTIF'
            }


            this.soalList[index].jawaban.push(jawaban);
        },
        hapusJawaban(id, id2) {

            const cek_id2 = id2 + "";

            const index = this.soalList.findIndex((record) => record.id === id);
            const index_jawaban = this.soalList[index].jawaban.findIndex((record) => record.id === id2);

            if (cek_id2.includes("soal_")) {
                this.soalList[index].jawaban.splice(index_jawaban, 1);
            } else {
                this.soalList[index].jawaban[index_jawaban].status = 'TIDAK';
            }

        },
        insertSoalJawaban() {
            if (this.soalList.length == 0) {
                AlertPopup("error", "", "soal masih kosong", 1500, false);
                return true;
            }
            ShowLoading();
            const formData = new FormData();

            formData.append('data', JSON.stringify(this.soalList));
            formData.append('id', this.currentRecord.id);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'kuesioner_soal_jawaban' + "_insert";
            } else {
                this.api = base_url + 'kuesioner_soal_jawaban' + "_insert";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        // Clear the form inputs
                        CloseLoading();
                        // AlertBottom(response.data.message);
                        AlertBottom('Soal berhasil disimpan');
                        // this.hideModal2('modal_list_soal');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        capitalizeWords(str) {
            return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        },
        CharAndNumberOnly,
        DateFormat,
        DateNow,

        async index(status) {

            this.paginglist = [];
            ShowLoading();
            this.records = [];


            let formData = new FormData();

            formData.append("ditujukan", this.ditujukanFilter);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);
            formData.append("limit", this.limit);
            formData.append("offset", this.offset);
            formData.append("query", this.query);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        if (response.data.data != undefined) {
                            this.records = response.data.data[1];
                            this.totalPages = response.data.data[0][0].number_page;

                            this.tahunAjaranListKuesioner = response.data.data[2];
                            this.semesterListKuesioner = response.data.data[3];
                            this.DosenListKuesioner = response.data.data[4];
                            this.prodiListKuesioner = response.data.data[5];


                            let limit = this.limit;

                            this.pageActive;
                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limit;
                                }

                                if (i <= this.batas_page_akhir && i > this.batas_page_awal) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }


                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhir] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) {

                                    page_list_array[this.batas_page_awal].show = false;
                                    page_list_array[this.batas_page_akhir].show = true;

                                    this.batas_page_awal++;
                                    this.batas_page_akhir++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActive - 1].key) {
                                        this.batas_page_awal--;
                                        this.batas_page_akhir--;
                                        page_list_array[this.batas_page_awal].show = true;
                                        page_list_array[this.batas_page_akhir].show = false;
                                    }

                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) && page_list_array[this.batas_page_akhir] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActive != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglist.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPages,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }

                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglist.push(pemisahPages);
                                    this.paginglist.push(totalPages);
                                }

                            }

                            // this.previousLastId = response.data.data[1][0].id;

                            // let last_index = Object.keys(response.data.data[1]).length - 1;
                            // this.NextLastId = response.data.data[1][last_index].id;

                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            if (this.currentRecord.terikat_krs == 'YA') {
                this.semesterFilter = this.currentRecord.id_semester;
                this.tahunAjaranFilter = this.currentRecord.id_tahun_ajaran;
                this.prodiFilter = this.currentRecord.id_prodi;
                this.index();
            }

            if (this.currentRecord.pilih_user != null && this.currentRecord.pilih_user != '') {

                const a = this.currentRecord.pilih_user.split(',');

                let b = [];


                for (let [key, value] of a.entries()) {
                    const index = this.DosenListKuesioner.findIndex((record) => record.id == value);
                    key


                    b.push(this.DosenListKuesioner[index].id);
                }
                this.currentRecord.pilih_user = b;
            }


        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {
            if (this.currentRecord[field] != null) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
            } else {
                this.currentRecord[field] = "";
            }
        },
        deleteData() {
            ShowLoading();
            const formData = new FormData();


            formData.append("id", this.temporaryId);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_kuisioner_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        insert() {
            ShowLoading();
            const formData = new FormData();

            Object.entries(this.newRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            // CloseLoading();
            // return false;


            if (this.newRecord.terikat_krs == 'YA') {
                formData.append('tahun_ajaran', this.tahunAjaranFilter);
                formData.append('semester', this.semesterFilter);
                formData.append('prodi', this.prodiFilter);
            }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_insert";
            } else {
                this.api = base_url + this.url + "_insert";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.records.unshift(response.data.data);

                        // Clear the form inputs
                        this.newRecord = {
                            nama: "", prodi: "", waktu_kuliah: "", dosen_wali: "", stambuk: "", keterangan: "", kelas: ""
                        };
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_kuesioner_tambah');
                        this.editRecord(this.records[0]);
                        this.showModal2('modal_list_soal')

                        CloseLoading();

                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        update() {
            ShowLoading();
            const formData = new FormData();

            if (this.finalisasi == 'YA') {
                this.currentRecord.status_kuisioner = 'VERIFY'
            } else {
                this.currentRecord.status_kuisioner = 'PENDING'
            }

            if (this.currentRecord.terikat_krs == 'YA') {
                this.currentRecord.tahun_ajaran = this.tahunAjaranFilter;
                this.currentRecord.semester = this.semesterFilter;
                this.currentRecord.prodi = this.prodiFilter;
            }

            Object.entries(this.currentRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });



            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_update";
            } else {
                this.api = base_url + this.url + "_update";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );

                        this.records.splice(index, 1, response.data.data);

                        // Clear the form inputs
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_kuesioner_update');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        async getSoalJawaban(id) {
            ShowLoading();
            this.soalList = [];

            let formData = new FormData();

            formData.append("limit", 100);
            formData.append("offset", 0);
            formData.append("query", "");
            formData.append("id", id);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'kuesioner_soal_jawaban' + '_get';
            } else {
                this.api = base_url + 'kuesioner_soal_jawaban' + '_get';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response)
                    if (response.data.status == true) {

                        if (response.data.data != undefined) {
                            this.soalList = JSON.parse(response.data.data);

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },

        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        showModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';


            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')

            setTimeout(() => {
                this.$refs[modal_name].classList.remove('show');
                this.$refs[modal_name].style.display = 'none';
            }, 150)
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        deleteRecord(id, status_dosen) {

            this.temporaryId = id;
            this.temporaryStatusDosen = status_dosen;
        },

        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        },

        // OBSERVER_____
        async observer_index(status) {

            this.paginglistObserver = [];
            ShowLoading();
            this.recordsObserver = [];


            let formData = new FormData();

            formData.append("limit", this.limitObserver);
            formData.append("offset", this.offsetObserver);
            formData.append("id", this.id_result_kuesioner);
            formData.append("id_prodi", this.prodiFilterObserver);
            formData.append("observer_dosen", this.dosenFilterObserver);
            formData.append("target_dosen", this.dosenTargetFilterObserver);
            formData.append("id_mata_kuliah", this.mataKuliahFilterObserver);
            formData.append("query", this.queryObserver);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'observer_index';
            } else {
                this.api = base_url + 'observer_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.prodiListObserver = response.data.data[2];
                        this.dosenListObserver = response.data.data[3];
                        this.prodiTargetListObserver = response.data.data[4];
                        this.mataKuliahListObserver = response.data.data[5];
                        this.kelasListObserver = response.data.data[6];

                        if (response.data.data != undefined) {
                            this.recordsObserver = response.data.data[1];
                            this.totalPagesObserver = response.data.data[0][0].number_page;

                            let limit = this.limitObserver;
                            this.pageActiveObserver;

                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limitObserver;
                                }

                                if (i <= this.batas_page_akhirObserver && i > this.batas_page_awalObserver) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }



                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhirObserver] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActiveObserver - 1].key) {

                                    page_list_array[this.batas_page_awalObserver].show = false;
                                    page_list_array[this.batas_page_akhirObserver].show = true;

                                    this.batas_page_awalObserver++;
                                    this.batas_page_akhirObserver++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActiveObserver - 1].key) {
                                        this.batas_page_awalObserver--;
                                        this.batas_page_akhirObserver--;

                                        page_list_array[this.batas_page_awalObserver].show = true;
                                        page_list_array[this.batas_page_akhirObserver].show = false;
                                    }
                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActiveObserver - 1].key) && page_list_array[this.batas_page_akhirObserver] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActiveObserver != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglistObserver.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPagesObserver,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }

                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglistObserver.push(pemisahPages);
                                    this.paginglistObserver.push(totalPages);
                                }

                            }


                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        insertObserver() {
            ShowLoading();
            const formData = new FormData();

            formData.append('id_kuesioner', this.id_result_kuesioner);
            formData.append('id_observer_dosen', this.dosenFilterObserver);
            formData.append('id_target_dosen', this.dosenTargetFilterObserver);
            formData.append('id_mata_kuliah', this.mataKuliahFilterObserver);
            formData.append('id_kelas', this.kelasFilterObserver);
            formData.append('id_prodi', this.prodiFilterObserver);

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            // CloseLoading();
            // return false;

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "observer_insert";
            } else {
                this.api = base_url + "observer_insert";
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.recordsObserver.unshift(response.data.data);
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_observer_tambah');
                        this.index();
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        editRecordObserver(record) {
            this.currentRecordObserver = Object.assign({}, record);

            this.dosenFilterObserver = this.currentRecordObserver.id_observer_dosen;
            this.dosenTargetFilterObserver = this.currentRecordObserver.id_target_dosen;
            this.mataKuliahFilterObserver = this.currentRecordObserver.id_mata_kuliah;
            this.kelasFilterObserver = this.currentRecordObserver.id_kelas;
            this.prodiFilterObserver = this.currentRecordObserver.id_prodi;
            this.observer_index();
        },
        updateObserver() {
            ShowLoading();
            const formData = new FormData();

            formData.append('id_kuesioner', this.id_result_kuesioner);
            formData.append('id_observer_dosen', this.dosenFilterObserver);
            formData.append('id_target_dosen', this.dosenTargetFilterObserver);
            formData.append('id_mata_kuliah', this.mataKuliahFilterObserver);
            formData.append('id_kelas', this.kelasFilterObserver);
            formData.append('id_prodi', this.prodiFilterObserver);
            formData.append('id', this.currentRecordObserver.id);

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            // CloseLoading();
            // return false;

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "observer_update";
            } else {
                this.api = base_url + "observer_update";
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.recordsObserver.findIndex(
                            (record) => record.id == response.data.data.id
                        );

                        this.recordsObserver.splice(index, 1, response.data.data);

                        AlertBottom(response.data.message);
                        this.hideModal2('modal_observer_update');
                        this.index();
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        deleteRecordObserver(id) {

            this.temporaryId = id;

        },

        deleteDataObserver() {
            ShowLoading();
            const formData = new FormData();

            formData.append("id", this.temporaryId);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "observer_delete";
            } else {
                this.api = base_url + "observer_delete";
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.recordsObserver.findIndex((record) => record.id === this.temporaryId);
                        this.recordsObserver.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_observer_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

    },

    // mounted() {

    // },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');

        this.delete_akses = menu_akses_cek(this.url, 'delete');

        this.index();
        this.hariJadwalList = hariList;
        this.newRecord.tanggal_mulai = DateNow();
        this.newRecord.tanggal_selesai = DateNow();
        this.newRecord.tanggal_akhir = DateNow();
        this.tipe = localStorage.getItem('tipe_user');

    }



}
</script>