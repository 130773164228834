<template>
    <div id="pdf-content" class="px-5 pdf-content container " v-bind:class="{ 'poppins': !isButtonHidden }"
        ref="pdf_content">

        <div class="position-relative pt-3">
            <div class="position-absolute end-0 pt-3 ">
                <button class="btn btn-danger" v-bind:class="{ 'd-none': isButtonHidden }" @click="savePDF();"
                    style="width: 150px;">
                    Simpan sebagai PDF
                </button>
            </div>
        </div>

        <div class="">
            <img src="@/assets/img/logo_navbar.png" alt="" style="max-height: 50px" />
        </div>


        <div class="fs-4 text-start mb-2 fw-bold pt-3">Invoice</div>

        <div class="row ps-2">
            <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block  col-10 ">
                <table class=" w-100">
                    <tr>
                        <td style="width: 20%" class="p-0">Nomor</td>
                        <td style="width: 2%" class="p-0">:</td>
                        <td style="width: 50%" class="p-0 fw-bold">
                            {{ recordsPDF.nomor_invoice }}
                        </td>
                    </tr>
                    <tr>
                        <td class="p-0">Tanggal</td>
                        <td class="p-0">:</td>
                        <td class="p-0 fw-bold">
                            {{ recordsPDF.tanggal }}
                        </td>
                    </tr>
                    <tr>
                        <td class="p-0">Tanggal Akhir Pembayaran</td>
                        <td class="p-0">:</td>
                        <td class="p-0 fw-bold">
                            {{ recordsPDF.tanggal_akhir }}
                        </td>
                    </tr>
                    <tr>
                        <td class="p-0">No Ktp</td>
                        <td class="p-0">:</td>
                        <td class="p-0 fw-bold">
                            {{ recordsPDF.no_ktp }}
                        </td>
                    </tr>
                    <tr>
                        <td class="p-0">Nama</td>
                        <td class="p-0">:</td>
                        <td class="p-0 fw-bold">
                            {{ recordsPDF.nama_lengkap }}
                        </td>
                    </tr>
                </table>


            </div>
            <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none col-12 ">
                <table class=" w-100">
                    <tr>
                        <td style="width: 45%" class="p-0">Nomor</td>
                        <td style="width: 5%" class="p-0">:</td>
                        <td style="width: 50%" class="p-0 fw-bold">
                            {{ recordsPDF.nomor_invoice }}
                        </td>
                    </tr>
                    <tr>
                        <td class="p-0">Tanggal</td>
                        <td class="p-0">:</td>
                        <td class="p-0 fw-bold">
                            {{ recordsPDF.tanggal }}
                        </td>
                    </tr>
                    <tr>
                        <td class="p-0">Tanggal Akhir Pembayaran</td>
                        <td class="p-0">:</td>
                        <td class="p-0 fw-bold">
                            {{ recordsPDF.tanggal_akhir }}
                        </td>
                    </tr>
                    <tr>
                        <td class="p-0">No Ktp</td>
                        <td class="p-0">:</td>
                        <td class="p-0 fw-bold">
                            {{ recordsPDF.no_ktp }}
                        </td>
                    </tr>
                    <tr>
                        <td class="p-0">Nama</td>
                        <td class="p-0">:</td>
                        <td class="p-0 fw-bold">
                            {{ recordsPDF.nama_lengkap }}
                        </td>
                    </tr>
                </table>


            </div>

        </div>


        <div class="row ps-2 pt-4">
            <div class="col-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="fw-bold " style="width: 25%;">
                                Nama Item
                            </th>
                            <th class="fw-bold " style="width: 13%;">
                                Keterangan
                            </th>
                            <th class="fw-bold " style="width: 12%;">
                                Jumlah
                            </th>
                            <th class="fw-bold " style="width: 25%;">
                                Harga
                            </th>
                            <th class="fw-bold " style="width: 25%;">
                                Sub Total
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr>
                            <td>{{ recordsPDF.item }}</td>
                            <td>1</td>
                            <td>{{ recordsPDF.quantity }} </td>
                            <td>{{ rupiah(recordsPDF.subtotal) }} </td>
                        </tr> -->
                        <tr>
                            <td>{{ recordsPDF.item }}</td>
                            <td>{{ recordsPDF.keterangan }}</td>
                            <td>1</td>
                            <td>{{ rupiah(recordsPDF.jumlah) }}</td>
                            <td>{{ rupiah(recordsPDF.jumlah) }}</td>
                        </tr>
                        <!-- <tr v-if="recordsPDF.kode_diskon != ''">
                            <td>DISKON</td>
                            <td></td>
                            <td></td>
                            <td>-{{ rupiah(recordsPDF.diskon) }}
                                <button class="btn" @click="undo_voucher()" v-if="recordsPDF.status_text == 'PENDING'">
                                    <i class="ri-delete-bin-line text-danger"></i>
                                </button>

                            </td>
                        </tr> -->
                        <tr class="bg-light">
                            <td class="fw-bold">Sub Total</td>
                            <td></td>
                            <td></td>
                            <td class="fw-bold">{{ rupiah(recordsPDF.jumlah) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row pt-4">
            <div class="col-6">

            </div>

            <div class="col-6 d-flex justify-content-end">
                <div class="d-flex align-items-end">
                    <div class="me-3">
                        <div>
                            Status
                        </div>
                        <div v-if="recordsPDF.status_text == 'PENDING'" style=" width: 100%" class="p-0 fw-bold ">
                            <!-- <i class="ri-close-line"></i> -->
                            <span class="fs-6 text-danger">
                                BELUM DIBAYAR
                            </span>
                        </div>
                        <div v-else style=" width: 100%" class="p-0 fw-bold ">
                            <!-- <i class="ri-wallet-3-line"></i> -->
                            <span class="text-success fs-6">
                                LUNAS
                            </span>
                        </div>
                    </div>

                    <!-- <div class="" v-if="recordsPDF.status_text == 'PENDING'">
                        <div>
                            <button class="btn btn-danger " @click=" create_invoice(recordsPDF.nomor_invoice, voucher)"
                                style="width: 150px;">Bayar</button>
                        </div>
                    </div> -->
                </div>


                <!-- <table border="solid">
                    <tr>
                        <td style="width: 100%" class="p-0">Status </td>
                    </tr>
                    <tr>


                    </tr>

                </table> -->

            </div>
        </div>





    </div>
</template>

<style scoped>
.order-1 {
    order: 1;

}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

#swal2-html-container {
    margin: 100px !important;
}
</style>

<script>

import {
    AlertPopup,
    ShowLoading,
    CloseLoading,
    ErrorConnectionTimeOut, rupiah, base_url,
    // base_url_header, ErrorConnectionTimeOut
} from "@/assets/javascript/function";

import Cookies from "js-cookie";
import axios from "axios";

export default {
    data() {
        return {
            isButtonHidden: false,

            url2: "st_mahasiswa_detail",
            id_mahasiswa: "",
            kode_unik: "",

            nomor_invoice: "",
            voucher: "",

            recordMahasiswa: {
                nama_lengkap: "",
                no_ktp: "",
            },
            recordsPDF: {
                //biodata
                nomor_invoice: "",
                tanggal: "",
                item: "",
                subtotal: "",
                total: "",
                price: "",
                quantity: "",
                jumlah: 0,
            },

            recordInvoiceDetail: {

            },

            config: "",
        };
    },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
    },
    methods: {
        rupiah,
        print() {
            // Set the page size to A4
            const style = document.createElement('style');
            style.textContent = `@media print { @page { size: A4; } }`;
            document.head.appendChild(style);

            // Wait for any dynamic content to load before printing
            setTimeout(() => {
                window.print();

                // Show any hidden elements after printing
                document.querySelectorAll('.no-print').forEach(el => el.style.display = 'block');

                // Remove the A4 page size style
                document.head.removeChild(style);
                // window.close();
                this.isButtonHidden = false;
            }, 1000);

        },
        savePDF() {
            this.isButtonHidden = true;
            this.print();
        },
        verifikasi(nomor_invoice) {
            ShowLoading();
            const formData = new FormData();

            formData.append("nomor_invoice", nomor_invoice);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "invoice_cicilan_mandiri_view";
            } else {
                this.api = base_url + "invoice_cicilan_mandiri_view";
            }

            const urlString = window.location.href;
            const cleanString = urlString.split('?')[0];
            const url = new URL(cleanString);
            url.pathname = "/";
            // const modifiedString = url.toString();

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.recordsPDF = response.data.data[0];

                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);

                });
        },
        resizeHandler() {
            const screenWidth = window.innerWidth;
            if (screenWidth <= 1024) {
                document.body.style.width = "576px";
            } else {
                document.body.style.width = "100%";
            }
        },

    },
    mounted() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 30000,
        }
        const str = this.$route.fullPath;
        const slug = str.split('nomor_invoice=').pop();
        const slug2 = slug.split('&')[0];
        this.verifikasi(slug2);

        window.addEventListener("resize", this.resizeHandler);
        this.resizeHandler();

        const userAgent = navigator.userAgent.toLowerCase();
        this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);


    },
};
</script>