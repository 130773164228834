<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                Mahasiswa
            </div>
            <div class="d-flex flex-column gap-2 mt-2 justify-content-center">
                <div class="row me-0">
                    <div class="col-2">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih prodi</option>
                            <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for="list in tahunAjaranList" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="jalurFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Jalur</option>
                            <option v-for="list in jalurList" :key="list.nama" :value="list.value">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>

                    <div class="col-6 d-flex align-items-center justify-content-end">
                        <button v-if="update_akses == true"
                            class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 " style="border-radius: 5px;"
                            @click="showModal2('modal_laporan_prestasi_mahasiswa'); index()">
                            <i class='bx bxs-file me-2'></i>
                            Laporan Prestasi
                        </button>
                    </div>

                    <!-- <div class="col-6 text-end ">
                        <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 " style="border-radius: 5px;"
                            data-bs-toggle="modal" data-bs-target="#modal_tambah" @click="showModal('modal_tambah')">
                            <img src="@/assets/icon/data_plus.png" alt="" width="15">
                            Tambah Mahasiswa
                        </button>

                    </div> -->

                </div>
                <div class="d-flex justify-content-start me-2 ">
                    <div class="col-3">
                        <input type="text" v-model="query" @change="index()" class="form-control"
                            placeholder="Cari berdasarkan nama, nim">
                    </div>
                    <div class="flex-fill d-flex justify-content-center align-items-center">
                        <div>
                            <i class='bx bx-chevron-left fs-7' @click="index_before_page()"
                                style="cursor: pointer;"></i>
                        </div>
                        <div class="fs-7">
                            {{ numberPage }}/{{ totalPages }}
                        </div>
                        <div>
                            <i class='bx bx-chevron-right fs-7' @click="index_next_page(previousLastId, NextLastId)"
                                style="cursor: pointer;"></i>
                        </div>
                    </div>

                </div>



                <div class="border mt-1 rounded-3 me-2 font-custom-1 mb-5">
                    <table class="table mb-0 bg-transparent table-borderless " style="">
                        <thead>
                            <tr>
                                <th scope="col" class="bg-transparent fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">NIM </th>
                                <th scope="col" class="font-custom-1 fs-7">Nama </th>
                                <th scope="col" class="font-custom-1 fs-7">Prodi </th>
                                <th scope="col" class="font-custom-1 fs-7">Tahun Ajaran</th>
                                <th scope="col" class="font-custom-1 fs-7">Jalur</th>
                                <th scope="col" class="font-custom-1 fs-7">Status Akademik</th>
                                <th scope="col" class="font-custom-1 fs-7">Verifikasi Dokumen</th>
                                <th scope="col" class="bg-transparent  fs-7">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in records" :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.nim_mahasiswa }}</td>
                                <td>{{ capitalizeWords(record.nama_lengkap) }}</td>
                                <td>{{ record.prodi }}</td>
                                <td>{{ record.tahun_ajaran }}</td>
                                <td v-if="record.jalur == 'jalur_kuliah'">mandiri</td>
                                <td v-if="record.jalur == 'KIP'">KIP</td>
                                <td v-if="record.jalur == 'scholarship'">Scholarship</td>
                                <td>{{ record.status_akademik }}</td>
                                <td>
                                    <span v-if="record.status_dokumen == 'terverifikasi'">Terverifikasi</span>
                                    <span v-else-if="record.status_dokumen == 'dokumen ditolak'">Ditolak</span>
                                    <span v-else>
                                        Pending
                                    </span>
                                </td>
                                <td class="bg-transparent">


                                    <div class="btn-group w-100 ">
                                        <button type="button" class="btn btn-sm px-1 py-1 border dropdown-toggle "
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            Aksi
                                        </button>
                                        <ul class="aksi-button dropdown-menu">

                                            <li><button class="dropdown-item  fs-8 aksi-item-primary"
                                                    data-bs-toggle="modal" data-bs-target="#modal_get_krs"
                                                    @click="setkrsId(record.id, 0)">
                                                    <i class='bx bxs-file '></i> KRS</button>
                                            </li>
                                            <li><button class="dropdown-item  fs-8 aksi-item-light"
                                                    v-on:click="viewRecord(record)" @click="showModal2('modal_view')">
                                                    <i class='bx bxs-show '></i> Mahasiswa</button>
                                            </li>
                                            <li v-if="update_akses == true"><button
                                                    class="dropdown-item fs-8 aksi-item-warning"
                                                    v-on:click="editRecord(record)" data-bs-toggle="modal"
                                                    data-bs-target="#modal_edit">
                                                    <i class='bx bxs-message-square-edit  me -2'></i> Ubah</button>
                                            </li>

                                            <li><button class="dropdown-item fs-8 aksi-item-info"
                                                    @click="showModal2('modal_absensi')"
                                                    v-on:click="editRecord(record); id_mahasiswa_absensi = record.id; get_absensi()"
                                                    data-bs-toggle="modal" data-bs-target="#modal_absensi">
                                                    <i class='bx bxs-bar-chart-alt-2  '></i> Absensi</button>
                                            </li>
                                            <li><button class="dropdown-item fs-8 aksi-item-success"
                                                    @click="showModal2('modal_dokumen')"
                                                    v-on:click="editRecord(record)">
                                                    <i class='bx bxs-file-image  '></i> Dokumen </button>
                                            </li>
                                            <li><button class="dropdown-item fs-8 aksi-item-black"
                                                    @click="showModal2('modal_mata_kuliah_nilai')"
                                                    v-on:click="editRecord(record); id_mahasiswa_absensi = record.id; nilaiMataKuliahGet()">
                                                    <i class='bx bxs-book-content'></i> Nilai</button>
                                            </li>
                                            <li><button class="dropdown-item fs-8 aksi-item-purple"
                                                    @click="showModal2('modal_prestasi')"
                                                    v-on:click="editRecord(record); id_mahasiswa_absensi = record.id; prestasiGet()">
                                                    <i class='bx bxs-award '></i> Prestasi</button>
                                            </li>
                                            <li><button class="dropdown-item fs-8 aksi-item-red"
                                                    @click="showModal2('modal_transkrip_akademik')"
                                                    v-on:click="editRecord(record); id_mahasiswa_absensi = record.id; transkripAkademikGet()">
                                                    <i class='bx bx-book'></i> Transkrip Akademik</button>
                                            </li>
                                            <li v-if="update_akses == true"><button
                                                    class="dropdown-item fs-8 aksi-item-orange"
                                                    v-on:click="id_mahasiswa_absensi = record.id; getAksesMahasiswa()">
                                                    <i class='bx bxs-log-in '></i> Akses Mahasiswa</button>
                                            </li>
                                            <li>
                                                <hr class="dropdown-divider">
                                            </li>
                                            <li v-if="delete_akses == true"><button
                                                    class="dropdown-item fs-8  aksi-item-danger" data-bs-toggle="modal"
                                                    data-bs-target="#modal_delete" v-on:click="deleteRecord(record.id)">
                                                    <i class='bx bxs-trash-alt  me -2'></i> Hapus</button>
                                            </li>

                                        </ul>
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>

    </div>
    <form @submit.prevent=" insert()">
        <div class="modal fade" id="modal_tambah" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_tambah">

            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah data
                            mahasiswa</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_tambah"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_tambah', 'close_modal_tambah')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="mama" class="form-label">NIM
                                Mahasiswa</label>
                            <input type="text" class="form-control" v-model="newRecord.nim_mahasiswa" required />
                        </div>
                        <div class="mb-3">
                            <label for="mama" class="form-label">Nama</label>
                            <input type="text" class="form-control" v-model="newRecord.nama_lengkap"
                                @keypress="CharOnly" required />
                        </div>
                        <div class="mb-3">
                            <label for="fakultas" class="form-label">Tahun
                                Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="fakultas"
                                id="fakultas" v-model="newRecord.tahun_ajaran" required>
                                <option value="" selected disabled>- Pilih Tahun
                                    ajaran</option>
                                <option v-for="list in tahunAjaranList" :key="list.tahun_ajaran" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="status_akademik" class="form-label">Status Akademik</label>
                            <select class="form-select" aria-label="Default select example" name="status_akademik"
                                id="status_akademik" v-model="newRecord.status_akademik" required>
                                <option value="" selected disabled>- Pilih
                                    Status Akademik</option>
                                <option v-for="list in statusAkademikList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="prodi" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="newRecord.prodi" required>
                                <option value="" selected disabled>- Pilih Prodi
                                </option>
                                <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="nisn_sekolah" class="form-label">NISN
                                sekolah</label>
                            <input type="text" class="form-control" v-model="newRecord.nisn_sekolah"
                                @keypress="NumberOnly" required />
                        </div>
                        <div class="mb-3">
                            <label for="warga_negara" class="form-label">Kewarganegaraan</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="newRecord.warga_negara" required>
                                <option value="" selected disabled>- Pilih Warga
                                    negara</option>
                                <option v-for="list in wargaNegaraList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="mama" class="form-label">No Induk
                                Kependudukan (NIK)</label>
                            <input type="text" class="form-control" v-model="newRecord.no_ktp" maxlength="16"
                                @keypress="NumberOnly" required />
                        </div>

                        <div class="mb-3">
                            <label for="mama" class="form-label">No Kartu
                                Kelurga KK</label>
                            <input type="text" class="form-control" v-model="newRecord.no_kk" maxlength="16"
                                @keypress="NumberOnly" required />
                        </div>
                        <div class="mb-3">
                            <label for="tempat_lahir" class="form-label">Tempat
                                lahir</label>
                            <input type="text" class="form-control" v-model="newRecord.tempat_lahir"
                                @keypress="CharOnly" />
                        </div>
                        <div class="mb-3">
                            <label for="tanggal_lahir" class="form-label">Tanggal lahir</label>
                            <input type="date" class="form-control" :max="tanggal_maksimum" :min="tanggal_minimum"
                                v-model="newRecord.tanggal_lahir" />
                        </div>
                        <div class="mb-3">
                            <label for="jenis_kelamin" class="form-label">Jenis
                                kelamin</label>
                            <input type="text" class="form-control" v-model="newRecord.jenis_kelamin" />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Alamat</label>
                            <input type="text" class="form-control" v-model="newRecord.alamat_siswa" />
                        </div>
                        <div class="mb-3">
                            <label for="provinsi" class="form-label">Provinsi</label>
                            <v-select v-model="provinsi" :options="provinsiList" placeholder="Pilih Provinsi"
                                :searchable="true" ref="provinsi" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kabupaten" class="form-label">Kabupaten / Kota</label>
                            <v-select v-model="kota" :options="kotaList" placeholder="Pilih Kota" :searchable="true"
                                ref="kota" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kecamatan" class="form-label">Kecamatan</label>
                            <v-select v-model="kecamatan" :options="kecamatanList" placeholder="Pilih Kecamatan"
                                :searchable="true" ref="kecamatan" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kelurahan" class="form-label">Kelurahan</label>
                            <v-select v-model="kelurahan" :options="kelurahanList" placeholder="Pilih Kelurahan"
                                :searchable="true" ref="kelurahan" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kodepos" class="form-label">Kode
                                Pos</label>
                            <input type="text" class="form-control" v-model="newRecord.kodepos" />
                        </div>
                        <div class="mb-3">
                            <label for="telepon_siswa" class="form-label">No
                                Telepon </label>
                            <input type="text" class="form-control" v-model="newRecord.telepon_siswa" />
                        </div>
                        <div class="mb-3">
                            <label for="whatsapp" class="form-label">whatsapp
                            </label>
                            <input type="text" class="form-control" v-model="newRecord.whatsapp" />
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="text" class="form-control" v-model="newRecord.email" />
                        </div>
                        <div class="mb-3">
                            <label for="jumlah_bersaudara" class="form-label">Jumlah bersaudara </label>
                            <input type="text" class="form-control" v-model="newRecord.jumlah_bersaudara" />
                        </div>

                        <div class="mb-3">
                            <label for="anak_ke" class="form-label">Anak
                                ke-</label>
                            <input type="text" class="form-control" v-model="newRecord.anak_ke" />
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label">Agama</label>
                            <select class="form-select" aria-label="Default select example" name="fakultas"
                                id="fakultas" v-model="newRecord.agama" required>
                                <option value="" selected disabled>- Pilih agama
                                </option>
                                <option v-for="list in agamaList" :key="list.nama" :value="list.nama">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label">Hobi</label>
                            <input type="text" class="form-control" v-model="newRecord.hobi" />
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label">Nama Ayah
                                Kandung</label>
                            <input type="text" class="form-control" v-model="newRecord.nama_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">No Hp
                                Ayah</label>
                            <input type="text" class="form-control" v-model="newRecord.no_hp_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">Pekerjaan
                                Ayah</label>
                            <input type="text" class="form-control" v-model="newRecord.pekerjaan_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">Pendidikan terakhir
                                ayah</label>
                            <input type="text" class="form-control" v-model="newRecord.pendidikan_terakhir_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">Pendapatan / Bulan
                                ayah</label>
                            <input type="text" class="form-control" v-model="newRecord.penghasilan_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label">Nama Ibu
                                Kandung</label>
                            <input type="text" class="form-control" v-model="newRecord.nama_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">No Hp
                                Ibu</label>
                            <input type="text" class="form-control" v-model="newRecord.no_hp_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Pekerjaan
                                Ibu</label>
                            <input type="text" class="form-control" v-model="newRecord.pekerjaan_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Pendidikan
                                terakhir Ibu</label>
                            <input type="text" class="form-control" v-model="newRecord.pendidikan_terakhir_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Pendapatan
                                / Bulan Ibu</label>
                            <input type="text" class="form-control" v-model="newRecord.penghasilan_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Alamat
                                Ortu</label>
                            <input type="text" class="form-control" v-model="newRecord.alamat_ortu" />
                        </div>
                        <div class="mb-3">
                            <label for="provinsi_ortu" class="form-label">Provinsi</label>
                            <v-select v-model="provinsi_ortu" :options="provinsiList" placeholder="Pilih Provinsi"
                                :searchable="true" ref="provinsi_ortu" class="form-control custom-select"></v-select>
                        </div>

                        <div class="mb-3">
                            <label for="kota_ortu" class="form-label">Kota</label>
                            <v-select v-model="kota_ortu" :options="kotaList_ortu" placeholder="Pilih Kota/Kabupaten"
                                :searchable="true" ref="kota_ortu" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kecamatan_ortu" class="form-label">Kecamatan</label>
                            <v-select v-model="kecamatan_ortu" :options="kecamatanList_ortu"
                                placeholder="Pilih Kecamatan" :searchable="true" ref="kecamatan_ortu"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kelurahan_ortu" class="form-label">Kelurahan</label>
                            <v-select v-model="kelurahan_ortu" :options="kelurahanList_ortu"
                                placeholder="Pilih Kelurahan" :searchable="true" ref="kelurahan_ortu"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Kodepos</label>
                            <input type="text" class="form-control" v-model="newRecord.kodepos_ortu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Nama
                                Sekolah</label>
                            <input type="text" class="form-control" v-model="newRecord.nama_sekolah" />
                        </div>
                        <div class="mb-3">
                            <label for="provinsi_sekolah" class="form-label">Provinsi Sekolah</label>
                            <v-select v-model="provinsi_sekolah" :options="provinsiList_sekolah"
                                placeholder="Pilih Provinsi" :searchable="true" ref="provinsi_sekolah"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kota_sekolah" class="form-label">Kota
                                Sekolah</label>
                            <v-select v-model="kota_sekolah" :options="kotaList_sekolah" placeholder="Pilih Kota"
                                :searchable="true" ref="kota_sekolah" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="jurusan sekolah" class="form-label">Jurusan Sekolah</label>
                            <input type="text" class="form-control" v-model="newRecord.jurusan_sekolah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Waktu
                                Kuliah</label>
                            <input type="text" class="form-control" v-model="newRecord.waktu_kuliah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Ukuran jas
                                akademis</label>
                            <input type="text" class="form-control" v-model="newRecord.ukuran_jas_akademis" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_tambah', 'close_modal_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>

                </div>

            </div>

        </div>
    </form>
    <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_edit">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data
                            mahasiswa</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_edit', 'close_modal_edit')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="mama" class="form-label">NIM
                                Mahasiswa <span class="text-secondary"> (akan berubah jika prodi diganti)</span></label>
                            <input type="text" class="form-control" v-model="currentRecord.nim_mahasiswa"
                                @keypress="CharOnly" required disabled />
                        </div>
                        <div class="mb-3">
                            <label for="mama" class="form-label">Nama</label>
                            <input type="text" class="form-control" v-model="currentRecord.nama_lengkap"
                                @keypress="CharOnly" required />
                        </div>
                        <div class="mb-3">
                            <label for="fakultas" class="form-label">Tahun
                                Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="fakultas"
                                id="fakultas" v-model="currentRecord.tahun_ajaran" required>
                                <option value="" selected disabled>- Pilih
                                    Tahun ajaran</option>
                                <option v-for="list in tahunAjaranList" :key="list.tahun_ajaran" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="status_akademik" class="form-label">Status Akademik</label>
                            <select class="form-select" aria-label="Default select example" name="status_akademik"
                                id="status_akademik" v-model="currentRecord.status_akademik" required>
                                <option value="" selected disabled>- Pilih
                                    Status Akademik</option>
                                <option v-for="list in statusAkademikList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="status_akses" class="form-label">Status Akses</label>
                            <select class="form-select" aria-label="Default select example" name="status_akses"
                                id="status_akademik" v-model="currentRecord.status_akses" required>
                                <option value="" selected disabled>- Pilih
                                    Status Akses</option>
                                <option value="AKTIF">AKTIF</option>
                                <option value="TIDAK">TIDAK</option>

                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="prodi" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="currentRecord.prodi" required>
                                <option value="" selected disabled>- Pilih
                                    Prodi</option>
                                <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="nisn_sekolah" class="form-label">NISN sekolah</label>
                            <input type="text" class="form-control" maxlength="10" minlength="10"
                                v-model="currentRecord.nisn_sekolah" required />
                        </div>
                        <div class="mb-3">
                            <label for="warga_negara" class="form-label">Kewarganegaraan</label>
                            <input type="text" class="form-control" v-model="currentRecord.warga_negara" required />
                        </div>
                        <div class="mb-3">
                            <label for="mama" class="form-label">No Induk
                                Kependudukan (NIK)</label>
                            <input type="text" class="form-control" minlength="16" maxlength="16"
                                v-model="currentRecord.no_ktp" required />
                        </div>

                        <div class="mb-3">
                            <label for="mama" class="form-label">No Kartu
                                Kelurga KK</label>
                            <input type="text" class="form-control" minlength="16" maxlength="16"
                                v-model="currentRecord.no_kk" />
                        </div>
                        <div class="mb-3">
                            <label for="tempat_lahir" class="form-label">Tempat lahir</label>
                            <input type="text" class="form-control" v-model="currentRecord.tempat_lahir" />
                        </div>
                        <div class="mb-3">
                            <label for="tanggal_lahir" class="form-label">Tanggal lahir</label>
                            <input type="date" class="form-control" v-model="currentRecord.tanggal_lahir" />
                        </div>
                        <div class="mb-3">
                            <label for="jenis_kelamin" class="form-label">Jenis kelamin</label>
                            <select class="form-select" aria-label="Default select example" name="list" id="list"
                                v-model="currentRecord.jenis_kelamin">
                                <option value="" selected disabled>- Pilih
                                    Jenis Kelamin </option>
                                <option value="Pria"> Pria
                                </option>
                                <option value="Wanita"> Wanita
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Alamat</label>
                            <input type="text" class="form-control" v-model="currentRecord.alamat_siswa" />
                        </div>
                        <div class="mb-3">
                            <label for="provinsi_update" class="form-label">Provinsi</label>
                            <v-select v-model="provinsi_update" :options="provinsiList" placeholder="Pilih Provinsi"
                                :searchable="true" ref="provinsi_update" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kabupaten" class="form-label">Kabupaten / Kota</label>
                            <v-select v-model="kota_update" :options="kotaList" placeholder="Pilih Kota"
                                :searchable="true" ref="kota" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kecamatan" class="form-label">Kecamatan</label>
                            <v-select v-model="kecamatan_update" :options="kecamatanList" placeholder="Pilih Kecamatan"
                                :searchable="true" ref="kecamatan" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kelurahan" class="form-label">Kelurahan</label>
                            <v-select v-model="kelurahan_update" :options="kelurahanList" placeholder="Pilih Kelurahan"
                                :searchable="true" ref="kelurahan" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kodepos" class="form-label">Kode
                                Pos</label>
                            <input type="text" class="form-control" maxlength="5" minlength="5"
                                v-model="currentRecord.kodepos" />
                        </div>
                        <div class="mb-3">
                            <label for="telepon_siswa" class="form-label">No
                                Telepon </label>
                            <input type="text" class="form-control" v-model="currentRecord.telepon_siswa" />
                        </div>
                        <div class="mb-3">
                            <label for="whatsapp" class="form-label">whatsapp </label>
                            <input type="text" class="form-control" v-model="currentRecord.whatsapp" />
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="text" class="form-control" v-model="currentRecord.email" />
                        </div>
                        <div class="mb-3">
                            <label for="jumlah_bersaudara" class="form-label">Jumlah bersaudara
                            </label>
                            <input type="text" class="form-control" v-model="currentRecord.jumlah_bersaudara" />
                        </div>

                        <div class="mb-3">
                            <label for="anak_ke" class="form-label">Anak
                                ke-</label>
                            <input type="text" class="form-control" v-model="currentRecord.anak_ke" />
                        </div>


                        <div class="mb-3">
                            <label for="agama" class="form-label">Agama</label>

                            <select class="form-select" aria-label="Default select example" name="fakultas"
                                id="fakultas" v-model="currentRecord.agama" required>
                                <option value="" selected disabled>- Pilih
                                    agama</option>
                                <option v-for="list in agamaList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="agama" class="form-label">Hobi</label>
                            <input type="text" class="form-control" v-model="currentRecord.hobi" />
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label">Nama Ayah
                                Kandung</label>
                            <input type="text" class="form-control" v-model="currentRecord.nama_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">No Hp
                                Ayah</label>
                            <input type="text" class="form-control" v-model="currentRecord.no_hp_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">Pekerjaan Ayah</label>
                            <div class="" v-if="isLainnyaPekerjaanAyah == false">
                                <select class="form-select" aria-label="Default select example" name="list2" id="list2"
                                    v-model="currentRecord.pekerjaan_ayah"
                                    @change="cekLainnyaPekerjaan('pekerjaan_ayah');">
                                    <option value="" selected disabled>Pilih
                                        Pekerjaan </option>
                                    <option v-for="list in pekerjaanList" :key="list.nama" :value="list.nama">
                                        {{ list.nama }}
                                    </option>
                                </select>
                            </div>
                            <div class="d-flex align-items-center justify-content-center gap-2"
                                v-if="isLainnyaPekerjaanAyah == true">
                                <div class="col-6">
                                    <select class="form-select" aria-label="Default select example" name="list2"
                                        id="list2" v-model="currentRecord.pekerjaan_ayah"
                                        @change="cekLainnyaPekerjaan('pekerjaan_ayah');">
                                        <option value="" selected disabled>
                                            Pilih
                                            Pekerjaan </option>
                                        <option v-for="list in pekerjaanList" :key="list.nama" :value="list.nama">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-6">
                                    <input type="text" name="pekerjaan_ayah_input" class="form-control"
                                        id="pekerjaan_ayah_input" v-model="isiLainnyaPekerjaanAyah" @input="charOnly" />
                                </div>

                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">Pendidikan terakhir
                                ayah</label>
                            <select class="form-select" aria-label="Default select example" name="list" id="list"
                                v-model="currentRecord.pendidikan_terakhir_ayah">
                                <option value="" selected disabled>Pilih
                                    Pendidikan terakhir :</option>
                                <option v-for="list in pendidikanTerakhirList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">Pendapatan / Bulan
                                ayah</label>
                            <input type="text"
                                @input="changerupiahInputInsert($event.target.value, 'penghasilan_ayah', 'update')"
                                class="form-control" v-model="currentRecord.penghasilan_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label">Nama Ibu
                                Kandung</label>
                            <input type="text" class="form-control" v-model="currentRecord.nama_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">No Hp
                                Ibu</label>
                            <input type="text" class="form-control" v-model="currentRecord.no_hp_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Pekerjaan Ibu</label>
                            <!-- <input type="text" class="form-control" v-model="currentRecord.pekerjaan_ibu" /> -->
                            <div class="" v-if="isLainnyaPekerjaanIbu == false">
                                <select class="form-select" aria-label="Default select example" name="list2" id="list2"
                                    v-model="currentRecord.pekerjaan_ibu"
                                    @change="cekLainnyaPekerjaan('pekerjaan_ibu');">
                                    <option value="" selected disabled>Pilih
                                        Pekerjaan </option>
                                    <option v-for="list in pekerjaanList" :key="list.nama" :value="list.nama">
                                        {{ list.nama }}
                                    </option>
                                </select>
                            </div>
                            <div class="d-flex align-items-center justify-content-center gap-2">
                                <div class="col-6" v-if="isLainnyaPekerjaanIbu == true">
                                    <select class="form-select" aria-label="Default select example" name="list2"
                                        id="list2" v-model="currentRecord.pekerjaan_ibu"
                                        @change="cekLainnyaPekerjaan('pekerjaan_ibu');">
                                        <option value="" selected disabled>
                                            Pilih
                                            Pekerjaan </option>
                                        <option v-for="list in pekerjaanList" :key="list.nama" :value="list.nama">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-6" v-if="isLainnyaPekerjaanIbu == true">
                                    <input type="text" name="pekerjaan_ibu_input" class="form-control"
                                        id="pekerjaan_ibu_input" v-model="isiLainnyaPekerjaanIbu" @input="charOnly" />
                                </div>

                            </div>

                        </div>




                        <div class="mb-3">
                            <label for="pendidikan_terakhir_ibu" class="form-label">
                                Pendidikan terakhir Ibu <span class="text-danger">*</span>
                            </label>
                            <select class="form-select" aria-label="Default select example" name="list" id="list"
                                v-model="currentRecord.pendidikan_terakhir_ibu">
                                <option value="" selected disabled>Pilih
                                    Pendidikan terakhir :</option>
                                <option v-for="list in pendidikanTerakhirList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Pendapatan / Bulan
                                Ibu</label>
                            <input type="text" class="form-control"
                                @input="changerupiahInputInsert($event.target.value, 'penghasilan_ibu', 'update')"
                                v-model="currentRecord.penghasilan_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Alamat
                                Ortu</label>
                            <input type="text" class="form-control" v-model="currentRecord.alamat_ortu" />
                        </div>
                        <div class="mb-3">
                            <label for="provinsi_ortu_update" class="form-label">Provinsi</label>
                            <v-select v-model="provinsi_ortu_update" :options="provinsiList_ortu"
                                placeholder="Pilih Provinsi" :searchable="true" ref="provinsi_ortu_update"
                                class="form-control custom-select"></v-select>
                        </div>

                        <div class="mb-3">
                            <label for="kota_ortu_update" class="form-label">Kota</label>
                            <v-select v-model="kota_ortu_update" :options="kotaList_ortu"
                                placeholder="Pilih Kota/Kabupaten" :searchable="true" ref="kota_ortu_update"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kecamatan_ortu_update" class="form-label">Kecamatan</label>
                            <v-select v-model="kecamatan_ortu_update" :options="kecamatanList_ortu"
                                placeholder="Pilih Kecamatan" :searchable="true" ref="kecamatan_ortu_update"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kelurahan_ortu_update" class="form-label">Kelurahan</label>
                            <v-select v-model="kelurahan_ortu_update" :options="kelurahanList_ortu"
                                placeholder="Pilih Kelurahan" :searchable="true" ref="kelurahan_ortu_update"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Kodepos</label>
                            <input type="text" class="form-control" v-model="currentRecord.kodepos_ortu" />
                        </div>

                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Nama
                                Sekolah</label>
                            <input type="text" class="form-control" v-model="currentRecord.nama_sekolah" />
                        </div>
                        <div class="mb-3">
                            <label for="provinsi_sekolah_update" class="form-label">Provinsi Sekolah</label>
                            <v-select v-model="provinsi_sekolah_update" :options="provinsiList_sekolah"
                                placeholder="Pilih Provinsi" :searchable="true" ref="provinsi_sekolah_update"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kota_sekolah_update" class="form-label">Kota Sekolah</label>
                            <v-select v-model="kota_sekolah_update" :options="kotaList_sekolah" placeholder="Pilih Kota"
                                :searchable="true" ref="kota_sekolah_update"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="jurusan sekolah" class="form-label">Jurusan Sekolah</label>
                            <input type="text" class="form-control" v-model="currentRecord.jurusan_sekolah" />
                        </div>

                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Waktu
                                Kuliah</label>
                            <select class="form-select" aria-label="Default select example" name="list" id="list"
                                v-model="currentRecord.waktu_kuliah">
                                <option value="" selected disabled>Pilih
                                    Waktu Kuliah : </option>
                                <option v-for="list in waktuKuliahList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Ukuran
                                jas akademis</label>
                            <select class="form-select" aria-label="Default select example" name="list" id="list"
                                v-model="currentRecord.ukuran_jas_akademis">
                                <option value="" selected disabled>Pilih
                                    Ukuran jas </option>
                                <option v-for="list in ukuranJasList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="foto ktp" class="form-label"> Foto Jas Akademis</label>
                            <div class="d-flex align-items-center gap-2">
                                <div class="">
                                    <img :src="currentRecord.foto_jas" alt=""
                                        style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                        class=" mb-2 border border-3" @click="openInNewTab(currentRecord.foto_jas)">
                                </div>
                                <div class="w-100">
                                    <input type="file" accept="image/png, image/jpeg" class="form-control"
                                        ref="foto_jas_update" />
                                </div>
                            </div>
                        </div>
                        <!-- <div class="mb-3">
                            <label for="jenis_penelitian " class="form-label"> Foto Pas</label>
                            <div class="d-flex align-items-center gap-2">
                                <div class="">
                                    <img :src="currentRecord.foto_pas" alt=""
                                        style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                        class=" mb-2 border border-3" @click="openInNewTab(currentRecord.foto_pas)">
                                </div>
                                <div class="w-100">
                                    <input type="file" accept="image/png, image/jpeg" class="form-control"
                                        ref="mahasiswa" />
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="mb-3">
                            <label for="foto ktp" class="form-label"> Foto Cover Rapor</label>
                            <div class="d-flex align-items-center gap-2">
                                <div class="">
                                    <img :src="currentRecord.foto_cover_rapor" alt=""
                                        style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                        class=" mb-2 border border-3" @click="openInNewTab(currentRecord.foto_cover_rapor)">
                                </div>
                                <div class="w-100">
                                    <input type="file" accept="image/png, image/jpeg" class="form-control"
                                        ref="mahasiswa" />
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="mb-3">
                            <label for="foto ktp" class="form-label"> Foto Cover Rapor</label>
                            <div class="d-flex align-items-center gap-2">
                                <div class="">
                                    <img :src="currentRecord.foto_cover_rapor" alt=""
                                        style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                        class=" mb-2 border border-3" @click="openInNewTab(currentRecord.foto_cover_rapor)">
                                </div>
                                <div class="w-100">
                                    <input type="file" accept="image/png, image/jpeg" class="form-control"
                                        ref="mahasiswa" />
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="mb-3">
                            <label for="foto ktp" class="form-label"> Foto Data diri Rapor</label>
                            <div class="d-flex align-items-center gap-2">
                                <div class="">
                                    <img :src="currentRecord.foto_data_diri_rapor" alt=""
                                        style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                        class=" mb-2 border border-3"
                                        @click="openInNewTab(currentRecord.foto_data_diri_rapor)">
                                </div>
                                <div class="w-100">
                                    <input type="file" accept="image/png, image/jpeg" class="form-control"
                                        ref="mahasiswa" />
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_edit', 'close_modal_edit')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data
                        tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_delete', 'close_modal_delete')">Tidak,
                        tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" id="modal_get_krs" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_get_krs">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">KRS Mahasiswa</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_get_krs"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_get_krs', 'close_modal_get_krs')">
                    </button>
                </div>

                <div class="modal-body">

                    <div class="d-flex gap-2 justify-content-start">
                        <div>Pilih Semester : </div>
                        <div v-for="(record) in semesterRecords" :key="record.id" :ref="record.id" class="d-flex">
                            <!-- <th scope="row" class="bg-transparent ">{{ index + 1 }}</th> -->
                            <button class="btn btn-primary btn-sm fs-7"
                                @click="setkrsId(krsId, record.counter_semester)">{{
                                    record.counter_semester
                                }}</button>
                        </div>
                    </div>

                    <div class="row" v-if="counter_semester != ''">
                        <div class="col-6">
                            <table>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Dosen Wali </td>
                                        <td>:</td>
                                        <td>&nbsp; {{ dosen_wali }}</td>
                                    </tr>
                                    <tr>
                                        <td>SKS yang telah diambil </td>
                                        <td>:</td>
                                        <td>&nbsp;{{ jumlah_sks_diambil }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>SkS yang boleh diambil </td>
                                        <td>:</td>
                                        <td>&nbsp;{{ jumlah_sks_diambil }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Kelas </td>
                                        <td>:</td>
                                        <td>&nbsp;{{ kelas }}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="col-6">
                            <table>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>SKS kumulatif </td>
                                        <td>:</td>
                                        <td>&nbsp;{{ jumlah_sks_diambil }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tahun Akademik </td>
                                        <td>:</td>
                                        <td>&nbsp;{{ tahun_akademik }}</td>
                                    </tr>
                                    <tr>
                                        <td>Semester </td>
                                        <td>:</td>
                                        <td>&nbsp;{{ counter_semester }} ({{
                                            tipe_semester }})</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                    <div class="border mt-1 rounded-3 me-2 font-custom-1" v-if="counter_semester != ''">
                        <table class="table mb-0 bg-transparent table-borderless " style="">
                            <thead>
                                <tr>
                                    <th scope="col" class="bg-transparent fs-7">No</th>
                                    <th scope="col" class="font-custom-1 fs-7">Kode mata
                                        kuliah </th>
                                    <th scope="col" class="font-custom-1 fs-7">Mata
                                        Kuliah </th>

                                    <th scope="col" class="font-custom-1 fs-7">sks </th>

                                </tr>

                            </thead>
                            <tbody>
                                <tr v-for="(record, index) in krsRecords" :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">
                                        {{ index + 1 }}</th>
                                    <td>{{ record.kode }}</td>
                                    <td>{{ record.mata_kuliah }}</td>
                                    <td>{{ record.sks }}</td>
                                    <td class="bg-transparent">
                                        <!-- <div class="d-flex gap-2"> -->
                                        <!-- <button class="btn btn-danger btn-sm fs-8" data-bs-toggle="modal"
                                                data-bs-target="#modal_delete" v-on:click="deleteRecord(record.id)"><i
                                                    class='bx bxs-trash-alt fs-8'></i></button> -->
                                        <!-- </div> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </div>
    </div>

    <div class="modal fade" id="modal_view" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_view">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">View Data mahasiswa
                    </h5>
                    <button hidden type="button" ref="close_modal_view"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_view')">
                    </button>
                </div>

                <div class="modal-body">
                    <div id="pdf-content" class=" pdf-content container  position-relative" ref="pdf_content">
                        <div class=" position-absolute d-flex align-items-end rounded-bottom"
                            style="height: 300px; background-color: #003970; border-radius: 0 0 30px 30px!important; top:-20px;">
                            <div>
                                <img :src="currentRecord.foto_jas" alt=""
                                    style="width: 2in; height: 2in; object-fit: cover; object-position: top; cursor: pointer;"
                                    class="rounded-circle mb-4 mx-3 " @click="source_foto = currentRecord.foto_jas"
                                    v-on:click="showModal2('lihat_foto')">
                            </div>
                        </div>

                        <div class="text-end pt-3">
                            <img src="@/assets/img/logo_st_bhinneka.png" alt="" class="" width="170">
                        </div>

                        <div class="row">
                            <div class="col-3 text-center">
                            </div>
                            <div class="col-9 text-start" style="color: #003970; ">
                                <div class="fs-1  fw-bold" style="letter-spacing: 2px;">
                                    {{ currentRecord.nama_lengkap }}
                                </div>
                                <div class="fs-4 fw-bold">
                                    {{ currentRecord.prodi_pt }}
                                </div>
                                <div class="fs-5 ps-2">
                                    <div class="row ps-2 mb-2">
                                        <div class="col-4 row ps-2 align-items-center">
                                            <div class="fw-bold col-3" style="font-size: 15px">
                                                NIM
                                            </div>
                                            <div class=" fs-6 col-9">
                                                {{
                                                    currentRecord.nim_mahasiswa
                                                }}
                                            </div>
                                        </div>

                                        <div class="col-8  d-flex gap-3 align-items-center justify-content-start">
                                            <div class="fw-bold fs-6 d-flex justify-content-start align-items-center">
                                                <i class='bx bxl-gmail'></i>
                                            </div>
                                            <div class="ps-2" style="font-size: 15px;">
                                                {{ currentRecord.email }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ps-2 mb-2">
                                        <div class="col-4 row ps-2 align-items-center">
                                            <div class="fw-bold col-3" style="font-size: 15px">
                                                <i class='bx bxs-user-badge me-2'></i>
                                            </div>
                                            <div class=" fs-6 col-9">
                                                <span v-if="currentRecord.jalur == 'jalur_kuliah'">Mandiri</span>
                                                <span v-else-if="currentRecord.jalur == 'KIP'">KIP</span>
                                                <span
                                                    v-else-if="currentRecord.jalur == 'scholarship'">Scholarship</span>
                                            </div>
                                        </div>
                                        <div class="col-5 row align-items-center">
                                            <div class="fw-bold col-1" style="font-size: 15px">
                                                Wali
                                            </div>
                                            <div class="ps-4 col-11 fs-6 ">
                                                {{ currentRecord.dosen_wali }}
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row ps-2 mb-2">
                                        <div class="col-4 row ps-2 align-items-center">
                                            <div class="fw-bold col-3">
                                                <i class='bx bxs-phone'></i>
                                            </div>
                                            <div class=" col-9" style="font-size: 15px;">
                                                {{
                                                    currentRecord.telepon_siswa
                                                }}
                                            </div>
                                        </div>
                                        <div class="col-5 row align-items-center">
                                            <div class="col-1 fw-bold  ">
                                                <i class='bx bxl-whatsapp'></i>
                                            </div>
                                            <div class="ps-4 col-11" style="font-size: 15px;">
                                                {{ currentRecord.whatsapp }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ps-2 mb-2">
                                        <div class="col-4 row ps-2 align-items-center">
                                            <div class="fw-bold col-3">
                                                <i class='bx bx-door-open'></i>
                                            </div>
                                            <div class=" col-9" style="font-size: 15px;">
                                                Kelas {{ currentRecord.kelas
                                                }}
                                            </div>
                                        </div>
                                        <div class="col-5 row align-items-center">
                                            <div class="col-1 fw-bold  ">
                                                <i class='bx bx-calendar-week'></i>
                                            </div>
                                            <div class="ps-4 col-11" style="font-size: 15px;">
                                                Semester {{
                                                    currentRecord.semester }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row ps-2 mb-2">
                                        <div class="col-4 row ps-2 align-items-center">
                                            <div class="fw-bold col-3">
                                                <i class='bx bxs-graduation'></i>
                                            </div>
                                            <div class=" col-9" style="font-size: 15px;">
                                                {{ currentRecord.status_akademik
                                                }}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="mt-3">
                            <div class="pt-5">
                                <ul class="nav nav-tabs   rounded justify-content-between"
                                    style="background-color: #003970;">
                                    <li class="nav-item " @click="setActiveTab('identitas_diri')"
                                        style="cursor:pointer;">
                                        <a class="nav-link text-light fw-bold" id="identitas_diri"
                                            ref="identitas_diri">Identitas
                                            Diri</a>
                                    </li>
                                    <li class="nav-item" @click="setActiveTab('data_keluarga')" style="cursor:pointer;">
                                        <a class="nav-link text-light fw-bold" id="data_keluarga"
                                            ref="data_keluarga">Data
                                            keluarga</a>
                                    </li>
                                    <li class="nav-item" @click="setActiveTab('data_sekolah')" style="cursor:pointer;">
                                        <a class="nav-link text-light fw-bold" id="data_sekolah" ref="data_sekolah">Data
                                            Sekolah</a>
                                    </li>
                                    <li class="nav-item" @click="setActiveTab('pilihan')" style="cursor:pointer;">
                                        <a class="nav-link text-light fw-bold" id="pilihan" ref="pilihan">Pilihan</a>
                                    </li>
                                    <li class="nav-item" @click="setActiveTab('dokumen')" style="cursor:pointer;">
                                        <a class="nav-link text-light fw-bold" id="dokumen" ref="dokumen">Dokumen</a>
                                    </li>
                                    <li class="nav-item" @click="setActiveTab('dokumen_2')" style="cursor:pointer;">
                                        <a class="nav-link text-light fw-bold" id="dokumen_2" ref="dokumen_2">Dokumen
                                            lainnya</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="this.pageActive == 'identitas_diri'" class="border px-4 mb-4">
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Warga negara
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.warga_negara }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Nama depan
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.nama_depan }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Nama tengah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.nama_tengah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Nama belakang
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.nama_belakang }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Tempat lahir
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.tempat_lahir }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Tanggal lahir
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.tanggal_lahir }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Jenis kelamin
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.jenis_kelamin }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Alamat tempat tinggal
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.alamat_siswa }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Provinsi
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.provinsi }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kota
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kota }}
                                </div>
                            </div>

                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kecamatan
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kecamatan }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kelurahan
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kelurahan }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kode pos
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kodepos }}
                                </div>
                            </div>

                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Agama
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.agama }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Hobi
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.hobi }}
                                </div>
                            </div>

                        </div>
                        <div v-if="this.pageActive == 'data_keluarga'" class="border px-4 mb-4">
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Jumlah Saudara
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.jumlah_bersaudara }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Anak Ke
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.anak_ke }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Nama Ayah Kandung
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.nama_ayah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    NIK Ayah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.nik_ayah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    No Hp Ayah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.no_hp_ayah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Pekerjaan Ayah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.pekerjaan_ayah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Pendidikan terakhir ayah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{
                                        currentRecord.pendidikan_terakhir_ayah
                                    }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Pendapatan Bulan Ayah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{
                                        rupiah(currentRecord.penghasilan_ayah)
                                    }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Nama Ibu Kandung
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.nama_ibu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    NIK Ibu
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.nik_ibu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    No Hp Ibu
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.no_hp_ibu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Pekerjaan Ibu
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.pekerjaan_ibu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Pendidikan terakhir ibu
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.pendidikan_terakhir_ibu
                                    }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Pendapatan Bulan Ibu
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ rupiah(currentRecord.penghasilan_ibu)
                                    }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Alamat Orang Tua
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.alamat_ortu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Provinsi Orang Tua
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.provinsi_ortu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kota Orang Tua
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kota_ortu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kecamatan Orang Tua
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kecamatan_ortu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kelurahan Orang Tua
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kelurahan_ortu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kode Pos Orang tua
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kodepos_ortu }}
                                </div>
                            </div>

                        </div>
                        <div v-if="this.pageActive == 'data_sekolah'" class="border px-4 mb-4">
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Nama Sekolah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.nama_sekolah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Provinsi Sekolah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.provinsi_sekolah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kota Sekolah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kota_sekolah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Jurusan Sekolah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.jurusan_sekolah }}
                                </div>
                            </div>
                        </div>
                        <div v-if="this.pageActive == 'pilihan'" class="border px-4 mb-4">
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Program Studi yang diminati
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.prodi_pt }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Waktu kuliah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.waktu_kuliah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Ukuran jas akademis
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.ukuran_jas_akademis }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Tahun Ajaran
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.tahun_ajaran }}
                                </div>
                            </div>

                        </div>
                        <div v-if="this.pageActive == 'dokumen'"
                            class="border px-2 py-2 mb-4 d-flex justify-content-between">
                            <div>
                                <div class="">
                                    <img :src="currentRecord.foto_cover_rapor" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto_2 = currentRecord.foto_cover_rapor; posisi_foto = 1; nama_foto_2 = 'Foto Cover Rapor'"
                                        v-on:click="showModal2('lihat_foto2')">
                                </div>
                                <div class="text-center">
                                    Foto Cover Rapor
                                </div>
                            </div>
                            <div>
                                <div class="">
                                    <img :src="currentRecord.foto_kartu_keluarga" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto_2 = currentRecord.foto_kartu_keluarga; posisi_foto = 2; nama_foto_2 = 'Foto Kartu keluarga'"
                                        v-on:click="showModal2('lihat_foto2')">
                                </div>
                                <div class=" text-center">
                                    Foto Kartu keluarga
                                </div>
                            </div>
                            <div>
                                <div class="">
                                    <img :src="currentRecord.foto_data_diri_rapor" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto_2 = currentRecord.foto_data_diri_rapor; posisi_foto = 3; nama_foto_2 = 'Foto Data Diri Rapor'"
                                        v-on:click="showModal2('lihat_foto2')">
                                </div>
                                <div class="text-center">
                                    Foto Data Diri Rapor
                                </div>
                            </div>
                            <div>
                                <div class="">
                                    <img :src="currentRecord.foto_rapor_kelas_xii_ganjil_genap" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto_2 = currentRecord.foto_rapor_kelas_xii_ganjil_genap; posisi_foto = 4; nama_foto_2 = 'Foto Rapor Kelas 12 Ganjil/Genap'"
                                        v-on:click="showModal2('lihat_foto2')">
                                </div>
                                <div class="text-center">
                                    Foto Rapor Kelas 12 <br> Ganjil/Genap
                                </div>
                            </div>
                            <div>
                                <div class="">
                                    <img :src="currentRecord.foto_skl_skhun" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto_2 = currentRecord.foto_skl_skhun; posisi_foto = 5; nama_foto_2 = 'SKL/SKHUN'"
                                        v-on:click="showModal2('lihat_foto2')">
                                </div>
                                <div class="text-center">
                                    SKL/SKHUN
                                </div>
                            </div>
                            <div>
                                <div class="">
                                    <img :src="currentRecord.foto_ijazah" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto_2 = currentRecord.foto_ijazah; posisi_foto = 6; nama_foto_2 = 'Foto Ijazah'"
                                        v-on:click="showModal2('lihat_foto2')">
                                </div>
                                <div class="text-center">
                                    Foto Ijazah
                                </div>
                            </div>
                            <!-- <div>
                                <div class="">
                                    <img :src="currentRecord.foto_jas" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto = currentRecord.foto_jas; posisi_foto = 7; nama_foto = 'Foto Almameter'"
                                        v-on:click="showModal2('lihat_foto2')">
                                </div>
                                <div class="text-center">
                                    Foto Almameter
                                </div>
                            </div> -->
                        </div>
                        <div v-if="this.pageActive == 'dokumen_2'"
                            class="border px-2 py-2 mb-4 d-flex justify-content-start gap-4">
                            <div>
                                <div class="">
                                    <img :src="currentRecord.foto_ktp_siswa" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto_2 = currentRecord.foto_ktp_siswa; posisi_foto = 7; nama_foto_2 = 'Foto KTP'"
                                        v-on:click="showModal2('lihat_foto2')">
                                </div>
                                <div class="text-center">
                                    Foto KTP
                                </div>
                            </div>
                            <div>
                                <div class="">
                                    <img :src="currentRecord.foto_rumah_depan" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto_2 = currentRecord.foto_rumah_depan; posisi_foto = 8; nama_foto_2 = 'Foto Pendukung lainnya 1'"
                                        v-on:click="showModal2('lihat_foto2')">
                                </div>
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <div>
                                        Foto Pendukung </div>
                                    <div>
                                        lainnya 1
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div class="">
                                    <img :src="currentRecord.foto_rumah_dalam" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto_2 = currentRecord.foto_rumah_dalam; posisi_foto = 9; nama_foto_2 = 'Foto Pendukung lainnya 2'"
                                        v-on:click="showModal2('lihat_foto2')">
                                </div>
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <div>
                                        Foto Pendukung </div>
                                    <div>
                                        lainnya 2
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div class="">
                                    <img :src="currentRecord.foto_jas" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto_2 = currentRecord.foto_jas; posisi_foto = 10; nama_foto_2 = 'Foto Almameter'"
                                        v-on:click="showModal2('lihat_foto2')">
                                </div>
                                <div class="text-center">
                                    Foto Almameter
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_view')">Tutup</button>
                    <!-- <button type="submit" class="btn bg-green-custom text-light">
                        Simpan </button> -->
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade bg-custom-gray" id="lihat_foto" data-bs-backdrop="static" role="dialog" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true" ref="lihat_foto">
        <div class="text-end position-absolute end-0 me-4 mt-2 " style="z-index: 9000!important;">
            <span class=" d-flex align-items-center" style="cursor: pointer;" v-on:click="hideModal2('lihat_foto')"><i
                    class='bx bx-x fs-1 text-danger fw-bold'></i>
            </span>
        </div>
        <div class="modal-dialog modal-xl modal-dialog-centered ">
            <div class="mb-3 w-100 text-center ">
                <img :src="source_foto" alt="" style="max-width: 100%; max-height: 80vh;">
            </div>
        </div>
    </div>



    <div class="modal fade" id="modal_absensi" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_absensi">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Absensi Mahasiswa</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_absensi"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_absensi', 'close_modal_absensi')">
                    </button>
                </div>

                <div class="modal-body">

                    <div class="d-flex gap-2 justify-content-start ">

                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="id_tahun_ajaran_absensi"
                            @change=" record_absensi = []; id_semester_absensi = ''; get_absensi();" required>
                            <option value="" selected disabled>- Pilih Tahun ajaran</option>
                            <option v-for="list in tahun_ajaran_list_absensi" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="id_semester_absensi" @change="get_absensi()" required
                            :disabled="id_tahun_ajaran_absensi == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semester_list_absensi" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>

                    <div class="border mt-1 rounded-3 me-2 font-custom-1">
                        <table class="table mb-0 bg-transparent table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col" class="bg-transparent fs-7">No</th>
                                    <th scope="col" class="font-custom-1 fs-7">NIM </th>
                                    <th scope="col" class="font-custom-1 fs-7">Nama Lengkap </th>
                                    <th scope="col" class="font-custom-1 fs-7">kode Matkul</th>
                                    <th scope="col" class="font-custom-1 fs-7">Mata Kuliah</th>
                                    <th scope="col" class="font-custom-1 fs-7">tipe</th>
                                    <th scope="col" class="font-custom-1 fs-7">M1</th>
                                    <th scope="col" class="font-custom-1 fs-7">M2</th>
                                    <th scope="col" class="font-custom-1 fs-7">M3</th>
                                    <th scope="col" class="font-custom-1 fs-7">M4</th>
                                    <th scope="col" class="font-custom-1 fs-7">M5</th>
                                    <th scope="col" class="font-custom-1 fs-7">M6</th>
                                    <th scope="col" class="font-custom-1 fs-7">M7</th>
                                    <th scope="col" class="font-custom-1 fs-7">M8</th>
                                    <th scope="col" class="font-custom-1 fs-7">M9</th>
                                    <th scope="col" class="font-custom-1 fs-7">M10</th>
                                    <th scope="col" class="font-custom-1 fs-7">M11</th>
                                    <th scope="col" class="font-custom-1 fs-7">M12</th>
                                    <th scope="col" class="font-custom-1 fs-7">M13</th>
                                    <th scope="col" class="font-custom-1 fs-7">M14</th>
                                    <th scope="col" class="font-custom-1 fs-7">M15</th>
                                    <th scope="col" class="font-custom-1 fs-7">M16</th>
                                    <th scope="col" class="font-custom-1 fs-7">jumlah kehadiran</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr v-for="(record, index) in record_absensi" :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">
                                        {{ index + 1 }}</th>
                                    <td>{{ record.nim_mahasiswa }}</td>
                                    <td>{{ record.nama_lengkap }}</td>
                                    <td>{{ record.kode }}</td>
                                    <td>{{ record.nama }}</td>
                                    <td>{{ record.tipe }}</td>
                                    <td>{{ record.M1 }}</td>
                                    <td>{{ record.M2 }}</td>
                                    <td>{{ record.M3 }}</td>
                                    <td>{{ record.M4 }}</td>
                                    <td>{{ record.M5 }}</td>
                                    <td>{{ record.M6 }}</td>
                                    <td>{{ record.M7 }}</td>
                                    <td>{{ record.M8 }}</td>
                                    <td>{{ record.M9 }}</td>
                                    <td>{{ record.M10 }}</td>
                                    <td>{{ record.M11 }}</td>
                                    <td>{{ record.M12 }}</td>
                                    <td>{{ record.M13 }}</td>
                                    <td>{{ record.M14 }}</td>
                                    <td>{{ record.M15 }}</td>
                                    <td>{{ record.M16 }}</td>
                                    <td>{{ record.jumlah_kehadiran }}</td>
                                    <td class="bg-transparent">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>



            </div>
        </div>
    </div>


    <div class="modal fade" id="modal_dokumen" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_dokumen">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Dokumen</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_dokumen"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_dokumen')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-3">
                            <div class="card">
                                <div class="card-body pt-0 px-0">
                                    <div class="text-start bg-green-custom text-light py-2 px-2 mb-3 rounded-top">
                                        Foto KTP siswa
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <img :src="currentRecord.foto_ktp_siswa" alt=""
                                            style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                            @click="source_foto_2 = currentRecord.foto_ktp_siswa; posisi_foto = 4; nama_foto_2 = 'Foto KTP '"
                                            v-on:click="showModal2('lihat_foto2')" class="rounded border border-5"
                                            :class="{
                                                'border-danger': currentRecord.terkirim == '2' || currentRecord.terkirim == 2,
                                                'border-success': currentRecord.terkirim == '3' || currentRecord.terkirim == 3,
                                                'border-warning': currentRecord.terkirim == '4' || currentRecord.terkirim == 4
                                            }">
                                    </div>
                                    <hr>
                                    <div class="d-flex justify-content-center align-items-center gap-2 h-100 mt-2">
                                        <button type="button" class="btn btn-danger"
                                            @click="nama_dokumen = 'foto_ktp_siswa'; nama_dokumen_status = 'terkirim'; value_dokumen = '2'; showModal2('modal_tolak_dokumen')">Tolak</button>
                                        <button type="button" class="btn bg-green-custom text-light"
                                            @click="nama_dokumen = 'foto_ktp_siswa'; nama_dokumen_status = 'terkirim'; value_dokumen = '3'; verifikasi_dokumen()">Verifikasi</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="card">
                                <div class="card-body pt-0 px-0">
                                    <div class="text-start bg-green-custom text-light py-2 px-2 mb-3 rounded-top">
                                        Foto Kartu keluarga
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <img :src="currentRecord.foto_kartu_keluarga" alt=""
                                            style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                            @click="source_foto_2 = currentRecord.foto_kartu_keluarga; posisi_foto = 5; nama_foto_2 = 'Foto Kartu keluarga'"
                                            class="rounded border border-5" v-on:click="showModal2('lihat_foto2')"
                                            :class="{
                                                'border-danger': currentRecord.st_ck_kk == '2' || currentRecord.st_ck_kk == 2,
                                                'border-success': currentRecord.st_ck_kk == '3' || currentRecord.st_ck_kk == 3,
                                                'border-warning': currentRecord.st_ck_kk == '4' || currentRecord.st_ck_kk == 4
                                            }">

                                    </div>

                                    <hr>
                                    <div class="d-flex justify-content-center align-items-center gap-2 h-100 mt-2">
                                        <button type="button" class="btn btn-danger"
                                            @click="nama_dokumen = 'foto_kartu_keluarga'; nama_dokumen_status = 'st_ck_kk'; value_dokumen = '2'; showModal2('modal_tolak_dokumen')">Tolak</button>
                                        <button type="button" class="btn bg-green-custom text-light"
                                            @click="nama_dokumen = 'foto_kartu_keluarga'; nama_dokumen_status = 'st_ck_kk'; value_dokumen = '3'; verifikasi_dokumen()">Verifikasi</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="card ">
                                <div class="card-body pt-0 px-0  ">
                                    <div class="text-start bg-green-custom text-light py-2 px-2 mb-3 rounded-top">
                                        Foto Akta Kelahiran
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center ">
                                        <img :src="currentRecord.foto_rumah_dapur" alt=""
                                            style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                            @click="source_foto_2 = currentRecord.foto_rumah_dapur; posisi_foto = 6; nama_foto_2 = 'Foto Akta Kelahiran'"
                                            v-on:click="showModal2('lihat_foto2')" class="rounded border border-5"
                                            :class="{
                                                'border-danger': currentRecord.st_ck_rapor == '2' || currentRecord.st_ck_rapor == 2,
                                                'border-success': currentRecord.st_ck_rapor == '3' || currentRecord.st_ck_rapor == 3,
                                                'border-warning': currentRecord.st_ck_rapor == '4' || currentRecord.st_ck_rapor == 4
                                            }">
                                    </div>
                                    <hr>
                                    <div class=" d-flex justify-content-center align-items-center gap-2 h-100 mt-2">
                                        <button type="button" class="btn btn-danger"
                                            @click="nama_dokumen = 'foto_akta_kelahiran'; nama_dokumen_status = 'st_ck_rapor'; value_dokumen = '2'; showModal2('modal_tolak_dokumen')">Tolak</button>
                                        <button type="button" class="btn bg-green-custom text-light"
                                            @click="nama_dokumen = 'foto_akta_kelahiran'; nama_dokumen_status = 'st_ck_rapor'; value_dokumen = '3'; verifikasi_dokumen()">Verifikasi</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-3">
                            <div class="card ">
                                <div class="card-body pt-0 px-0  ">
                                    <div class="text-start bg-green-custom text-light py-2 px-2 mb-3 rounded-top">
                                        Foto Cover Rapor
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center ">
                                        <img :src="currentRecord.foto_cover_rapor" alt=""
                                            style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                            @click="source_foto = currentRecord.foto_cover_rapor; posisi_foto = 1; nama_foto = 'Foto Cover Rapor'"
                                            v-on:click="showModal2('lihat_foto2')" class="rounded border border-5" :class="{
                                                'border-danger': currentRecord.st_ck_rapor == '2' || currentRecord.st_ck_rapor == 2,
                                                'border-success': currentRecord.st_ck_rapor == '3' || currentRecord.st_ck_rapor == 3,
                                                'border-warning': currentRecord.st_ck_rapor == '1' || currentRecord.st_ck_rapor == 1
                                            }">
                                    </div>
                                    <hr>
                                    <div class=" d-flex justify-content-center align-items-center gap-2 h-100 mt-2">
                                        <button type="button" class="btn btn-danger"
                                            @click="nama_dokumen = 'foto_cover_rapor'; nama_dokumen_status = 'st_ck_rapor'; value_dokumen = '2'; showModal2('modal_tolak_dokumen')">Tolak</button>
                                        <button type="button" class="btn bg-green-custom text-light"
                                            @click="nama_dokumen = 'foto_cover_rapor'; nama_dokumen_status = 'st_ck_rapor'; value_dokumen = '3'; verifikasi_dokumen()">Verifikasi</button>
                                    </div>
                                </div>
                            </div>
                        </div> -->


                        <div class="col-3">
                            <div class="card">
                                <div class="card-body pt-0 px-0">
                                    <div class="text-start bg-green-custom text-light py-2 px-2 mb-3 rounded-top">
                                        Foto Ijazah
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <img :src="currentRecord.foto_ijazah" alt=""
                                            style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                            @click="source_foto_2 = currentRecord.foto_ijazah; posisi_foto = 7; nama_foto_2 = 'Foto Ijazah'"
                                            v-on:click="showModal2('lihat_foto2')" class="rounded border border-5"
                                            :class="{
                                                'border-danger': currentRecord.st_ck_ijizah == '2' || currentRecord.st_ck_ijizah == 2,
                                                'border-success': currentRecord.st_ck_ijizah == '3' || currentRecord.st_ck_ijizah == 3,
                                                'border-warning': currentRecord.st_ck_ijizah == '4' || currentRecord.st_ck_ijizah == 4
                                            }">
                                    </div>
                                    <hr>
                                    <div class="d-flex justify-content-center align-items-center gap-2 h-100 mt-2">
                                        <button type="button" class="btn btn-danger"
                                            @click="nama_dokumen = 'foto_ijazah'; nama_dokumen_status = 'st_ck_ijizah'; value_dokumen = '2'; showModal2('modal_tolak_dokumen')">Tolak</button>
                                        <button type="button" class="btn bg-green-custom text-light"
                                            @click="nama_dokumen = 'foto_ijazah'; nama_dokumen_status = 'st_ck_ijizah'; value_dokumen = '3'; verifikasi_dokumen()">Verifikasi</button>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!-- <div class="col-3">
                            <div class="card">
                                <div class="card-body pt-0 px-0">
                                    <div class="text-start bg-green-custom text-light py-2 px-2 mb-3 rounded-top">
                                        Foto Data Diri Rapor
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <img :src="currentRecord.foto_data_diri_rapor" alt=""
                                            style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                            @click="source_foto = currentRecord.foto_data_diri_rapor; posisi_foto = 3; nama_foto = 'Foto Data Diri Rapor'"
                                            v-on:click="showModal2('lihat_foto2')" class="rounded border border-5" :class="{
                                                'border-danger': currentRecord.st_ck_datadirirapor == '2' || currentRecord.st_ck_datadirirapor == 2,
                                                'border-success': currentRecord.st_ck_datadirirapor == '3' || currentRecord.st_ck_datadirirapor == 3,
                                                'border-warning': currentRecord.st_ck_datadirirapor == '1' || currentRecord.st_ck_datadirirapor == 1
                                            }">
                                    </div>

                                    <hr>
                                    <div class="d-flex justify-content-center align-items-center gap-2 h-100 mt-2">
                                        <button type="button" class="btn btn-danger"
                                            @click="nama_dokumen = 'foto_data_diri_rapor'; nama_dokumen_status = 'st_ck_datadirirapor'; value_dokumen = '2'; showModal2('modal_tolak_dokumen')">Tolak</button>
                                        <button type="button" class="btn bg-green-custom text-light"
                                            @click="nama_dokumen = 'foto_data_diri_rapor'; nama_dokumen_status = 'st_ck_datadirirapor'; value_dokumen = '3'; verifikasi_dokumen()">Verifikasi</button>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="col-3">
                            <div class="card">
                                <div class="card-body pt-0 px-0">
                                    <div class="text-start bg-green-custom text-light py-2 px-2 mb-3 rounded-top">
                                        Foto Rapor Kelas 12 Ganjil/Genap
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <img :src="currentRecord.foto_rapor_kelas_xii_ganjil_genap" alt=""
                                            style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                            @click="source_foto = currentRecord.foto_rapor_kelas_xii_ganjil_genap; posisi_foto = 4; nama_foto = 'Foto Rapor Kelas 12 Ganjil/Genap'"
                                            v-on:click="showModal2('lihat_foto2')" class="rounded border border-5" :class="{
                                                'border-danger': currentRecord.st_ck_kelas_xii_genap_ganjil == '2' || currentRecord.st_ck_kelas_xii_genap_ganjil == 2,
                                                'border-success': currentRecord.st_ck_kelas_xii_genap_ganjil == '3' || currentRecord.st_ck_kelas_xii_genap_ganjil == 3,
                                                'border-warning': currentRecord.st_ck_kelas_xii_genap_ganjil == '1' || currentRecord.st_ck_kelas_xii_genap_ganjil == 1
                                            }">

                                    </div>

                                    <hr>
                                    <div class="d-flex justify-content-center align-items-center gap-2 h-100 mt-2">
                                        <button type="button" class="btn btn-danger"
                                            @click="nama_dokumen = 'foto_rapor_kelas_xii_ganjil_genap'; nama_dokumen_status = 'st_ck_kelas_xii_genap_ganjil'; value_dokumen = '2'; showModal2('modal_tolak_dokumen')">Tolak</button>
                                        <button type="button" class="btn bg-green-custom text-light"
                                            @click="nama_dokumen = 'foto_rapor_kelas_xii_ganjil_genap'; nama_dokumen_status = 'st_ck_kelas_xii_genap_ganjil'; value_dokumen = '3'; verifikasi_dokumen()">Verifikasi</button>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="row mt-2">

                        <!-- <div class="col-3">
                            <div class="card">
                                <div class="card-body pt-0 px-0">
                                    <div class="text-start bg-green-custom text-light py-2 px-2 mb-3 rounded-top">
                                        Foto SKL/SKHUN
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <img :src="currentRecord.foto_skl_skhun" alt=""
                                            style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                            @click="source_foto = currentRecord.foto_skl_skhun; posisi_foto = 5; nama_foto = 'SKL/SKHUN'"
                                            v-on:click="showModal2('lihat_foto2')" class="rounded border border-5" :class="{
                                                'border-danger': currentRecord.st_ck_skl_skhun == '2' || currentRecord.st_ck_skl_skhun == 2,
                                                'border-success': currentRecord.st_ck_skl_skhun == '3' || currentRecord.st_ck_skl_skhun == 3,
                                                'border-warning': currentRecord.st_ck_skl_skhun == '1' || currentRecord.st_ck_skl_skhun == 1
                                            }">

                                    </div>
                                    <hr>
                                    <div class="d-flex justify-content-center align-items-center gap-2 h-100 mt-2">
                                        <button type="button" class="btn btn-danger"
                                            @click="nama_dokumen = 'foto_skl_skhun'; nama_dokumen_status = 'st_ck_skl_skhun'; value_dokumen = '2'; showModal2('modal_tolak_dokumen')">Tolak</button>
                                        <button type="button" class="btn bg-green-custom text-light"
                                            @click="nama_dokumen = 'foto_skl_skhun'; nama_dokumen_status = 'st_ck_skl_skhun'; value_dokumen = '3'; verifikasi_dokumen()">Verifikasi</button>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                    </div>
                    <div class='row mt-2'>
                        <div class="col-3">
                            <div class="card">
                                <div class="card-body pt-0 px-0">
                                    <div class="text-start bg-green-custom text-light py-2 px-2 mb-3 rounded-top">
                                        Foto Pendukung lainnya 1
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <img :src="currentRecord.foto_rumah_depan" alt=""
                                            style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                            @click="source_foto_2 = currentRecord.foto_rumah_depan; posisi_foto = 8; nama_foto_2 = 'Foto Pendukung lainnya 1'"
                                            v-on:click="showModal2('lihat_foto2')" class="rounded border border-5"
                                            :class="{
                                                'border-danger': currentRecord.terkirim_reminder == '2' || currentRecord.terkirim_reminder == 2,
                                                'border-success': currentRecord.terkirim_reminder == '3' || currentRecord.terkirim_reminder == 3,
                                                'border-warning': currentRecord.terkirim_reminder == '4' || currentRecord.terkirim_reminder == 4
                                            }">
                                    </div>
                                    <hr>
                                    <div class="d-flex justify-content-center align-items-center gap-2 h-100 mt-2">
                                        <button type="button" class="btn btn-danger"
                                            @click="nama_dokumen = 'foto_pendukung_lainnya_1'; nama_dokumen_status = 'terkirim_reminder'; value_dokumen = '2'; showModal2('modal_tolak_dokumen')">Tolak</button>
                                        <button type="button" class="btn bg-green-custom text-light"
                                            @click="nama_dokumen = 'foto_pendukung_lainnya_1'; nama_dokumen_status = 'terkirim_reminder'; value_dokumen = '3'; verifikasi_dokumen()">Verifikasi</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-3">
                            <div class="card">
                                <div class="card-body pt-0 px-0">
                                    <div class="text-start bg-green-custom text-light py-2 px-2 mb-3 rounded-top">
                                        Foto Pendukung lainnya 2
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <img :src="currentRecord.foto_rumah_dalam" alt=""
                                            style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                            @click="source_foto_2 = currentRecord.foto_rumah_dalam; posisi_foto = 9; nama_foto_2 = 'Foto Pendukung lainnya 2'"
                                            v-on:click="showModal2('lihat_foto2')" class="rounded border border-5"
                                            :class="{
                                                'border-danger': currentRecord.wawancara_wa_notif == '2' || currentRecord.wawancara_wa_notif == 2,
                                                'border-success': currentRecord.wawancara_wa_notif == '3' || currentRecord.wawancara_wa_notif == 3,
                                                'border-warning': currentRecord.wawancara_wa_notif == '4' || currentRecord.wawancara_wa_notif == 4
                                            }">
                                    </div>
                                    <hr>
                                    <div class="d-flex justify-content-center align-items-center gap-2 h-100 mt-2">
                                        <button type="button" class="btn btn-danger"
                                            @click="nama_dokumen = 'foto_pendukung_lainnya_2'; nama_dokumen_status = 'wawancara_wa_notif'; value_dokumen = '2'; showModal2('modal_tolak_dokumen')">Tolak</button>
                                        <button type="button" class="btn bg-green-custom text-light"
                                            @click="nama_dokumen = 'foto_pendukung_lainnya_2'; nama_dokumen_status = 'wawancara_wa_notif'; value_dokumen = '3'; verifikasi_dokumen()">Verifikasi</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-3">
                            <div class="card">
                                    <div class="card-body pt-0 px-0">
                                        <div class="text-start bg-green-custom text-light py-2 px-2 mb-3 rounded-top">
                                            Foto Almameter
                                        </div>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <img :src="currentRecord.foto_jas" alt=""
                                                style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                                @click="source_foto = currentRecord.foto_jas; posisi_foto = 10; nama_foto = 'Foto Almameter'"
                                                v-on:click="showModal2('lihat_foto2')">
                                        </div>
                                        <hr>
                                        <div class="d-flex justify-content-center align-items-center gap-2 h-100 mt-2">
                                            <button type="button" class="btn btn-danger">Tolak</button>
                                            <button type="button" class="btn bg-green-custom text-light">Verifikasi</button>
                                        </div>
                                    </div>
                                </div>

                        </div> -->
                    </div>
                    <div class="row mt-2">

                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_dokumen')">Tutup</button>
                    <!-- <button type="submit" class="btn bg-green-custom text-light">
                        Simpan </button> -->
                </div>
            </div>
        </div>
    </div>

    <form @submit.prevent="verifikasi_dokumen()">
        <div class="modal fade" id="modal_tolak_dokumen" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_tolak_dokumen">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                        <!-- <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_delete"></button> -->
                        <button type="button " class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_tolak_dokumen')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Masukan alasan menolak dokumen ini : <span
                                    class="text-danger">*</span></label>
                            <textarea class="form-control" v-model="alasan_dokumen" required />
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_tolak_dokumen')">Batal</button>
                        <button type="submit" class="btn bg-danger text-light">
                            Tolak</button>
                    </div>
                </div>
            </div>
        </div>
    </form>


    <div class="modal fade bg-custom-gray" id="lihat_foto2" data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="lihat_foto2">
        <div class="text-end position-absolute end-0 me-4 mt-2 " style="z-index: 9000!important;">
            <span class=" d-flex align-items-center" style="cursor: pointer;" v-on:click="hideModal2('lihat_foto2')"><i
                    class='bx bx-x fs-1 text-danger fw-bold'></i>
            </span>
        </div>
        <div class="modal-dialog modal-xl modal-dialog-centered  ">
            <div class="mb-3 w-100 text-center ">
                <img :src="source_foto_2" alt="" style="max-width: 100%; max-height: 80vh;">
            </div>
        </div>
        <div class="px-3 py-3 fixed-bottom d-flex justify-content-between">
            <button class="btn btn-danger " @click="beforePhoto()">
                <i class='bx bx-left-arrow-alt'></i>
            </button>
            <div class="text-light">
                {{ nama_foto_2 }}
            </div>
            <button class="btn btn-danger " @click="nextPhoto()">
                <i class='bx bx-right-arrow-alt'></i>
            </button>

        </div>
    </div>

    <div class="modal fade" id="modal_mata_kuliah_nilai" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_mata_kuliah_nilai">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Nilai {{ currentRecord.nama_lengkap }}</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_mata_kuliah_nilai"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_mata_kuliah_nilai')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="d-flex gap-2 justify-content-start ">

                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="id_tahun_ajaran_absensi" @change="nilaiMataKuliahGet()" required>
                            <option value="" selected disabled>- Pilih Tahun ajaran</option>
                            <option v-for="list in tahun_ajaran_list_absensi" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="id_semester_absensi" @change="nilaiMataKuliahGet()" required
                            :disabled="id_tahun_ajaran_absensi == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semester_list_absensi" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Nama Mata Kuliah</th>
                                    <th>Kehadiran</th>
                                    <!-- <th>Finalisasi UTS</th>
                                    <th>Finalisasi UAS</th>
                                    <th>Finalisasi Tugas</th> -->
                                    <th>Tugas</th>
                                    <th>Nilai UTS</th>
                                    <th>Nilai UAS</th>
                                    <th>Nilai Quiz</th>
                                    <th>Nilai Hasil proyek</th>
                                    <th>Nilai Aktifitas Partisipatif</th>
                                    <th>Nilai Indeks</th>
                                    <th>Nilai Huruf</th>
                                    <!-- <th>Nilai UTS (Susulan)</th>
                                    <th>Nilai UAS (Susulan)</th> -->
                                    <!-- <th>Aksi</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( record, index ) in mataKuliahNilaiRecords " :key="record.id"
                                    :ref="record.id" class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ record.nama }}</td>
                                    <td>{{ record.kehadiran }} %</td>
                                    <!-- <td :class="{ 'text-success': record.status_nilai_uts == 'FINAL' }">{{
                                                    record.status_nilai_uts }}</td>
                                    <td>{{ record.status_nilai_uas }}</td>
                                    <td>{{ record.status_nilai_tugas }}</td> -->
                                    <td>
                                        <span>{{
                                            record.tugas }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.uts }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.uas }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.quiz }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.hasil_proyek }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.aktifitas_partisipatif }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.nilai_indeks }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.nilai_huruf }}</span>
                                    </td>
                                    <!-- <td><span v-if="record.susulan_uts == '-1'">-</span> <span
                                            v-if="record.susulan_uts != '-1'">{{
                                                record.susulan_uts }}</span></td>
                                    <td><span v-if="record.susulan_uas == '-1'">-</span> <span
                                            v-if="record.susulan_uas != '-1'">{{
                                                record.susulan_uas }}</span></td> -->
                                    <!-- <td class="bg-transparent">
                                        <div class="d-flex gap-2" v-if="record.status_input_nilai == 'nilai diinput'">
                                            <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                                @click="editRecordNilai(record);"><i
                                                    class='bx bxs-message-square-edit fs-8'></i></button>
                                        </div>
                                    </td> -->
                                </tr>
                                <tr v-if="mataKuliahNilaiRecords.length > 0">
                                    <td>
                                    </td>
                                    <td>
                                        <span class="fw-bold">IP</span>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                        <span>{{ ipk_kumulatif }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_mata_kuliah_nilai')">Tutup</button>
                    <!-- <button type="submit" class="btn bg-green-custom text-light">
                        Simpan </button> -->
                </div>
            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_prestasi" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_prestasi">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Prestasi {{ currentRecord.nama_lengkap }}
                    </h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_prestasi"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_prestasi')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="">


                        <div class="d-flex justify-content-between ">
                            <div class="d-flex justify-content-start gap-2">
                                <div>
                                    <select class="form-select" aria-label="Default select example" name="semester"
                                        id="semester" v-model="limitPrestasi"
                                        @change="pageActivePrestasi = 1; offsetPrestasi = 0; prestasiGet();" required>
                                        <option v-for="     list in limitList     " :key="list.value"
                                            :value="list.value" @change=prestasiGet();>
                                            {{ list.value }}
                                        </option>
                                    </select>
                                </div>
                                <div>
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination" ref="pagi">
                                            <li class="page-item">
                                                <span class="page-link"
                                                    @click="if (pageActivePrestasi != 1) { pageActivePrestasi--; offsetPrestasi = offsetPrestasi - limitPrestasi; prestasiGet('previous') };"
                                                    aria-label="Previous" style="cursor:pointer;">
                                                    <span aria-hidden="true">&laquo;</span>
                                                </span>
                                            </li>
                                            <li v-for="     list in paginglistPrestasi     " :key="list.nama"
                                                :value="list.value" class="page-item"
                                                :class="{ 'active': list.nama == pageActivePrestasi, 'd-none': list.show == false }">
                                                <span class="page-link text-black "
                                                    @click="if (list.disabled != true) pageActivePrestasi = list.nama; offsetPrestasi = list.value; prestasiGet();"
                                                    style="cursor: pointer;">{{
                                                        list.nama }}</span>
                                            </li>
                                            <li class="page-item">
                                                <span class="page-link"
                                                    @click="if (pageActivePrestasi != totalPagesPrestasi) { pageActivePrestasi++; offsetPrestasi = offsetPrestasi + limitPrestasi; prestasiGet('next') };"
                                                    aria-label="Next" style="cursor:pointer;">
                                                    <span aria-hidden="true">&raquo;</span>
                                                </span>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div>
                                    <input type="text" v-model="queryPrestasi"
                                        @change="pageActivePrestasi = 1; offsetPrestasi = 0; prestasiGet();"
                                        class="form-control" placeholder="Cari NIM, Nama Lengkap">
                                </div>
                            </div>

                            <div class="">
                                <button v-if="create_akses == true"
                                    class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                                    style="border-radius: 5px;" @click="showModal2('modal_insert_prestasi');">
                                    <i class='bx bx-list-plus me-1'></i>
                                    Tambah prestasi
                                </button>
                            </div>
                        </div>


                    </div>
                    <div class="d-flex gap-2 justify-content-start ">
                        <!-- <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="id_tahun_ajaran_absensi" @change="nilaiMataKuliahGet()" required>
                            <option value="" selected disabled>- Pilih Tahun ajaran</option>
                            <option v-for="list in tahun_ajaran_list_absensi" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="id_semester_absensi" @change="nilaiMataKuliahGet()" required
                            :disabled="id_tahun_ajaran_absensi == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semester_list_absensi" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select> -->
                    </div>
                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Nama</th>
                                    <th>Jenis Prestasi</th>
                                    <th>Tingkat</th>
                                    <th>Nama Prestasi</th>
                                    <th>tanggal</th>
                                    <th>Penyelenggara</th>
                                    <th>Peringkat</th>
                                    <th>hasil lomba</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( record, index ) in recordsPrestasi" :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ record.nama }}</td>
                                    <td>{{ record.jenis_prestasi }}</td>
                                    <td>{{ record.tingkat }}</td>
                                    <td>{{ record.nama }}</td>
                                    <td>{{ record.tanggal }}</td>
                                    <td>{{ record.penyelenggara }}</td>
                                    <td><span v-if="record.peringkat > 0">{{ record.peringkat }}</span>
                                        <span v-else>-</span>
                                    </td>
                                    <td>{{ record.hasil }}</td>
                                    <td class="bg-transparent">
                                        <div class="d-flex gap-2">
                                            <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                                v-on:click="editRecordPrestasi(record); showModal2('modal_prestasi_update')"><i
                                                    class='bx bxs-message-square-edit fs-8'></i></button>
                                            <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                                v-on:click="deleteRecordPrestasi(record.id); showModal2('modal_delete_prestasi')"><i
                                                    class='bx bxs-trash-alt fs-8'></i></button>

                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class=" modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_prestasi')">Tutup</button>
                    <!-- <button type="submit" class="btn bg-green-custom text-light">
                        Simpan </button> -->
                </div>
            </div>

        </div>
    </div>

    <form @submit.prevent="prestasiInsert">
        <div class="modal fade" id="modal_insert_prestasi" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_insert_prestasi">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah Prestasi Mahasiswa
                        </h5>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_insert_prestasi')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nama" class="form-label">Nama Kegiatan</label>
                            <input type="text" class="form-control" v-model="newRecordPrestasi.nama" required />
                        </div>
                        <div class="mb-3">
                            <label for="jenis_prestasi" class="form-label">Jenis Prestasi</label>
                            <select class="form-select" aria-label="Default select example" name="jenis_prestasi"
                                id="tahuna_ajaran" v-model="newRecordPrestasi.jenis_prestasi">
                                <option value="" selected disabled>- Pilih Jenis Prestasi</option>
                                <option value="Sains">
                                    Sains</option>
                                <option value="Seni">
                                    Seni</option>
                                <option value="Olahraga">
                                    Olahraga</option>
                                <option value="Lain-lain">
                                    Lain-lain</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Bidang </label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahuna_ajaran" v-model="newRecordPrestasi.bidang">
                                <option value="" selected disabled>- Pilih Tahun ajaran</option>
                                <option value="Akademik">
                                    Akademik</option>
                                <option value="Non Akademik">
                                    Non Akademik</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tingkat_lomba" class="form-label">Cabang </label>
                            <input type="text" class="form-control" v-model="newRecordPrestasi.cabang" required />
                        </div>
                        <div class="mb-3">
                            <label for="tingkat_lomba" class="form-label">Tingkat Lomba</label>
                            <select class="form-select" aria-label="Default select example" name="tingkat_lomba"
                                id="tingkat_lomba" v-model="newRecordPrestasi.tingkat">
                                <option value="" selected disabled>- Pilih Tingkat</option>
                                <option value="Internal Kampus">
                                    Internal Kampus</option>
                                <option value="Regional">
                                    Regional</option>
                                <option value="Nasional">
                                    Nasional</option>
                                <option value="Internasional">
                                    Internasional</option>
                                <option value="Lainnya">
                                    Lainnya</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="penyelenggara" class="form-label">Penyelenggara </label>
                            <input type="text" class="form-control" v-model="newRecordPrestasi.penyelenggara"
                                required />
                        </div>
                        <div>
                            <label for="peringkat" class="form-label">Peringkat</label>
                            <!-- <input type="text" class="form-control" v-model="newRecordPrestasi.peringkat" required /> -->
                            <select class="form-select" aria-label="Default select example" name="peringkat"
                                id="peringkat" v-model="newRecordPrestasi.peringkat">
                                <option value="" selected disabled>- Pilih Peringkat</option>
                                <option value="1">
                                    1</option>
                                <option value="2">
                                    2</option>
                                <option value="3">
                                    3</option>
                                <option value="-">
                                    -</option>
                            </select>
                        </div>
                        <div>
                            <label for="hasil" class="form-label">Hasil </label>
                            <!-- <input type="text" class="form-control" v-model="newRecordPrestasi.hasil" required /> -->
                            <div class="row">
                                <div
                                    :class="{ 'col-6': newRecordPrestasi.hasil_pilih == 'lainnya', 'col-12': newRecordPrestasi.hasil_pilih != 'lainnya' }">
                                    <select class="form-select" aria-label="Default select example" name="peringkat"
                                        id="peringkat" v-model="newRecordPrestasi.hasil_pilih">
                                        <option value="" selected disabled>- Pilih Hasil Lomba</option>

                                        <option value="Juara I">
                                            Juara I</option>
                                        <option value="Juara II">
                                            Juara II</option>
                                        <option value="Juara III">
                                            Juara III</option>
                                        <option value="Harapan I">
                                            Harapan I</option>
                                        <option value="Harapan II">
                                            Harapan II</option>
                                        <option value="Harapan III">
                                            Harapan III</option>
                                        <option value="Penghargaan Tim Terbaik">
                                            Penghargaan Tim Terbaik</option>
                                        <option value="lainnya">
                                            Lainnya</option>
                                    </select>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-control" v-model="newRecordPrestasi.hasil" required
                                        v-if="newRecordPrestasi.hasil_pilih == 'lainnya'" />
                                </div>
                            </div>



                        </div>
                        <div class="mb-3">
                            <label for="tanggal" class="form-label">Tanggal Kegiatan </label>
                            <input type="date" class="form-control" v-model="newRecordPrestasi.tanggal" required />
                        </div>

                        <div class="mb-3">
                            <label for="hasil" class="form-label">Link Info</label>
                            <input type="text" class="form-control" v-model="newRecordPrestasi.link_info" required />
                        </div>

                        <!-- <div class=""
                            v-if="newRecordPrestasi.foto_sertifikat != null || newRecordPrestasi.foto_sertifikat != ''">
                            <img :src="newRecordPrestasi.foto_sertifikat" alt=""
                                style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                class=" mb-2 border border-3" @click="openInNewTab(newRecordPrestasi.foto_sertifikat)">
                        </div> -->
                        <div class="mb-3">
                            <label for="foto_sertifikat" class="form-label">Foto Sertifikat</label>
                            <input type="file" accept="image/png, image/jpeg" class="form-control"
                                ref="foto_sertifikat" />
                        </div>

                        <!-- <div class=""
                            v-if="newRecordPrestasi.foto_penyerahan_sertifikat != null || newRecordPrestasi.foto_penyerahan_sertifikat != ''">
                            <img :src="newRecordPrestasi.foto_penyerahan_sertifikat" alt=""
                                style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                class=" mb-2 border border-3"
                                @click="openInNewTab(newRecordPrestasi.foto_penyerahan_sertifikat)">
                        </div> -->

                        <div class="mb-3">
                            <label for="foto_penyerahan_sertifikat" class="form-label">Foto Penyerahan
                                Sertifikat</label>
                            <input type="file" accept="image/png, image/jpeg" class="form-control"
                                ref="foto_penyerahan_sertifikat" />
                        </div>


                        <!-- <div class=""
                            v-if="newRecordPrestasi.surat_undangan != null || newRecordPrestasi.surat_undangan != ''">
                            <img :src="newRecordPrestasi.surat_undangan" alt=""
                                style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                class=" mb-2 border border-3" @click="openInNewTab(newRecordPrestasi.surat_undangan)">
                        </div> -->

                        <div class="mb-3">
                            <label for="surat_undangan" class="form-label">Surat Undangan </label>
                            <input type="file" accept="application/pdf" class="form-control" ref="surat_undangan" />
                        </div>
                        <!-- <div class="" v-if="newRecordPrestasi.surat_tugas != null || newRecordPrestasi.surat_tugas != ''">
                            <img :src="newRecordPrestasi.surat_tugas" alt=""
                                style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                class=" mb-2 border border-3" @click="openInNewTab(newRecordPrestasi.surat_tugas)">
                        </div> -->
                        <div class="mb-3">
                            <label for="surat_tugas" class="form-label">Surat Tugas</label>
                            <input type="file" accept="application/pdf" class="form-control" ref="surat_tugas" />
                        </div>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_insert_prestasi')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <form @submit.prevent="prestasiUpdate">
        <div class="modal fade" id="modal_prestasi_update" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_prestasi_update">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah Prestasi Mahasiswa
                        </h5>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_prestasi_update')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nama" class="form-label">Nama Kegiatan</label>
                            <input type="text" class="form-control" v-model="currentRecordPrestasi.nama" required />
                        </div>
                        <div class="mb-3">
                            <label for="jenis_prestasi" class="form-label">Jenis Prestasi</label>
                            <select class="form-select" aria-label="Default select example" name="jenis_prestasi"
                                id="tahuna_ajaran" v-model="currentRecordPrestasi.jenis_prestasi">
                                <option value="" selected disabled>- Pilih Jenis Prestasi</option>
                                <option value="Sains">
                                    Sains</option>
                                <option value="Seni">
                                    Seni</option>
                                <option value="Olahraga">
                                    Olahraga</option>
                                <option value="Lain-lain">
                                    Lain-lain</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Bidang </label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahuna_ajaran" v-model="currentRecordPrestasi.bidang">
                                <option value="" selected disabled>- Pilih Tahun ajaran</option>
                                <option value="Akademik">
                                    Akademik</option>
                                <option value="Non Akademik">
                                    Non Akademik</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tingkat_lomba" class="form-label">Cabang </label>
                            <input type="text" class="form-control" v-model="currentRecordPrestasi.cabang" required />
                        </div>
                        <div class="mb-3">
                            <label for="tingkat_lomba" class="form-label">Tingkat Lomba</label>
                            <select class="form-select" aria-label="Default select example" name="tingkat_lomba"
                                id="tingkat_lomba" v-model="currentRecordPrestasi.tingkat">
                                <option value="" selected disabled>- Pilih Tingkat</option>
                                <option value="Internal Kampus">
                                    Internal Kampus</option>
                                <option value="Regional">
                                    Regional</option>
                                <option value="Nasional">
                                    Nasional</option>
                                <option value="Internasional">
                                    Internasional</option>
                                <option value="Lainnya">
                                    Lainnya</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="penyelenggara" class="form-label">Penyelenggara </label>
                            <input type="text" class="form-control" v-model="currentRecordPrestasi.penyelenggara"
                                required />
                        </div>
                        <div>
                            <label for="peringkat" class="form-label">Peringkat</label>
                            <!-- <input type="text" class="form-control" v-model="currentRecordPrestasi.peringkat" required /> -->
                            <select class="form-select" aria-label="Default select example" name="peringkat"
                                id="peringkat" v-model="currentRecordPrestasi.peringkat">
                                <option value="" selected disabled>- Pilih Peringkat</option>
                                <option value="1">
                                    1</option>
                                <option value="2">
                                    2</option>
                                <option value="3">
                                    3</option>
                                <option value="-">
                                    -</option>
                            </select>
                        </div>
                        <div>
                            <label for="hasil" class="form-label">Hasil </label>
                            <div class="row">
                                <div
                                    :class="{ 'col-6': currentRecordPrestasi.hasil_pilih == 'lainnya', 'col-12': currentRecordPrestasi.hasil_pilih != 'lainnya' }">
                                    <select class="form-select" aria-label="Default select example" name="peringkat"
                                        id="peringkat" v-model="currentRecordPrestasi.hasil_pilih">
                                        <option value="" selected disabled>- Pilih Hasil Lomba</option>

                                        <option value="Juara I">
                                            Juara I</option>
                                        <option value="Juara II">
                                            Juara II</option>
                                        <option value="Juara III">
                                            Juara III</option>
                                        <option value="Harapan I">
                                            Harapan I</option>
                                        <option value="Harapan II">
                                            Harapan II</option>
                                        <option value="Harapan III">
                                            Harapan III</option>
                                        <option value="Penghargaan Tim Terbaik">
                                            Penghargaan Tim Terbaik</option>
                                        <option value="lainnya">
                                            Lainnya</option>
                                    </select>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-control" v-model="currentRecordPrestasi.hasil"
                                        required v-if="currentRecordPrestasi.hasil_pilih == 'lainnya'" />
                                </div>
                            </div>
                            <!-- <input type="text" class="form-control" v-model="currentRecordPrestasi.hasil" required /> -->
                        </div>
                        <div class="mb-3">
                            <label for="tanggal" class="form-label">Tanggal Kegiatan </label>
                            <input type="date" class="form-control" v-model="currentRecordPrestasi.tanggal" required />
                        </div>

                        <div class="mb-3">
                            <label for="hasil" class="form-label">Link Info</label>
                            <input type="text" class="form-control" v-model="currentRecordPrestasi.link_info"
                                required />
                        </div>

                        <div class=""
                            v-if="currentRecordPrestasi.foto_sertifikat_update != null || currentRecordPrestasi.foto_sertifikat_update != ''">
                            <img :src="currentRecordPrestasi.foto_sertifikat_update" alt=""
                                style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                class=" mb-2 border border-3"
                                @click="openInNewTab(currentRecordPrestasi.foto_sertifikat_update)">
                        </div>
                        <div class="mb-3">
                            <label for="foto_sertifikat_update" class="form-label">Foto Sertifikat</label>
                            <input type="file" accept="image/png, image/jpeg" class="form-control"
                                ref="foto_sertifikat_update" />
                        </div>

                        <div class=""
                            v-if="currentRecordPrestasi.foto_penyerahan_sertifikat_update != null || currentRecordPrestasi.foto_penyerahan_sertifikat_update != ''">
                            <img :src="currentRecordPrestasi.foto_penyerahan_sertifikat_update" alt=""
                                style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                class=" mb-2 border border-3"
                                @click="openInNewTab(currentRecordPrestasi.foto_penyerahan_sertifikat_update)">
                        </div>

                        <div class="mb-3">
                            <label for="foto_penyerahan_sertifikat_update" class="form-label">Foto Penyerahan
                                Sertifikat</label>
                            <input type="file" accept="image/png, image/jpeg" class="form-control"
                                ref="foto_penyerahan_sertifikat_update" />
                        </div>


                        <div class=""
                            v-if="currentRecordPrestasi.surat_undangan_update != null || currentRecordPrestasi.surat_undangan_update != ''">
                            <i class='bx bxs-file-pdf fs-1'
                                @click="openInNewTab(currentRecordPrestasi.surat_undangan_update)"
                                style="cursor:pointer"></i>
                        </div>

                        <div class="mb-3">
                            <label for="surat_undangan_update" class="form-label">Surat Undangan </label>
                            <input type="file" accept="application/pdf" class="form-control"
                                ref="surat_undangan_update" />
                        </div>
                        <div class=""
                            v-if="currentRecordPrestasi.surat_tugas_update != null || currentRecordPrestasi.surat_tugas_update != ''">
                            <i class='bx bxs-file-pdf fs-1'
                                @click="openInNewTab(currentRecordPrestasi.surat_tugas_update)"
                                style="cursor:pointer"></i>
                        </div>
                        <div class="mb-3">
                            <label for="surat_tugas_update" class="form-label">Surat Tugas</label>
                            <input type="file" accept="application/pdf" class="form-control" ref="surat_tugas_update" />
                        </div>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_prestasi_update')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="modal fade" id="modal_delete_prestasi" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_delete_prestasi">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <!-- <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete_prestasi"></button> -->
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_delete_prestasi')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data
                        tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_delete_prestasi')">Tidak,
                        tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="prestasiDelete">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal_laporan_prestasi_mahasiswa" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_prestasi_mahasiswa">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Prestasi Mahasiswa </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_prestasi_mahasiswa')">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Stambuk</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for="list in tahunAjaranList" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_prestasi_mahasiswa')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcelPrestasiMahasiswa">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_transkrip_akademik" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_transkrip_akademik">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Trankrip Akademik</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_transkrip_akademik"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_transkrip_akademik')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;"
                        v-if="transkripAkademikRecords.length != 0">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <tbody>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Nama</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[8][0].nama_lengkap }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">NIM</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[8][0].nim_mahasiswa }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Tempat & Tanggal Lahir</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[8][0].tempat_lahir }} & {{
                                            transkripAkademikRecords[8][0].tanggal_lahir }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Program Pendidikan</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[8][0].jenjang_studi }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Fakultas / Program Studi</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[8][0].fakultas }} / {{
                                            transkripAkademikRecords[8][0].prodi }}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Kode Mata Kuliah</th>
                                    <th>Mata Kuliah</th>
                                    <th>H</th>

                                    <th>A</th>
                                    <th>K</th>
                                    <th>M</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( record, index ) in transkripAkademikRecords[0] " :key="record.id"
                                    :ref="record.id" class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ record.kode }}</td>
                                    <td>{{ record.mata_kuliah }}</td>
                                    <!-- <td :class="{ 'text-success': record.status_nilai_uts == 'FINAL' }">{{
                                                    record.status_nilai_uts }}</td>
                                    <td>{{ record.status_nilai_uas }}</td>
                                    <td>{{ record.status_nilai_tugas }}</td> -->
                                    <td>
                                        <span>{{
                                            record.nilai_huruf }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.nilai_mutu }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.sks }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.nilai_mutu_2 }}</span>
                                    </td>
                                </tr>
                                <tr v-if="transkripAkademikRecords.length > 0">
                                    <td>
                                    </td>
                                    <td colspan="2">
                                        <span>H = Huruf, A = Angka, K = Kredit, M = Mutu</span>
                                    </td>

                                    <td colspan="2">Total
                                    </td>
                                    <td>{{ transkripAkademikRecords[2][0].jumlah_sks }}
                                    </td>
                                    <td>{{ transkripAkademikRecords[2][0].jumlah_nilai_mutu_2 }}
                                    </td>


                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;"
                        v-if="transkripAkademikRecords.length != 0">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <tbody>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Jumlah SKS Lulus</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[2][0].jumlah_sks_lulus }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Jumlah SKS Nilai D</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[2][0].jumlah_nilai_d }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Jumlah SKS Nilai E</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[2][0].jumlah_nilai_e }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Jumlah SKS praktikum yang gagal</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[2][0].jumlah_sks_gagal }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Jumlah SKS yang belum diambil </td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[2][0].jumlah_sks_belum_diambil }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">IPK Jika Semua Lulus (Min. Nilai C)</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[2][0].jumlah_ipk_lulus.toFixed(2) }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">IPK Sementara</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[7][0].nilai_kumulatif.toFixed(2) }}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_transkrip_akademik')">Tutup</button>
                    <button type="button" class="btn bg-green-custom text-light" @click="generateTranskripNilai()">
                        Print </button>
                </div>
            </div>

        </div>
    </div>
</template>


<style scoped>
.pagination {
    --bs-pagination-color: black !important;
}

.active>.page-link,
.page-link.active {
    background-color: #0D9044 !important;
    border-color: #0D9044 !important;
    color: white !important;
}


.aksi-item-danger.dropdown-item:hover {
    color: white !important;
    background-color: #dc3545 !important;
}

.aksi-item-primary.dropdown-item:hover {
    color: white !important;
    background-color: #007bff !important;
}

.aksi-item-warning.dropdown-item:hover {
    color: white !important;
    background-color: #f0ad4e !important;
}

.aksi-item-success.dropdown-item:hover {
    color: white !important;
    background-color: #5cb85c !important;
}

.aksi-item-black.dropdown-item:hover {
    color: white !important;
    background-color: black !important;
}

.aksi-item-purple.dropdown-item:hover {
    color: white !important;
    background-color: purple !important;
}

.aksi-item-red.dropdown-item:hover {
    color: white !important;
    background-color: #dc3545 !important;
}

.aksi-item-orange.dropdown-item:hover {
    color: white !important;
    background-color: rgb(195, 195, 0) !important;
}

.aksi-item-gray.dropdown-item:hover {
    color: white !important;
    background-color: #292b2c !important;
}

.aksi-item-white.dropdown-item:hover {
    color: #dc3545 !important;
    background-color: white;
}

.aksi-item-light.dropdown-item:hover {
    color: white !important;
    background-color: #6c757d !important;
}

.aksi-item-info.dropdown-item:hover {
    color: white !important;
    background-color: #5bc0de !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #003970 !important;
    border-radius: 50px;

}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    /* border-color: #003970 !important; */
    border: none !important;
}


.custom-select .vs__dropdown-toggle,
.custom-select .vs__open-indicator {
    border: none;
}

.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, base_url, base_url_2, CloseLoading, ShowLoading, menu_akses_cek, ErrorConnectionTimeOut, AlertBottom, base_url_wilayah_indonesia, CharOnly, NumberOnly, wargaNegaraList, statusAkademikList, openInNewTab, rupiah, rupiahInput, rupiahoutput, capitalizeWords, cekListString } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";
import XLSX from "xlsx/dist/xlsx.full.min.js";


import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
    data() {
        return {
            //data
            url: 'mahasiswa',
            api: "",

            //krs
            prodiKrs: "",
            semesterKrs: "",
            tahunAjarankrs: "",
            krsId: "",
            krsRecords: [],
            semesterRecords: [],
            dosen_wali: "",
            jumlah_sks_diambil: "",
            tahun_akademik: "",
            counter_semester: "",
            tipe_semester: "",
            kelas: "",


            prodiList: [],
            wargaNegaraList: [],
            statusAkademikList: [],
            semesterList: [],
            provinsiList: [],
            kotaList: [],
            kecamatanList: [],
            kelurahanList: [],
            tahunAjaranList: [],
            jalurList: [],



            kotaList_ortu: [],
            provinsiList_ortu: [],
            kecamatanList_ortu: [],
            kelurahanList_ortu: [],

            kotaList_sekolah: [],
            provinsiList_sekolah: [],

            provinsi: "",
            kota: "",
            kecamatan: "",
            kelurahan: "",

            provinsi_update: "",
            kota_update: "",
            kecamatan_update: "",
            kelurahan_update: "",

            provinsi_ortu: "",
            kota_ortu: "",
            kecamatan_ortu: "",
            kelurahan_ortu: "",

            provinsi_ortu_update: "",
            kota_ortu_update: "",
            kecamatan_ortu_update: "",
            kelurahan_ortu_update: "",

            provinsi_sekolah: "",
            kota_sekolah: "",

            provinsi_sekolah_update: "",
            kota_sekolah_update: "",

            //pages
            limit: "10",
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,

            //format
            tanggal_minimum: "",
            tanggal_hari_ini: "",
            tanggal_maksimum: "",

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            newRecord: {
                foto_pas: "",
                nama_lengkap: "",
                nisn_sekolah: "",
                warga_negara: "WNI",
                no_ktp: "",
                no_kk: "",
                tempat_lahir: "",
                tanggal_lahir: "",
                jenis_kelamin: "Pria",
                alamat_siswa: "",

                kota: "",
                provinsi: "",
                kecamatan: "",
                kelurahan: "",

                kodepos: "",
                telepon_siswa: "",
                whatsapp: "",
                jumlah_bersaudara: "",
                anak_ke: "",
                agama: "",
                hobi: "",
                nama_ayah: "",
                no_hp_ayah: "",
                pekerjaan_ayah: "",
                pendidikan_terakhir_ayah: "",
                penghasilan_ayah: "",
                nama_ibu_kandung: "",
                no_hp_ibu: "",
                pekerjaan_ibu: "",
                pendidikan_terakhir_ibu: "",
                penghasilan_ibu: "",
                alamat_ortu: "",
                kota_ortu: "",
                provinsi_ortu: "",
                kecamatan_ortu: "",
                kelurahan_ortu: "",
                kodepos_ortu: "",

                nama_sekolah: "",
                kota_sekolah: "",
                provinsi_sekolah: "",
                jurusan_sekolah: "",

                prodi_pt: "",
                waktu_kuliah: "",
                ukuran_jas_akademis: "",

                foto_kartu_keluarga: "",
                foto_cover_rapor: "",
                foto_data_diri_rapor: "",
                foto_rapor_kelas_xii_ganjil_genap: "",
                foto_skl_skhun: "",
                foto_ijazah: "",
                email: "",
                prodi: "",

                status_akademik: "",
            },
            currentRecord: {},


            //absensi 
            id_mahasiswa_absensi: "",
            id_profiles_absensi: "",
            id_tahun_ajaran_absensi: "",
            id_semester_absensi: "",

            tahun_ajaran_list_absensi: [],
            semester_list_absensi: [],
            record_absensi: [],

            //dokumen
            nama_dokumen: "",
            alasan_dokumen: "",
            nama_dokumen_status: "",
            value_dokumen: "",


            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //filter
            prodiFilter: "",
            tahunAjaranFilter: "",
            jalurFilter: "",

            //query 
            query: "",

            pageActive: "",
            source_foto: "",
            posisi_foto: "",
            nama_foto: "",

            posisi_foto_2: "",
            nama_foto_2: "",
            source_foto_2: "",

            pendidikanTerakhirList: [
                {
                    nama: "Tidak/ Belum Sekolah"
                },
                {
                    nama: "Tidak Tamat SD/Sederajat"
                },
                {
                    nama: "Tamat SD/Sederajat"
                },
                {
                    nama: "SLTP/Sederajat"
                },
                {
                    nama: "SLTA/Sederajat"
                },
                {
                    nama: "Diploma I/II"
                },
                {
                    nama: "Akademi/Diploma III/Sarjana Muda"
                },
                {
                    nama: "Diploma IV/Strata I"
                },
                {
                    nama: "Strata II"
                },
                {
                    nama: "Strata III"
                }
            ],

            agamaList: [
                {
                    nama: "Islam",
                },
                {
                    nama: "Katolik",
                },
                {
                    nama: "Protestan",
                },
                {
                    nama: "Hindu",
                },
                {
                    nama: "Buddha",
                },
                {
                    nama: "Konghucu",
                },

            ],

            isLainnyaPekerjaanIbu: false,
            isiLainnyaPekerjaanIbu: "",
            isLainnyaPekerjaanAyah: false,
            isiLainnyaPekerjaanAyah: "",



            pekerjaanList: [
                {
                    nama: "PNS",
                },
                {
                    nama: "KARYAWAN SWASTA",
                },
                {
                    nama: "WIRAUSAHA",
                },
                {
                    nama: "TNI/POLRI",
                },
                {
                    nama: "PETANI",
                },
                {
                    nama: "NELAYAN",
                },
                {
                    nama: "LAINNYA",
                },
            ],

            ukuranJasList: [
                {
                    nama: "S",
                },
                {
                    nama: "M",
                },
                {
                    nama: "L",
                },
                {
                    nama: "XL",
                },
                // {
                //   nama: "XXL",
                // },
                // {
                //   nama: "3XL",
                // },
            ],
            waktuKuliahList: [
                {
                    nama: "Siang (14.00 s.d. 17.00 WIB)"
                },
                {
                    nama: "Sore (17.30 s.d. 20.30 WIB)"
                }
            ],
            limitList: [{
                value: 5
            }, {
                value: 10
            }, {
                value: 25
            },
            {
                value: 50
            },
            {
                value: 100
            }

            ],

            mataKuliahNilaiRecords: [],

            //transkrip akademik
            transkripAkademikRecords: [],

            //pindah prestasi
            numberPagePrestasi: 1,
            totalPagesPrestasi: 0,
            currentPageActivePrestasi: 1,
            pageActivePrestasi: 1,
            recordsPrestasi: [],
            newRecordPrestasi: [],
            currentRecordPrestasi: { nim_mahasiswa: "", nama_lengkap: "", status_terima: "", tanggal: "", alasan: "" },
            limitPrestasi: 10,
            offsetPrestasi: 0,
            batas_page_awalPrestasi: 0,
            batas_page_akhirPrestasi: 10,
            paginglistPrestasi: [],

            //
            waktuKuliahListPrestasi: [],
            dosenWaliListPrestasi: [],
            semesterListPrestasi: [],
            tahunAjaranListPrestasi: [],
            hariJadwalListPrestasi: [],
            mataKuliahListPrestasi: [],
            kurikulumListPrestasi: [],

            //
            prodiFilterPrestasi: "",
            semesterFilterPrestasi: "",
            tahunAjaranFilterPrestasi: "",
            kurikulumFilterPrestasi: "",
            tipeFilterPrestasi: "",
            MataKuliahFilterPrestasi: "",
            queryPrestasi: "",
            status_terima_Prestasi: "",
            tipeListPrestasi: [],

            //nilai
            ipk_kumulatif: "-",
        }
    },
    components: {
        SideNavbar, 'v-select': vSelect
    },
    watch: {
        'provinsi': function () {
            if (this.provinsi == null) {
                this.newRecord.provinsi = "";
            } else {
                this.newRecord.provinsi = this.provinsi.nama;
                this.kabupaten_index(this.provinsi.value, 'siswa');
                this.kota = null;
                this.kecamatan = null;
                this.kelurahan = null;
            }
        },
        'kota': function () {
            if (this.kota == null) {
                this.newRecord.kota = "";
            } else {
                this.newRecord.kota = this.kota.nama;
                this.kecamatan_index(this.kota.value, 'siswa');
                this.kecamatan = null;
                this.kelurahan = null;
            }
        },
        'kecamatan': function () {

            if (this.kecamatan == null) {
                this.newRecord.kecamatan = "";
            } else {

                this.newRecord.kecamatan = this.kecamatan.nama;
                this.kelurahan_index(this.kecamatan.value, 'siswa');
                this.kelurahan = null;
            }
        },
        'kelurahan': function () {

            if (this.kelurahan == null) {
                this.newRecord.kelurahan = "";
            } else {

                this.newRecord.kelurahan = this.kelurahan.nama;
            }

        },
        'provinsi_update': function () {
            if (this.provinsi_update == null) {
                this.currentRecord.provinsi = "";
            } else {
                this.currentRecord.provinsi = this.provinsi_update.nama;
                this.kabupaten_index(this.provinsi_update.value, 'siswa');
                this.kota_update = null;
                this.kecamatan_update = null;
                this.kelurahan_update = null;
            }

        },
        'kota_update': function () {
            if (this.kota_update == null) {
                this.currentRecord.kota = "";
            } else {
                this.currentRecord.kota = this.kota_update.nama;
                this.kecamatan_index(this.kota_update.value, 'siswa');
                this.kecamatan_update = null;
                this.kelurahan_update = null;
            }
        },
        'kecamatan_update': function () {

            if (this.kecamatan_update == null) {
                this.currentRecord.kecamatan = "";
            } else {

                this.currentRecord.kecamatan = this.kecamatan_update.nama;
                this.kelurahan_index(this.kecamatan_update.value, 'siswa');
                this.kelurahan_update = null;
            }
        },
        'kelurahan_update': function () {

            if (this.kelurahan_update == null) {
                this.currentRecord.kelurahan = "";
            } else {
                this.currentRecord.kelurahan = this.kelurahan_update.nama;
            }

        },
        'provinsi_ortu': function () {
            if (this.provinsi_ortu == null) {
                this.newRecord.provinsi_ortu = "";
            } else {
                this.newRecord.provinsi_ortu = this.provinsi_ortu.nama;
                this.kabupaten_index(this.provinsi_ortu.value, 'ortu');
                this.kota_ortu = null;
                this.kecamatan_ortu = null;
                this.kelurahan_ortu = null;
            }

        },
        'kota_ortu': function () {
            if (this.kota_ortu == null) {
                this.newRecord.kota_ortu = "";
            } else {
                this.newRecord.kota_ortu = this.kota_ortu.nama;
                this.kecamatan_index(this.kota_ortu.value, 'ortu');
                this.kecamatan_ortu = null;
                this.kelurahan_ortu = null;
            }
        },
        'kecamatan_ortu': function () {

            if (this.kecamatan_ortu == null) {
                this.newRecord.kecamatan_ortu = "";
            } else {

                this.newRecord.kecamatan_ortu = this.kecamatan_ortu.nama;
                this.kelurahan_index(this.kecamatan_ortu.value, 'ortu');
                this.kelurahan_ortu = null;
            }

        },
        'kelurahan_ortu': function () {
            if (this.kelurahan_ortu == null) {
                this.newRecord.kelurahan_ortu = "";
            } else {
                this.newRecord.kelurahan_ortu = this.kelurahan_ortu.nama;
            }

        },
        'provinsi_ortu_update': function () {
            if (this.provinsi_ortu_update == null) {
                this.currentRecord.provinsi_ortu = "";
            } else {
                this.currentRecord.provinsi_ortu = this.provinsi_ortu_update.nama;
                this.kabupaten_index(this.provinsi_ortu_update.value, 'ortu');
                this.kota_ortu_update = null;
                this.kecamatan_ortu_update = null;
                this.kelurahan_ortu_update = null;
            }

        },
        'kota_ortu_update': function () {
            if (this.kota_ortu_update == null) {
                this.currentRecord.kota_ortu = "";
            } else {

                this.currentRecord.kota_ortu = this.kota_ortu.nama;
                this.kecamatan_index(this.kota_ortu_update.value, 'ortu');
                this.kecamatan_ortu_update = null;
                this.kelurahan_ortu_update = null;
            }
        },
        'kecamatan_ortu_update': function () {

            if (this.kecamatan_ortu_update == null) {
                this.currentRecord.kecamatan_ortu_update = "";
            } else {

                this.currentRecord.kecamatan_ortu_update = this.kecamatan_ortu_update.nama;
                this.kelurahan_index(this.kecamatan_ortu_update.value, 'ortu');
                this.kelurahan_ortu_update = null;
            }

        },
        'kelurahan_ortu_update': function () {
            if (this.kelurahan_ortu_update == null) {
                this.currentRecord.kelurahan_ortu_update = "";
            } else {
                this.currentRecord.kelurahan_ortu_update = this.kelurahan_ortu_update.nama;
            }

        },
        'provinsi_sekolah': function () {
            if (this.provinsi_sekolah == null) {
                this.newRecord.provinsi_sekolah = "";
            } else {
                this.newRecord.provinsi_sekolah = this.provinsi_sekolah.nama;
                this.kabupaten_index(this.provinsi_sekolah.value, 'sekolah');
                this.kota_sekolah = null;
            }

        },
        'kota_sekolah': function () {
            if (this.kota_sekolah == null) {
                this.newRecord.kota_sekolah = "";
            } else {
                this.newRecord.kota_sekolah = this.kota_sekolah.nama;
            }
        },
        'provinsi_sekolah_update': function () {
            if (this.provinsi_sekolah_update == null) {
                this.currentRecord.provinsi_sekolah = "";
            } else {
                this.currentRecord.provinsi_sekolah = this.provinsi_sekolah_update.nama;
                this.kabupaten_index(this.provinsi_sekolah_update.value, 'sekolah');
                this.kota_sekolah_update = null;
            }

        },
        'kota_sekolah_update': function () {
            if (this.kota_sekolah_update == null) {
                this.currentRecord.kota_sekolah = "";
            } else {
                this.currentRecord.kota_sekolah = this.kota_sekolah_update.nama;
            }
        },




    },

    methods: {
        //data
        CharOnly,
        NumberOnly,
        openInNewTab,
        rupiahInput,
        rupiah,
        capitalizeWords,
        cekListString,
        addNumberToObjects(objArray) {
            for (let i = 0; i < objArray.length; i++) {
                const newObj = { No: i + 1, ...objArray[i] };
                objArray[i] = newObj;
            }
            return objArray;
        },
        generateExcelPrestasiMahasiswa() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);

            api = base_url + 'generate_laporan_prestasi_mahasiswa';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Prestasi Mahasiswa');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Prestasi Mahasiswa' + ".xlsx");
                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        nilaiMataKuliahGet() {

            this.mataKuliahNilaiRecords = [];

            ShowLoading();
            let formData = new FormData();

            formData.append("tahun_ajaran", this.id_tahun_ajaran_absensi);
            formData.append("semester", this.id_semester_absensi);
            formData.append("mahasiswa", this.id_mahasiswa_absensi);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'nilai_wali_get';
            } else {
                this.api = base_url + 'nilai_wali_get';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.tahun_ajaran_list_absensi = response.data.tahun_ajaran;
                        this.semester_list_absensi = response.data.semester;
                        if (response.data.data != undefined) {
                            this.mataKuliahNilaiRecords = response.data.data[0];
                            this.ipk_kumulatif = response.data.data[3][0].nilai_kumulatif;
                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        this.hideModal2('modal_mata_kuliah_nilai');
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        transkripAkademikGet() {

            this.mataKuliahNilaiRecords = [];

            ShowLoading();
            let formData = new FormData();
            formData.append("mahasiswa", this.id_mahasiswa_absensi);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'transkrip_akademik_get';
            } else {
                this.api = base_url + 'transkrip_akademik_get';
            }



            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            this.transkripAkademikRecords = response.data.data;

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        this.hideModal2('modal_mata_kuliah_nilai');
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        generateTranskripNilai() {
            ShowLoading();
            let formData = new FormData();
            formData.append("p_id_user", this.id_mahasiswa_absensi);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url_2 + 'generate_transkrip_nilai';
            } else {
                this.api = base_url_2 + 'generate_transkrip_nilai';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        CloseLoading();
                        openInNewTab(response.data.data.file_report);
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        this.hideModal2('modal_mata_kuliah_nilai');
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        getAksesMahasiswa() {
            ShowLoading();
            let formData = new FormData();
            formData.append("id", this.id_mahasiswa_absensi);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'get_akses_mahasiswa';
            } else {
                this.api = base_url + 'get_akses_mahasiswa';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        // console.log(response.data.token_refresh);
                        Cookies.set("X-Header-Token", response.data.token_refresh);
                        const urlString = window.location.href;
                        const cleanString = urlString.split("?")[0];
                        const url = new URL(cleanString);
                        url.pathname = "/";

                        const modifiedString = url.toString();
                        CloseLoading();

                        window.open(modifiedString + "mhs/dashboard", '_blank').focus();

                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        this.hideModal2('modal_mata_kuliah_nilai');
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        prestasiGet(status) {

            this.paginglistPrestasi = [];
            ShowLoading();
            this.recordsPrestasi = [];


            let formData = new FormData();
            formData.append("id_mahasiswa", this.id_mahasiswa_absensi);
            formData.append("limit", this.limitPrestasi);
            formData.append("offset", this.offsetPrestasi);
            formData.append("query", this.queryPrestasi);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'prestasi_index';
            } else {
                this.api = base_url + 'prestasi_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            this.recordsPrestasi = response.data.data[1];
                            this.totalPagesPrestasi = response.data.data[0][0].number_page;

                            let limit = this.limitPrestasi;
                            this.pageActivePrestasi;

                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limitPrestasi;
                                }

                                if (i <= this.batas_page_akhirPrestasi && i > this.batas_page_awalPrestasi) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }



                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhirPrestasi] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActivePrestasi - 1].key) {

                                    page_list_array[this.batas_page_awalPrestasi].show = false;
                                    page_list_array[this.batas_page_akhirPrestasi].show = true;

                                    this.batas_page_awalPrestasi++;
                                    this.batas_page_akhirPrestasi++;

                                } else if (this.pageActivePrestasi != 1) {


                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActivePrestasi - 1].key) {
                                        this.batas_page_awalPrestasi--;
                                        this.batas_page_akhirPrestasi--;

                                        page_list_array[this.batas_page_awalPrestasi].show = true;
                                        page_list_array[this.batas_page_akhirPrestasi].show = false;
                                    }
                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActivePrestasi - 1].key) && page_list_array[this.batas_page_akhirPrestasi] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActivePrestasi != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglistPrestasi.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPagesPrestasi,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }

                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglistPrestasi.push(pemisahPages);
                                    this.paginglistPrestasi.push(totalPages);
                                }

                            }


                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        prestasiInsert() {

            ShowLoading();
            const formData = new FormData();

            Object.entries(this.newRecordPrestasi).forEach(([key, value]) => {
                formData.append(key, value);
            });

            if (this.newRecordPrestasi.hasil_pilih == 'lainnya') {
                formData.delete('hasil_pilih');
                formData.append('hasil', this.newRecordPrestasi.hasil)
            } else {
                formData.delete('hasil_pilih');
                formData.append('hasil', this.newRecordPrestasi.hasil_pilih)
            }

            formData.append("foto_sertifikat", this.$refs.foto_sertifikat.files[0]);
            formData.append("foto_penyerahan_sertifikat", this.$refs.foto_penyerahan_sertifikat.files[0]);
            formData.append("surat_tugas", this.$refs.surat_tugas.files[0]);
            formData.append("surat_undangan", this.$refs.surat_undangan.files[0]);

            formData.append("id_mahasiswa", this.id_mahasiswa_absensi);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "prestasi_insert";
            } else {
                this.api = base_url + "prestasi_insert";
            }



            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.recordsPrestasi.unshift(response.data.data);

                        this.newRecordPrestasi = {
                        };
                        this.$refs.foto_sertifikat = "";
                        this.$refs.foto_penyerahan_sertifikat = "";
                        this.$refs.surat_tugas = "";
                        this.$refs.surat_undangan = "";
                        CloseLoading();

                        AlertBottom(response.data.message);
                        this.hideModal2('modal_insert_prestasi')
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        prestasiUpdate() {

            ShowLoading();
            const formData = new FormData();
            Object.entries(this.currentRecordPrestasi).forEach(([key, value]) => {
                formData.append(key, value);
            });

            if (this.currentRecordPrestasi.hasil_pilih == 'lainnya') {
                formData.delete('hasil_pilih');
            } else {
                formData.delete('hasil_pilih');
                formData.delete('hasil');
                formData.append("hasil", this.currentRecordPrestasi.hasil_pilih);
            }

            if (this.$refs.foto_sertifikat_update.files[0] != undefined) {
                // delete this.currentRecordPrestasi.foto_sertifikat;
                formData.delete('foto_sertifikat');
                formData.append("foto_sertifikat_update", this.$refs.foto_sertifikat_update.files[0]);
            }
            if (this.$refs.foto_penyerahan_sertifikat_update.files[0] != undefined) {
                // delete this.currentRecordPrestasi.foto_penyerahan_sertifikat;
                formData.delete('foto_penyerahan_sertifikat');
                formData.append("foto_penyerahan_sertifikat_update", this.$refs.foto_penyerahan_sertifikat_update.files[0]);
            }
            if (this.$refs.surat_tugas_update.files[0] != undefined) {
                // delete this.currentRecordPrestasi.surat_tugas;
                formData.delete('surat_tugas');
                formData.append("surat_tugas_update", this.$refs.surat_tugas_update.files[0]);
            }
            if (this.$refs.surat_undangan_update.files[0] != undefined) {
                // delete this.currentRecordPrestasi.surat_undangan;
                formData.delete('surat_undangan');
                formData.append("surat_undangan_update", this.$refs.surat_undangan_update.files[0]);
            }







            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "prestasi_update";
            } else {
                this.api = base_url + "prestasi_update";
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.recordsPrestasi.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecordPrestasi = {};
                        this.recordsPrestasi.splice(index, 1, response.data.data);
                        this.$refs.foto_sertifikat_update = "";
                        this.$refs.foto_penyerahan_sertifikat_update = "";
                        this.$refs.surat_tugas_update = "";
                        this.$refs.surat_undangan_update = "";

                        CloseLoading();

                        AlertBottom(response.data.message);
                        this.hideModal2('modal_prestasi_update')
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        prestasiDelete() {

            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "prestasi_delete";
            } else {
                this.api = base_url + "prestasi_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.recordsPrestasi.findIndex((record) => record.id === this.temporaryId);
                        this.recordsPrestasi.splice(index, 1);

                        CloseLoading();

                        AlertBottom(response.data.message);
                        this.hideModal2('modal_delete_prestasi');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        changerupiahInputInsert(value, pilihan, status) {
            if (status == 'insert') {
                if (value.length == 5 && value.slice(-1) == ",") {
                    value = "Rp 0,-";
                    this.newRecord[pilihan] = rupiahInput(value);
                }
                this.newRecord[pilihan] = rupiahInput(value);

            } else {
                this.currentRecord[pilihan] = rupiahInput(value);

            }

        },
        cekLainnyaPekerjaan(field) {

            if (this.currentRecord[field] == 'LAINNYA' && field == 'pekerjaan_ayah') {
                this.isLainnyaPekerjaanAyah = true;
            } else if (this.currentRecord[field] == 'LAINNYA' && field == 'pekerjaan_ibu') {
                this.isLainnyaPekerjaanIbu = true;
            } else if (this.currentRecord[field] != 'LAINNYA' && field == 'pekerjaan_ayah') {
                this.isLainnyaPekerjaanAyah = false;
            } else if (this.currentRecord[field] != 'LAINNYA' && field == 'pekerjaan_ibu') {
                this.isLainnyaPekerjaanIbu = false;
            } else {
                this.isLainnyaPekerjaanAyah = false;
                this.isLainnyaPekerjaanIbu = false;
            }
        },
        provinsi_index() {
            axios
                .post(base_url_wilayah_indonesia + "provinsi_index", '')
                .then((response) => {
                    if (response.data.status == true) {
                        // AlertPopup("success", response.data.message, "", 1500, false);
                        this.provinsiList = response.data.data.map((item) => ({
                            nama: item.nama,
                            value: item.id,
                            label: item.nama,
                        }));

                        this.provinsiList_ortu = response.data.data.map((item) => ({
                            nama: item.nama,
                            value: item.id,
                            label: item.nama,
                        }));
                        this.provinsiList_sekolah = response.data.data.map((item) => ({
                            nama: item.nama,
                            value: item.id,
                            label: item.nama,
                        }));
                    }
                    if (response.data.status == false) {
                        // AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        kabupaten_index(id, jenis, update_kota, update_kecamatan, update_kelurahan) {

            if (update_kota == undefined) {
                const formData = new FormData();
                formData.append("id", id);
                axios
                    .post(base_url_wilayah_indonesia + "kabupaten_index", formData)
                    .then((response) => {
                        if (response.data.status == true) {
                            if (jenis == "ortu") {
                                if (response.data.data != null) {
                                    this.kotaList_ortu = response.data.data.map((item) => ({
                                        nama: item.nama,
                                        value: item.id,
                                        label: item.nama,
                                    }));
                                }
                            }
                            if (jenis == "siswa") {
                                if (response.data.data == null) {
                                    //asd
                                } else {
                                    this.kotaList = response.data.data.map((item) => ({
                                        nama: item.nama,
                                        value: item.id,
                                        label: item.nama,
                                    }));
                                }

                            }
                            if (jenis == "sekolah") {
                                if (response.data.data != null) {
                                    this.kotaList_sekolah = response.data.data.map((item) => ({
                                        nama: item.nama,
                                        value: item.id,
                                        label: item.nama,
                                    }));
                                }
                            }
                        }
                        if (response.data.status == false) {
                            //as
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });

            } else {

                const formData = new FormData();
                formData.append("id", id);
                axios
                    .post(base_url_wilayah_indonesia + "kabupaten_index", formData)
                    .then((response) => {
                        if (response.data.status == true) {
                            if (jenis == "ortu") {
                                this.kotaList_ortu = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));
                                const indexKota = this.kotaList_ortu.findIndex(
                                    (record) => record.nama === update_kota
                                )
                                this.kecamatan_index(this.kotaList_ortu[indexKota].value, 'ortu', update_kecamatan, update_kelurahan);
                                this.kota_ortu_update = update_kota;
                            }
                            if (jenis == "siswa") {

                                this.kotaList = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));

                                const indexKota = this.kotaList.findIndex(
                                    (record) => record.nama === update_kota
                                )
                                this.kecamatan_index(this.kotaList[indexKota].value, 'siswa', update_kecamatan, update_kelurahan);
                                this.kota_update = update_kota;

                            }
                            if (jenis == "sekolah") {
                                this.kotaList_sekolah = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));
                                const indexKota = this.kotaList_sekolah.findIndex(
                                    (record) => record.nama === update_kota
                                )
                                this.kecamatan_index(this.kotaList_sekolah[indexKota].value, 'sekolah');
                                this.kota_sekolah_update = update_kota;
                            }
                        }
                        if (response.data.status == false) {
                            //as
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });
            }
        },
        kecamatan_index(id, jenis, update_kecamatan, update_kelurahan) {
            if (update_kecamatan == undefined) {
                const formData = new FormData();
                formData.append("id", id);
                axios
                    .post(base_url_wilayah_indonesia + "kecamatan_index", formData)
                    .then((response) => {
                        if (response.data.status == true) {

                            if (jenis == "ortu") {
                                if (response.data.data != null) {
                                    this.kecamatanList_ortu = response.data.data.map((item) => ({
                                        nama: item.nama,
                                        value: item.id,
                                        label: item.nama,
                                    }));
                                }
                            }
                            if (jenis == "siswa") {
                                // this.kecamatanList = response.data.data;
                                if (response.data.data != null) {
                                    this.kecamatanList = response.data.data.map((item) => ({
                                        nama: item.nama,
                                        value: item.id,
                                        label: item.nama,
                                    }));
                                }

                            }
                        }
                        if (response.data.status == false) {
                            //
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });
            }
            else {
                const formData = new FormData();
                formData.append("id", id);
                axios
                    .post(base_url_wilayah_indonesia + "kecamatan_index", formData)
                    .then((response) => {
                        if (response.data.status == true) {

                            if (jenis == "ortu") {
                                this.kecamatanList_ortu = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));
                                const indexkecamatan = this.kecamatanList_ortu.findIndex(
                                    (record) => record.nama === update_kecamatan
                                )
                                this.kelurahan_index(this.kecamatanList_ortu[indexkecamatan].value, 'ortu', update_kelurahan);
                                this.kecamatan_ortu_update = update_kecamatan;
                            }
                            if (jenis == "siswa") {
                                // this.kecamatanList = response.data.data;
                                this.kecamatanList = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));

                                const indexkecamatan = this.kecamatanList.findIndex(
                                    (record) => record.nama === update_kecamatan
                                )
                                this.kelurahan_index(this.kecamatanList[indexkecamatan].value, 'siswa', update_kelurahan);
                                this.kecamatan_update = update_kecamatan;
                            }
                        }
                        if (response.data.status == false) {
                            //
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });
            }

        },
        kelurahan_index(id, jenis, update_kelurahan) {
            if (update_kelurahan == undefined) {
                const formData = new FormData();
                formData.append("id", id);
                axios
                    .post(base_url_wilayah_indonesia + "kelurahan_index", formData)
                    .then((response) => {
                        if (response.data.status == true) {
                            if (jenis == "ortu") {
                                if (response.data.data != null) {
                                    this.kelurahanList_ortu = response.data.data.map((item) => ({
                                        nama: item.nama,
                                        value: item.id,
                                        label: item.nama,
                                    }));
                                }
                            }
                            if (jenis == "siswa") {
                                if (response.data.data != null) {
                                    this.kelurahanList = response.data.data.map((item) => ({
                                        nama: item.nama,
                                        value: item.id,
                                        label: item.nama,
                                    }));
                                }

                            }

                        }
                        if (response.data.status == false) {
                            //
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });
            } else {
                const formData = new FormData();
                formData.append("id", id);
                axios
                    .post(base_url_wilayah_indonesia + "kelurahan_index", formData)
                    .then((response) => {
                        if (response.data.status == true) {

                            if (jenis == "ortu") {
                                this.kelurahanList_ortu = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));
                                this.kelurahan_ortu_update = update_kelurahan;
                            }
                            if (jenis == "siswa") {
                                this.kelurahanList = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));

                                this.kelurahan_update = update_kelurahan;
                            }

                        }
                        if (response.data.status == false) {
                            //
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });
            }
        },
        setkrsId(id, semester) {
            this.krsId = 0;
            if (id != undefined) {
                this.krsId = id;
            }

            this.krsRecords = [];
            this.tahun_akademik = "";
            this.tipe_semester = "";
            this.counter_semester = "";
            this.kelas = "";
            this.getKrs(semester);
        },
        getKrs(semester) {
            this.dosen_wali = "";
            this.jumlah_sks_diambil = "";
            ShowLoading();
            let formData = new FormData();

            formData.append("mahasiswa", this.krsId);

            formData.append("semester", semester);

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'get_krs';
            } else {
                this.api = base_url + 'get_krs';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {

                        if (response.data.data != undefined) {
                            if (semester == 0) {
                                this.semesterRecords = response.data.data[0];
                                console.log(response.data.data[0]);
                            } else {
                                this.krsRecords = response.data.data[0];
                                this.dosen_wali = response.data.dosen_wali.dosen_wali;
                                this.jumlah_sks_diambil = response.data.jumlah_sks_diambil[0].jumlah_sks_diambil;
                                this.kelas = response.data.kelas[0].kelas;
                                this.tahun_akademik = response.data.tahun_akademik[0].tahun_akademik;
                                this.counter_semester = response.data.counter_semester[0].counter_semester;
                                this.tipe_semester = response.data.tipe_semester[0].tipe_semester;
                            }


                            // this.totalPages = response.data.data[0][0].number_page;
                            // this.previousLastId = response.data.data[1].id;
                            // this.previousLastIdOld = response.data.data[1].id;
                            // let last_index = Object.keys(response.data.data[0]).pop();

                            // this.NextLastId = response.data.data[1][last_index].id;
                            // this.NextLastIdOld = response.data.data[1][last_index].id;
                            // this.arrayLastIdOld.push(response.data.data[1][last_index].id);
                            // this.arrayPreviousIdOld.push(response.data.data[1][0].id);

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    AlertPopup("error", "", "mohon maaf terjadi kesalahan silahkan coba lagi", 1500, false);
                    ErrorConnectionTimeOut(error);
                });

        },



        index() {
            this.records = [];
            this.prodiList = [];


            ShowLoading();
            let formData = new FormData();

            formData.append("previous_id", '');
            formData.append("last_id", '');
            formData.append("limit", this.limit);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("jalur", this.jalurFilter);
            formData.append("query", this.query);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (localStorage.getItem("tipe_user") == "dosen") {
                            console.log(response.data.prodi_akses);
                            for (let i = 0; i < response.data.prodi_akses.length; i++) {
                                const index = response.data.prodi.findIndex(
                                    (record) => record.id === response.data.prodi_akses[i].id
                                );
                                this.prodiList.push(response.data.prodi[index]);
                            }
                        } else {
                            this.prodiList = response.data.prodi;
                        }

                        this.tahunAjaranList = response.data.tahun_ajaran;
                        this.jalurList = response.data.jalur;


                        if ((response.data.tipe == 'dosen' && this.prodiFilter != "" && response.data.data != undefined) || (response.data.data != undefined && (response.data.tipe == 'admin' || response.data.tipe == 'pegawai'))) {
                            this.records = response.data.data[1];
                            this.totalPages = response.data.data[0][0].number_page;
                            this.previousLastId = response.data.data[1][0].id;
                            let last_index = Object.keys(response.data.data[1]).length - 1;
                            this.NextLastId = response.data.data[1][last_index].id;
                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    AlertPopup("error", "", "mohon maaf terjadi kesalahan silahkan coba lagi", 1500, false);
                    ErrorConnectionTimeOut(error);
                });

        },

        index_next_page(param1, param2) {

            ShowLoading();
            let formData = new FormData();
            formData.append("last_id", param2);
            formData.append("limit", this.limit);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("jalur", this.jalurFilter);
            formData.append("query", this.query);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.records = response.data.data[1];

                        this.totalPages = response.data.data[0][0].number_page;
                        this.previousLastId = response.data.data[1][0].id;

                        let last_index = Object.keys(response.data.data[1]).length - 1;
                        this.NextLastId = response.data.data[1][last_index].id;

                        this.numberPage = this.numberPage + 1;


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }


                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index_before_page() {
            if (this.numberPage == 1) {
                return true
            }

            ShowLoading();
            let formData = new FormData();
            formData.append("previous_id", this.previousLastId);
            formData.append("last_id", "0");
            formData.append("limit", this.limit);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("jalur", this.jalurFilter);
            formData.append("query", this.query);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.records = response.data.data[1];
                        this.totalPages = response.data.data[0][0].number_page;
                        this.previousLastId = response.data.data[1][0].id;

                        let last_index = Object.keys(response.data.data[1]).length - 1;
                        this.NextLastId = response.data.data[1][last_index].id;

                        this.numberPage = this.numberPage - 1;
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        insert() {

            ShowLoading();

            const formData = new FormData();
            this.newRecord['prodi_pt'] = 'test';

            Object.entries(this.newRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_insert";
            } else {
                this.api = base_url + this.url + "_insert";
            }


            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status == true) {

                        this.records.unshift(response.data.data);
                        // Clear the form inputs
                        this.newRecord = {

                        };
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_tambah', 'close_modal_tambah')
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    AlertPopup("error", "", "Mohon maaf terjadi kesalahan", 1500, false);
                    ErrorConnectionTimeOut(error);
                });

            // reset provinsi siswa
            this.provinsi = "";
            this.kota = "";
            this.kelurahan = "";
            this.kecamatan = "";
        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.currentRecord.penghasilan_ayah = rupiahInput(this.currentRecord.penghasilan_ayah)
            this.currentRecord.penghasilan_ibu = rupiahInput(this.currentRecord.penghasilan_ibu)

            //
            this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
            this.setRecordwithId('tahun_ajaran', this.tahunAjaranList, 'tahun_ajaran', 'id');

            this.showModal('modal_edit');



            //load provinsi dsb

            if (this.currentRecord.provinsi != "") {
                const index = this.provinsiList.findIndex((record) => record.nama == this.currentRecord.provinsi);
                this.provinsi_update = this.currentRecord.provinsi;
                this.kabupaten_index(this.provinsiList[index].value, 'siswa', this.currentRecord.kota, this.currentRecord.kecamatan, this.currentRecord.kelurahan);
            }

            if (this.currentRecord.provinsi_ortu != "") {
                this.provinsi_ortu_update = this.currentRecord.provinsi_ortu;
                const index_ortu = this.provinsiList_ortu.findIndex((record) => record.nama == this.currentRecord.provinsi_ortu);
                this.kabupaten_index(this.provinsiList[index_ortu].value, 'ortu', this.currentRecord.kota_ortu, this.currentRecord.kecamatan_ortu, this.currentRecord.kelurahan_ortu);
            }

            if (this.currentRecord.provinsi_sekolah != "") {
                this.provinsi_sekolah_update = this.currentRecord.provinsi_sekolah;
                const index_sekolah = this.provinsiList_sekolah.findIndex((record) => record.nama == this.currentRecord.provinsi_sekolah);
                this.kabupaten_index(this.provinsiList_sekolah[index_sekolah].value, 'sekolah', this.currentRecord.kota_sekolah);
            }

            const index_pekerjaan_ayah = this.pekerjaanList.findIndex((record) => record.nama === this.currentRecord.pekerjaan_ayah);
            if (index_pekerjaan_ayah > -1) {
                this.isLainnyaPekerjaanAyah = false;
            } else {
                this.isLainnyaPekerjaanAyah = true;
                this.isiLainnyaPekerjaanAyah = this.currentRecord.pekerjaan_ayah;
                this.currentRecord.pekerjaan_ayah = 'LAINNYA';
            }
            const index_pekerjaan_ibu = this.pekerjaanList.findIndex((record) => record.nama === this.currentRecord.pekerjaan_ibu);

            if (index_pekerjaan_ibu > -1) {
                this.isLainnyaPekerjaanibu = false;
            } else {
                this.isLainnyaPekerjaanIbu = true;
                this.isiLainnyaPekerjaanIbu = this.currentRecord.pekerjaan_ibu;
                this.currentRecord.pekerjaan_ibu = 'LAINNYA';
            }

        },
        editRecordPrestasi(record) {
            this.currentRecordPrestasi = {};
            // Set the current record to edit mode
            this.currentRecordPrestasi = Object.assign({}, record);
            this.currentRecordPrestasi.foto_sertifikat_update = record.foto_sertifikat;
            this.currentRecordPrestasi.foto_penyerahan_sertifikat_update = record.foto_penyerahan_sertifikat;
            this.currentRecordPrestasi.surat_undangan_update = record.surat_undangan;
            this.currentRecordPrestasi.surat_tugas_update = record.surat_tugas;

            const listHasil = ["Juara I", "Juara II", "Juara III", "Harapan I", "Harapan II", "Harapan III", "Penghargaan Tim Terbaik"]
            if (this.cekListString(this.currentRecordPrestasi.hasil, listHasil)) {
                this.currentRecordPrestasi.hasil_pilih = this.currentRecordPrestasi.hasil;
            } else {
                this.currentRecordPrestasi.hasil_pilih = 'lainnya';
            }

        },

        viewRecord(record) {
            this.currentRecord = Object.assign({}, record);
            this.setActiveTab('identitas_diri');
        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {

            if (this.currentRecord[field] != null) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
            } else {
                this.currentRecord[field] = "";
            }
        },

        update() {

            this.currentRecord.provinsi = typeof this.provinsi_update === 'object' ? this.provinsi_update.nama : this.provinsi_update;
            this.currentRecord.kota = typeof this.kota_update === 'object' ? this.kota_update.nama : this.kota_update;
            this.currentRecord.kecamatan = typeof this.kecamatan_update === 'object' ? this.kecamatan_update.nama : this.kecamatan_update;
            this.currentRecord.kelurahan = typeof this.kelurahan_update === 'object' ? this.kelurahan_update.nama : this.kelurahan_update;

            this.currentRecord.provinsi_ortu = typeof this.provinsi_ortu_update === 'object' ? this.provinsi_ortu_update.nama : this.provinsi_ortu_update;
            this.currentRecord.kota_ortu = typeof this.kota_ortu_update === 'object' ? this.kota_ortu_update.nama : this.kota_ortu_update;
            this.currentRecord.kecamatan_ortu = typeof this.kecamatan_ortu_update === 'object' ? this.kecamatan_ortu_update.nama : this.kecamatan_ortu_update;
            this.currentRecord.kelurahan_ortu = typeof this.kelurahan_ortu_update === 'object' ? this.kelurahan_ortu_update.nama : this.kelurahan_ortu_update;


            this.currentRecord.provinsi_sekolah = typeof this.provinsi_sekolah_update === 'object'
                ? this.provinsi_sekolah_update.nama
                : this.provinsi_sekolah_update;

            this.currentRecord.kota_sekolah = typeof this.kota_sekolah_update === 'object'
                ? this.kota_sekolah_update.nama
                : this.kota_sekolah_update;

            this.currentRecord.penghasilan_ibu = rupiahoutput(this.currentRecord.penghasilan_ibu);
            this.currentRecord.penghasilan_ayah = rupiahoutput(this.currentRecord.penghasilan_ayah);

            ShowLoading();
            const formData = new FormData();

            Object.entries(this.currentRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });
            if (this.currentRecord.pekerjaan_ayah == 'LAINNYA') {
                formData.delete('pekerjaan_ayah');
                formData.append('pekerjaan_ayah', this.isiLainnyaPekerjaanAyah);
            }

            if (this.currentRecord.pekerjaan_ibu == 'LAINNYA') {
                formData.delete('pekerjaan_ibu');
                formData.append('pekerjaan_ibu', this.isiLainnyaPekerjaanIbu);
            }
            if (this.$refs.foto_jas_update.files[0] != undefined) {
                formData.append("foto_jas_update", this.$refs.foto_jas_update.files[0]);
            }






            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_update";
            } else {
                this.api = base_url + this.url + "_update";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecord = {}

                        this.records.splice(index, 1, response.data.data);

                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_edit', 'close_modal_edit');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        deleteData() {

            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);
            console.log(this.temporaryId)
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)

                .then((response) => {

                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_delete', 'close_modal_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        verifikasi_dokumen() {

            ShowLoading();

            const formData = new FormData();

            formData.append('id_mahasiswa', this.currentRecord.id)
            formData.append('nama_dokumen', this.nama_dokumen)

            formData.append('value_dokumen', this.value_dokumen)
            formData.append('alasan', this.alasan_dokumen)

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "mahasiswa_verifikasi_dokumen";
            } else {
                this.api = base_url + "mahasiswa_verifikasi_dokumen";
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        const index = this.records.findIndex(
                            (record) => record.id == this.currentRecord.id
                        );

                        if (this.value_dokumen == '2') {
                            this.hideModal2('modal_tolak_dokumen');
                        }


                        this.currentRecord[this.nama_dokumen_status] = response.data.data[this.nama_dokumen_status];
                        this.records[index][this.nama_dokumen_status] = response.data.data[this.nama_dokumen_status];



                        CloseLoading();
                        AlertBottom(response.data.message);
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        get_absensi() {


            ShowLoading();
            let formData = new FormData();

            formData.append("mahasiswa", this.id_mahasiswa_absensi);
            formData.append("tahun_ajaran", this.id_tahun_ajaran_absensi);
            formData.append("semester", this.id_semester_absensi);

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_get_absensi';
            } else {
                this.api = base_url + this.url + '_get_absensi';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        this.tahun_ajaran_list_absensi = response.data.tahun_ajaran;
                        this.semester_list_absensi = response.data.semester

                        // console.log(response.data.data);
                        if (response.data.data != undefined) {
                            this.record_absensi = response.data.data[0];
                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    AlertPopup("error", "", "mohon maaf terjadi kesalahan silahkan coba lagi", 1500, false);
                    ErrorConnectionTimeOut(error);
                });

        },


        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        deleteRecord(id) {
            this.temporaryId = id;
            console.log(this.temporaryId)
            this.showModal('modal_delete');
        },
        deleteRecordPrestasi(id) {
            this.temporaryId = id;
            this.showModal('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        },
        showModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')

            setTimeout(() => {
                this.$refs[modal_name].classList.remove('show');
                this.$refs[modal_name].style.display = 'none';
            }, 150)
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },

        setActiveTab(id) {
            const activeElements = document.querySelectorAll(".active");

            // Remove "active" class from all elements
            activeElements.forEach(element => {
                element.classList.remove("active");
            });

            const myElement = this.$refs[id];
            myElement.classList.add("active");
            this.pageActive = id;

        },
        nextPhoto() {

            if (this.posisi_foto < 10) {
                this.posisi_foto += 1;
            }

            if (this.posisi_foto == 1) {
                this.source_foto_2 = this.currentRecord.foto_cover_rapor;
                this.nama_foto_2 = "Foto Cover Rapor";
            }

            if (this.posisi_foto == 2) {
                this.source_foto_2 = this.currentRecord.foto_kartu_keluarga;
                this.nama_foto_2 = "Foto Kartu Keluarga";
            }

            if (this.posisi_foto == 3) {
                this.source_foto_2 = this.currentRecord.foto_data_diri_rapor;
                this.nama_foto_2 = "Foto Data Diri Rapor";
            }

            if (this.posisi_foto == 4) {
                this.source_foto_2 = this.currentRecord.foto_rapor_kelas_xii_ganjil_genap;
                this.nama_foto_2 = "Foto Rapor Kelas 12 Ganjil / Genap"

            }
            if (this.posisi_foto == 5) {
                this.source_foto_2 = this.currentRecord.foto_skl_skhun;
                this.nama_foto_2 = "SKL / SKHUN";

            }
            if (this.posisi_foto == 6) {
                this.source_foto_2 = this.currentRecord.foto_ijazah;
                this.nama_foto_2 = "Foto Ijazah";

            }
            if (this.posisi_foto == 7) {
                this.source_foto_2 = this.currentRecord.foto_ktp_siswa;
                this.nama_foto_2 = "Foto KTP";
            }
            if (this.posisi_foto == 8) {
                this.source_foto_2 = this.currentRecord.foto_rumah_depan;
                this.nama_foto_2 = "Foto Pendukung lainnya 1";

            }
            if (this.posisi_foto == 9) {
                this.source_foto_2 = this.currentRecord.foto_rumah_dalam;
                this.nama_foto_2 = "Foto Pendukung lainnya 2";

            }
            if (this.posisi_foto == 10) {
                this.source_foto_2 = this.currentRecord.foto_jas;
                this.nama_foto_2 = "Foto Almameter";
            }

        },
        nextPhoto2() {

            this.posisi_foto_2 += 1;

            if (this.posisi_foto_2 == 1) {
                this.source_foto_2 = this.currentRecord.foto_ktp_siswa;
                this.nama_foto_2 = "Foto Cover Rapor";
            }

            if (this.posisi_foto_2 == 2) {
                this.source_foto_2 = this.currentRecord.foto_kartu_keluarga;
                this.nama_foto_2 = "Foto Kartu Keluarga";
            }

            if (this.posisi_foto_2 == 3) {
                this.source_foto_2 = this.currentRecord.foto_rumah_dapur;
                this.nama_foto_2 = "Foto Akta Kelahiran";
            }

            if (this.posisi_foto_2 == 4) {
                this.source_foto_2 = this.currentRecord.foto_ijazah;
                this.nama_foto_2 = "Foto Ijazah";

            }

            if (this.posisi_foto_2 == 5) {
                this.source_foto_2 = this.currentRecord.foto_rumah_depan;
                this.nama_foto_2 = "Foto Pendukung lainnya 1";

            }
            if (this.posisi_foto_2 == 6) {
                this.source_foto_2 = this.currentRecord.foto_rumah_dalam;
                this.nama_foto_2 = "Foto Pendukung lainnya 2";

            }


        },
        beforePhoto() {
            if (this.posisi_foto > 1) {
                this.posisi_foto -= 1;
            }


            if (this.posisi_foto == 1) {
                this.source_foto_2 = this.currentRecord.foto_cover_rapor;
                this.nama_foto_2 = "Foto Cover Rapor";
            }

            if (this.posisi_foto == 2) {
                this.source_foto_2 = this.currentRecord.foto_kartu_keluarga;
                this.nama_foto_2 = "Foto Kartu Keluarga";
            }

            if (this.posisi_foto == 3) {
                this.source_foto_2 = this.currentRecord.foto_data_diri_rapor;
                this.nama_foto_2 = "Foto Data Diri Rapor";
            }
            if (this.posisi_foto == 4) {
                this.source_foto_2 = this.currentRecord.foto_rapor_kelas_xii_ganjil_genap;
                this.nama_foto_2 = "Foto Rapor Kelas 12 Ganjil / Genap"

            }
            if (this.posisi_foto == 5) {
                this.source_foto_2 = this.currentRecord.foto_skl_skhun;
                this.nama_foto_2 = "SKL / SKHUN";

            }
            if (this.posisi_foto == 6) {
                this.source_foto_2 = this.currentRecord.foto_ijazah;
                this.nama_foto_2 = "Foto Ijazah";

            }
            if (this.posisi_foto == 7) {
                this.source_foto_2 = this.currentRecord.foto_ktp_siswa;
                this.nama_foto_2 = "Foto KTP";
            }
            if (this.posisi_foto == 8) {
                this.source_foto_2 = this.currentRecord.foto_rumah_depan;
                this.nama_foto_2 = "Foto Pendukung lainnya 1";

            }
            if (this.posisi_foto == 9) {
                this.source_foto_2 = this.currentRecord.foto_rumah_dalam;
                this.nama_foto_2 = "Foto Pendukung lainnya 2";

            }
            if (this.posisi_foto == 10) {
                this.source_foto_2 = this.currentRecord.foto_jas;
                this.nama_foto_2 = "Foto Almameter";

            }


        },
        beforePhoto2() {
            this.posisi_foto_2 -= 1;

            if (this.posisi_foto_2 == 1) {
                this.source_foto_2 = this.currentRecord.foto_ktp_siswa;
                this.nama_foto_2 = "Foto Cover Rapor";
            }

            if (this.posisi_foto_2 == 2) {
                this.source_foto_2 = this.currentRecord.foto_kartu_keluarga;
                this.nama_foto_2 = "Foto Kartu Keluarga";
            }

            if (this.posisi_foto_2 == 3) {
                this.source_foto_2 = this.currentRecord.foto_rumah_dapur;
                this.nama_foto_2 = "Foto Akta Kelahiran";
            }

            if (this.posisi_foto_2 == 4) {
                this.source_foto_2 = this.currentRecord.foto_ijazah;
                this.nama_foto_2 = "Foto Ijazah";

            }

            if (this.posisi_foto_2 == 5) {
                this.source_foto_2 = this.currentRecord.foto_rumah_depan;
                this.nama_foto_2 = "Foto Pendukung lainnya 1";

            }
            if (this.posisi_foto_2 == 6) {
                this.source_foto_2 = this.currentRecord.foto_rumah_dalam;
                this.nama_foto_2 = "Foto Pendukung lainnya 2";

            }


        },

    },

    mounted() {
        // document.querySelector('meta[name=viewport]').setAttribute('content', 'width=1024');
        this.wargaNegaraList = wargaNegaraList;
        this.statusAkademikList = statusAkademikList;


        const dateNow = new Date();
        const year15 = new Date(dateNow.getFullYear() - 17, dateNow.getMonth(), dateNow.getDate());
        const year45 = new Date(dateNow.getFullYear() - 45, dateNow.getMonth(), dateNow.getDate());

        this.tanggal_minimum = year45.toISOString().slice(0, 10);
        this.newRecord.tanggal_lahir = year15.toISOString().slice(0, 10);
        this.tanggal_maksimum = year15.toISOString().slice(0, 10);



        //provinsi
        this.provinsi_index();

        //set v-select
        this.$nextTick(() => {
            const dropdownToggle = document.querySelectorAll('.vs__dropdown-toggle');
            const searchInput = document.querySelectorAll('.vs__search');
            const focusedSearchInput = document.querySelectorAll('.vs__search:focus');
            const selectedOption = document.querySelectorAll('.vs__selected');

            dropdownToggle.forEach((toggle) => {
                toggle.style.border = 'none';
            });

            searchInput.forEach((input) => {
                input.style.fontSize = 'inherit';
                input.style.padding = '0';
            });

            focusedSearchInput.forEach((input) => {
                input.style.fontSize = 'inherit';
                input.style.padding = '0';
            });

            selectedOption.forEach((option) => {
                option.style.margin = '4px 0 0';
                option.style.padding = '0';
            });


        });

    },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');
        this.delete_akses = menu_akses_cek(this.url, 'delete');

        this.index();

    }



}
</script>